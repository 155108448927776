import { FC, useState, memo, ChangeEvent } from "react";
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { setPicture, setUploadPicture } from "ducks/profile/slice";
import { useProfileEdit } from "hooks/profileEditHooks";
import styles from "styles/components/ProfileEdit/EditPictureTrimming.module.css";
import { Button } from "components/Shadcn/Button";

type Props = {
  isOpen: boolean;
  src: string;
  closeModal: VoidFunction;
};

/**
 * プロフィール写真変更モーダル
 * @param Props
 * @returns
 */
const EditPictureTrimming: FC<Props> = ({ isOpen, src, closeModal }) => {
  // field
  const { dispatch } = useProfileEdit();
  const [crop, setCrop] = useState<Crop>();
  const [cropImage, setCropImage] = useState<HTMLImageElement>();

  // function
  // 画像ロード時にcropを編集
  const onImageLoad = (e: ChangeEvent<HTMLImageElement>) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    const crop = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: "%",
          width: 50,
          height: 50,
        },
        1,
        width,
        height
      ),
      width,
      height
    );
    setCrop(crop);
    setCropImage(e.target);
  };

  // trimmingの枠が動くたびにcrop編集
  const onChange = (c: Crop) => {
    setCrop(c);
  };

  // trimmingの枠を動かし終わるたびにcanvas編集
  const onComplete = (c: Crop) => {
    if (cropImage) {
      // canvasを作成
      const canvas = document.createElement("canvas");
      const scaleX = cropImage.naturalWidth / cropImage.width;
      const scaleY = cropImage.naturalHeight / cropImage.height;
      const maxSize = 300; // 画像の最大サイズ
      canvas.width = Math.min(c.width, maxSize);
      canvas.height = Math.min(c.height, maxSize);
      const ctx = canvas.getContext("2d");
      // canvasの画像サイズをcropに合わせて編集
      if (ctx !== null) {
        ctx.drawImage(
          cropImage,
          c.x * scaleX,
          c.y * scaleY,
          c.width * scaleX,
          c.height * scaleY,
          0,
          0,
          canvas.width,
          canvas.height
        );
      }
      // canvasを画像データに変換
      if (document) {
        // dataURL作成
        const png = canvas.toDataURL("image/png");
        const base64 = png.split(",")[1];

        // blob作成
        let bin = atob(base64.replace(/^.\*,/, ""));
        let buffer = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; i++) {
          buffer[i] = bin.charCodeAt(i);
        }
        const blob = new Blob([buffer.buffer], {
          type: "image/png",
        });

        // blobをFileに変換
        const file: File = new File([blob], "test.png");

        // stateを更新
        dispatch(setPicture(png));
        dispatch(setUploadPicture({ file }));
      }
    }
  };

  return (
    <>
      {isOpen && (
        <div className={styles.trimming_container}>
          <h2 className={styles.crop_text}>trimming</h2>
          <ReactCrop
            className={styles.crop_area}
            crop={crop}
            onChange={onChange}
            onComplete={onComplete}
            ruleOfThirds={true}
            aspect={1}
          >
            <img
              className={styles.trimming_image}
              src={src}
              onLoad={onImageLoad}
              alt="EditPictureTrimming"
            />
          </ReactCrop>
          <Button onClick={closeModal} className="my-[2%] mx-auto">
            確定
          </Button>
        </div>
      )}
    </>
  );
};

export default memo(EditPictureTrimming);
