import { ChangeEvent, FC, memo, useEffect, useState, MouseEvent } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { useSelector } from "react-redux";

import { PAGES } from "common/PAGES";
import { DEFAULT_USER_ICON, JOBS, LOCAL_STORAGE_KEYS } from "common/constants";
import ProfileDetailModal from "components/Profiles/ProfileDetailModal";
import { selectIsAdmin } from "ducks/auth/slice";
import styles from "styles/components/Profiles/Profile.module.css";
import Badge from "./Badge";
import { Button } from "components/Shadcn/Button";
import { useNavigate } from "react-router-dom";

type Props = {
  id: string;
  firstName: string;
  lastName: string;
  picture: string;
  jobId: number;
  deleteFlg: number;
  badgeStyle: number;
};

/**
 * 社員情報
 * @param Props
 * @returns
 */
const Profile: FC<Props> = ({
  id,
  firstName,
  lastName,
  picture,
  jobId,
  deleteFlg,
  badgeStyle,
}) => {
  const navigate = useNavigate();
  const isAdmin: boolean = useSelector(selectIsAdmin);

  const [isDetailModal, setIsDetailModal] = useState<boolean>(false);
  const [positionName, setPositionName] = useState<string>("");

  const clickEditProfile = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    localStorage.setItem(LOCAL_STORAGE_KEYS.PROFILE_EDIT_KEY, id);
    navigate(PAGES.PROFILE_EDIT.PATH);
  };

  const openDetailModal = () => {
    setIsDetailModal(true);
  };
  const closeDetailModal = () => {
    setIsDetailModal(false);
  };

  useEffect(() => {
    let index = 0;
    if (jobId < JOBS.length) {
      index = jobId;
    }
    setPositionName(JOBS[index]);
  }, [jobId]);

  return (
    <div>
      <div className={styles.profile_content} onClick={openDetailModal}>
        {deleteFlg === 1 && <div className={styles.deleteView}></div>}
        <Badge badgeStyle={badgeStyle} />
        <img
          src={picture}
          alt="thumbnail"
          onError={(e: ChangeEvent<HTMLImageElement>) =>
            (e.target.src = DEFAULT_USER_ICON)
          }
        />
        <div className={styles.profile_txt}>
          <p className={styles.profile_txt_name}>
            {lastName}&nbsp;{firstName}
          </p>
          <p className={styles.profile_txt_position}>{positionName}</p>
        </div>
        <div className={styles.more_btns}>
          {isAdmin && (
            <Button
              variant="admin"
              className="px-2"
              onClick={clickEditProfile}
            >
              Edit
              <span className="inline-block">
                <AiOutlineEdit />
              </span>
            </Button>
          )}
        </div>
      </div>
      <ProfileDetailModal
        isOpen={isDetailModal}
        closeModal={closeDetailModal}
        id={id}
      />
    </div>
  );
};

export default memo(Profile);
