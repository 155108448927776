import { ChangeEvent, FC, useCallback, useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "app/store";

import uuid from "react-uuid";
import { getDateTimeNow, escapeForAwsIntegra } from "common/common";
import NoticeCard from "components/Common/NoticeCard";
import PortalBtn from "components/Common/PortalBtn";
import {
  fetchAsyncPostFaqDetail,
  fetchAsyncPutFaqDetail,
} from "ducks/faq/slice";
import { PostFaqDetailInput, PutFaqDetailInput } from "ducks/faq/type";

import {
  fetchAsyncGetFaqs,
  selectIsUpdateFinished,
  selectIsUpdateLoading,
  unsetIsPostFinished,
} from "ducks/faq/slice";

import styles from "styles/components/Faq/FaqDetailEditModal.module.css";
import { Textarea } from "components/Shadcn/Textarea";
import { Label } from "components/Shadcn/Label";

type Props = {
  isOpen: boolean;
  closeModal: VoidFunction;
  isUpdateMode?: boolean;
  title?: string;
  titlePartitionKey?: string;
  detailPartitionKey?: string;
  question?: string;
  answer?: string;
  createdAt?: string;
};

/**
 * FAQ詳細追加/編集モーダル
 * @param param0
 * @returns
 */
const FaqDetailEditModal: FC<Props> = ({
  isOpen,
  closeModal,
  isUpdateMode = false,
  title = "",
  titlePartitionKey = "",
  detailPartitionKey = "",
  question = "",
  answer = "",
}) => {
  const isUpdateLoading: boolean = useSelector(selectIsUpdateLoading);
  const isUpdateFinished: boolean = useSelector(selectIsUpdateFinished);
  const dispatch = useDispatch<AppDispatch>();

  const [questionVal, setQuestionVal] = useState<string>(question);
  const [answerVal, setAnswerVal] = useState<string>(answer);
  const [isDisabledExe, setIsDisabeldExe] = useState<boolean>(false);

  const actionMode: "登録" | "更新" = isUpdateMode ? "更新" : "登録";

  /** input */
  const changeQuestionVal = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setQuestionVal(e.target.value);
  };
  const changeAnswerVal = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setAnswerVal(e.target.value);
  };
  const initVal = () => {
    setQuestionVal("");
    setAnswerVal("");
  };

  /** モーダルクローズ */
  const closeModalOverride = useCallback(() => {
    initVal();
    dispatch(fetchAsyncGetFaqs());
    dispatch(unsetIsPostFinished());
    closeModal();
  }, [dispatch, closeModal]);

  /** 処理実行 */
  const clickExe = () => {
    const now: string = getDateTimeNow();
    if (isUpdateMode) {
      // 更新
      const input: PutFaqDetailInput = {
        titlePartitionKey: titlePartitionKey,
        detailPartitionKey: detailPartitionKey,
        question: escapeForAwsIntegra(questionVal),
        answer: escapeForAwsIntegra(answerVal),
        updatedAt: now,
      };
      dispatch(fetchAsyncPutFaqDetail(input));
    } else {
      // 追加
      const input: PostFaqDetailInput = {
        titlePartitionKey: titlePartitionKey, //titleのPKをSortKeyに
        detailPartitionKey: uuid(),
        question: escapeForAwsIntegra(questionVal),
        answer: escapeForAwsIntegra(answerVal),
        createdAt: now,
      };
      dispatch(fetchAsyncPostFaqDetail(input));
    }
  };

  useEffect(() => {
    isUpdateFinished && !isUpdateMode && initVal();
  }, [isUpdateFinished, isUpdateMode]);

  /** input check */
  useEffect(() => {
    if (isUpdateMode) {
      // 更新用
      if (
        (questionVal === question && answerVal === answer) ||
        !questionVal ||
        !answerVal
      ) {
        setIsDisabeldExe(true);
      } else {
        setIsDisabeldExe(false);
      }
    } else {
      // 追加用
      if (!questionVal || !answerVal) {
        setIsDisabeldExe(true);
      } else {
        setIsDisabeldExe(false);
      }
    }
  }, [dispatch, questionVal, answerVal, question, answer, isUpdateMode]);

  return (
    <>
      {isOpen && (
        <div className={styles.overlay} onClick={closeModalOverride}>
          <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
            <div className={styles.modal_contents}>
              <div className={styles.title_box}>
                {isUpdateMode && <p>{title} FAQ編集</p>}
                {!isUpdateMode && <p>{title} FAQ追加</p>}
              </div>
              <div className="mt-8">
                <div className="grid w-full gap-1.5">
                  <Label htmlFor="question_input">質問</Label>
                  <Textarea
                    id="question_input"
                    placeholder="質問を入力"
                    value={questionVal}
                    maxLength={2000}
                    onChange={changeQuestionVal}
                  />
                </div>
              </div>

              <div className="mt-8">
                <div className="grid w-full gap-1.5">
                  <Label htmlFor="question_answer_area">回答</Label>
                  <Textarea
                    className="h-60"
                    id="question_answer_area"
                    placeholder="回答を入力"
                    value={answerVal}
                    maxLength={2000}
                    onChange={changeAnswerVal}
                  />
                </div>
              </div>

              <div className={styles.btns}>
                <PortalBtn
                  variant="outline"
                  clickFunc={closeModalOverride}
                  isLoading={isUpdateLoading}
                >
                  閉じる
                </PortalBtn>
                <PortalBtn
                  clickFunc={clickExe}
                  isLoading={isUpdateLoading}
                  disabled={isDisabledExe}
                >
                  {actionMode}
                </PortalBtn>
              </div>
            </div>
          </div>
        </div>
      )}
      <NoticeCard isFadedIn={isUpdateFinished}>
        {`${actionMode}が完了しました`}
      </NoticeCard>
    </>
  );
};

export default memo(FaqDetailEditModal);
