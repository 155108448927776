import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PAGES } from "common/PAGES";
import { FC, useCallback, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Auth, Hub } from "aws-amplify";
import { AppDispatch } from "app/store";
import Login from "components/Login";
import { useInitialHooks } from "hooks/initialHooks";
import { selectUserInfo, fetchAsyncGetUserInfo } from "ducks/auth/slice";
import { UserInfo } from "ducks/auth/type";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/Error/ErrorFallback";

const App: FC = () => {
  const { initialize } = useInitialHooks();
  initialize();
  const dispatch = useDispatch<AppDispatch>();
  const userInfo: UserInfo = useSelector(selectUserInfo);
  const [user, setUser] = useState(null);

  /** ログインユーザの情報を取得する */
  const getUser = useCallback(async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      setUser(userData);
      await dispatch(fetchAsyncGetUserInfo());
    } catch (e) {
      console.log(e);
      return console.log("Not signed in");
    }
  }, [dispatch]);

  useLayoutEffect(() => {
    /** 認証情報の制御を行う */
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser();
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });
    getUser();
  }, [getUser]);

  /** エラー発生時のログ出力 */
  const onErrorFallback = (error: Error, info: { componentStack: string }) => {
    // ここでログ出力などを行う
    console.log("error.message", error.message);
    console.log("info.componentStack:", info.componentStack);
  };

  return (
    <div className="App">
      {user ? (
        <BrowserRouter>
          {userInfo.deleteFlg === 0 ? (
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={onErrorFallback}
            >
              <Routes>
                {Object.entries(PAGES).map(([key, PAGE]) => {
                  return (
                    <Route
                      key={key}
                      path={PAGE.PATH}
                      element={PAGE.CONTAINER}
                    />
                  );
                })}
              </Routes>
            </ErrorBoundary>
          ) : (
            <Login />
          )}
        </BrowserRouter>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default App;
