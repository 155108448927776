import { SxProps, Theme } from "@mui/material/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Box,
  Typography,
  Modal,
  Card,
} from "@mui/material";
import { BsFolder2, BsFillTrashFill } from "react-icons/bs";
import { BiCaretDown } from "react-icons/bi";

import emotionStyled from "@emotion/styled";

// emotionStyled（スタイル）
export const WorkRegulationArea = emotionStyled(Box)`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: start;
align-items: top;
`;

export const WorkRegulationAccordion = emotionStyled(Accordion)`
margin-top: 0;
`;

export const WorkRegulationAccordionSummary = emotionStyled(AccordionSummary)`
font-weight: bold;
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
align-items: center;
color: #151515;
border-bottom: solid;
border-color: #808080;
border-width: 0.1;
`;

export const WorkRegulationAccordionDetails = emotionStyled(AccordionDetails)`
display: flex;
flex-flow: column;
justify-content: center;
align-items: center;
font-weight: bold;
`;

export const FolderTypography = emotionStyled(Typography)`
display: flex;
flex-flow: column;
justify-content: center;
align-items: center;
font-weight: bold;
`;

export const WorkRegulationsContainer = emotionStyled(Container)`
display:flex;
flex-flow: column;
align-items: center;
justify-content: center;
`;

export const FileViewRow = emotionStyled(Box)`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: left;
align-items: center;
`;

export const UploadTypography = emotionStyled(Typography)`
`;

export const UploadIcon = emotionStyled(BsFolder2)`
@media (max-width:599px) {
  width: 18px;
  height: 18px;
}
@media (min-width:600px) {
  width: 18px;
  height: 18px;
}
@media (min-width:900px) {
  width: 24px;
  height: 24px;
}
@media (min-width:1200px) {
  width: 26px;
  height: 26px;
}
`;

export const DeleteIcon = emotionStyled(BsFillTrashFill)`
cursor: pointer;
color: #6C6C6C;
@media (max-width:599px) {
  width: 14px;
  height: 14px;
}
@media (min-width:600px) {
  width: 16px;
  height: 16px;
}
@media (min-width:900px) {
  width: 22px;
  height: 22px;
}
@media (min-width:1200px) {
  width: 24px;
  height: 24px;
}
`;

export const ExpandIcon = emotionStyled(BiCaretDown)`
@media (max-width:599px) {
  width: 14px;
  height: 14px;
}
@media (min-width:600px) {
  width: 16px;
  height: 16px;
}
@media (min-width:900px) {
  width: 22px;
  height: 22px;
}
@media (min-width:1200px) {
  width: 24px;
  height: 24px;
}
`;

export const UploadModal = emotionStyled(Modal)`
display:flex;
flex-flow: column;
align-items: center;
justify-content: center;
`;

export const UploadModalCard = emotionStyled(Card)`
display:flex;
flex-flow: column;
align-items: center;
justify-content: center;
`;

export const ModalStrArea = emotionStyled(Box)`
display:flex;
flex-flow: column;
align-items: center;
justify-content: center;
`;

export const ModalBtnArea = emotionStyled(Box)`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
align-items: center;
`;

export const ModalFolderTypography = emotionStyled(Typography)`
font-weight: bold;
`;

export const ModalConfirmTypography = emotionStyled(Typography)`
`;

export const ModalFileTypography = emotionStyled(Typography)`
`;

// Theme（レスポンシブスタイル）
export const workRegulationAreaSx: SxProps<Theme> = {
  width: { xs: 370, sm: 550, md: 850, lg: 1100 },
  marginTop: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
  marginBottom: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
};

export const workRegulationAccordionSx: SxProps<Theme> = {
  width: { xs: 290, sm: 450, md: 700, lg: 950 },
  marginBottom: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
};

export const folderTypographySx: SxProps<Theme> = {
  fontSize: { xs: 14, sm: 18, md: 20, lg: 24 },
};

export const workRegulationAccordionSummarySx: SxProps<Theme> = {
  width: { xs: 290, sm: 450, md: 700, lg: 950 },
};

export const workRegulationAccordionDetailsSx: SxProps<Theme> = {
  width: { xs: 290, sm: 450, md: 700, lg: 950 },
};

export const fileViewRowSx: SxProps<Theme> = {
  width: { xs: 290, sm: 450, md: 700, lg: 950 },
  height: { xs: 26, sm: 28, md: 32, lg: 34 },
  paddingLeft: { xs: 5, sm: 6, md: 7, lg: 8 },
  fontSize: { xs: 15, sm: 16, md: 17, lg: 18 },
  gap: { xs: 0.4, sm: 0.6, md: 0.8, lg: 1 },
};

export const uploadTypographySx: SxProps<Theme> = {
  display: { xs: "none", md: "flex" },
  fontSize: { xs: 8, sm: 10, md: 14, lg: 16 },
};

export const uploadModalCardSx: SxProps<Theme> = {
  width: { xs: 370, sm: 450, md: 500, lg: 600 },
  height: { xs: 200, sm: 225, md: 250, lg: 300 },
};

export const modalStrAreaSx: SxProps<Theme> = {
  width: { xs: 370, sm: 450, md: 500, lg: 600 },
  height: { xs: 100, sm: 112.5, md: 125, lg: 150 },
};

export const modalBtnAreaSx: SxProps<Theme> = {
  width: { xs: 370, sm: 450, md: 500, lg: 600 },
  height: { xs: 100, sm: 112.5, md: 125, lg: 150 },
  gap: { xs: 7, sm: 8, md: 10, lg: 12 },
};

export const modalFolderTypographySx: SxProps<Theme> = {
  fontSize: { xs: 16, sm: 20, md: 22, lg: 26 },
};

export const modalConfirmTypographySx: SxProps<Theme> = {
  fontSize: { xs: 14, sm: 18, md: 20, lg: 24 },
};

export const modalFileTypographySx: SxProps<Theme> = {
  fontSize: { xs: 12, sm: 16, md: 18, lg: 20 },
  marginTop: { xs: 1, sm: 1, md: 2, lg: 2 },
};
