import React from "react";
import { useEffect } from "react";
import ReactGA from "react-ga4";

export function GoogleAnalyticsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  /** GA4連携 */
  useEffect(() => {
    const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_ID || "";
    if (GA_MEASUREMENT_ID) {
      // GA測定ID を入力して設定
      ReactGA.initialize(GA_MEASUREMENT_ID);
      // アクセスしたパスをGA4に連携
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
      });
    } else {
      // 本番とdevs環境以外は連携しない
      return;
    }
  }, []);

  return <>{children}</>;
}
