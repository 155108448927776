import { FC } from "react";
import { LOGO_FILE_NAME } from "common/constants";
import { PAGES } from "common/PAGES";
import styles from "styles/components/Footer.module.css";
import { CONTACT_US_MAIL_ADDRES } from "common/constants";
import { useNavigate } from "react-router-dom";

/**
 * 共通フッター
 * @returns
 */
const Footer: FC = () => {
  const navigate = useNavigate();
  const mailto = "mailto:" + CONTACT_US_MAIL_ADDRES;

  return (
    <footer className={`${styles.footer} bg-background`}>
      <div className={styles.footer_logo}>
        <div>
          <button onClick={() => navigate(PAGES.HOME.PATH)}>
            <img src={LOGO_FILE_NAME} alt="logo" />
          </button>
        </div>
      </div>
      <ul className={`${styles.footer_menu} pl-10`}>
        <li>
          <a className={styles.footer_menu_item} href="#top">
            ▲ページ上部へ戻る
          </a>
        </li>
        <li>
          <a
            className={styles.footer_menu_item}
            href={mailto}
            target="_blank"
            rel="noreferrer noopener"
          >
            Contact us
          </a>
        </li>
      </ul>
      <p className={styles.footer_copyright}>
        <small lang="en">&copy; kokorozashi.inc All Rights Reserved</small>
      </p>
    </footer>
  );
};

export default Footer;
