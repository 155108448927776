import { FC, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "styles/containers/WelfareInfo.module.css";
import Layout from "components/Layout";
import Loader from "components/Loader";
import { AppDispatch } from "app/store";
import { PAGES, PageType } from "common/PAGES";
import { WelfareType } from "ducks/welfare/type";
import {
  selectIsLoading,
  selectKokonobiList,
  selectKokomemoList,
  selectKokokaraList,
  selectKokonegiList,
  selectKokohaguList,
} from "ducks/welfare/slice";
import { fetchAsyncGetWelfares } from "ducks/welfare/slice";
import WelfareBlock from "components/Welfares/WelfareBlock";

/**
 * 福利厚生一覧画面
 */
const Welfares: FC = () => {
  // field
  const dispatch = useDispatch<AppDispatch>();
  const pageName: string = useMemo(() => PAGES.WELFARE_INFO.NAME, []);
  const breadcrumbs: PageType[] = useMemo(
    () => [PAGES.HOME, PAGES.WELFARE_INFO],
    []
  );

  // useSelector(redux)
  const isLoading: boolean = useSelector(selectIsLoading);
  const kokonobiList: WelfareType[] = useSelector(selectKokonobiList);
  const kokomemoList: WelfareType[] = useSelector(selectKokomemoList);
  const kokokaraList: WelfareType[] = useSelector(selectKokokaraList);
  const kokonegiList: WelfareType[] = useSelector(selectKokonegiList);
  const kokohaguList: WelfareType[] = useSelector(selectKokohaguList);

  // Rendering
  useEffect(() => {
    dispatch(fetchAsyncGetWelfares());
  }, [dispatch]);

  return (
    <Layout pageName={pageName} breadcrumbs={breadcrumbs}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.container}>
          <WelfareBlock kokoTitle="ココのび" kokoList={kokonobiList} />
          <WelfareBlock kokoTitle="ココめも" kokoList={kokomemoList} />
          <WelfareBlock kokoTitle="ココから" kokoList={kokokaraList} />
          <WelfareBlock kokoTitle="ココねぎ" kokoList={kokonegiList} />
          <WelfareBlock kokoTitle="ココはぐ" kokoList={kokohaguList} />
        </div>
      )}
    </Layout>
  );
};

export default Welfares;
