import { SxProps, Theme } from "@mui/material/styles";
import { Box, CardContent, Typography } from "@mui/material";
import emotionStyled from "@emotion/styled";

/* Emotion */
export const ListBox = emotionStyled(Box)`
display: flex;
flex-flow: column;
justify-content: center;
align-items: center;
`;

export const NewLabelWrapper = emotionStyled(Box)`
position: relative;
width: 100%;
`;

export const NewLabel = emotionStyled(Typography)`
position: absolute;
color: #fff;
background: #e63b3b;
box-shadow: 0 0 0 2px rgb(203 37 37);
transform: rotate(45deg);
text-align: center;
`;

export const ListCardContent = emotionStyled(CardContent)`
display: flex;
flex-flow: column;
justify-content: center;
align-items: center;
`;

export const ListTitleArea = emotionStyled(Box)`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
align-items: start;
`;

export const ListButtonArea = emotionStyled(Box)`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: right;
align-items: center;
`;

export const ListDayViewArea = emotionStyled(Box)`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: right;
align-items: center;
`;

export const ListTitleElement = emotionStyled(Typography)`
font-weight: bold;
`;

/* Theme（レスポンシブ） */
// Box
export const listBoxSx: SxProps<Theme> = {
  width: { xs: 250, sm: 300, md: 580, lg: 740 },
};

// Label
export const newLabelWrapperSx: SxProps<Theme> = {};

export const newLabelSx: SxProps<Theme> = {
  width: { xs: 110, sm: 140, md: 150, lg: 180 },
  height: { xs: 20, sm: 25, md: 30, lg: 35 },
  top: { xs: 20, sm: 20, md: 20, lg: 30 },
  left: { xs: 165, sm: 197, md: 468, lg: 580 },
  fontSize: { xs: 12, sm: 15, md: 20, lg: 25 },
};

// Card
export const listCardSx: SxProps<Theme> = {
  width: { xs: 250, sm: 300, md: 580, lg: 720 },
  marginTop: { xs: 0.5, sm: 1, md: 1.5, lg: 2 },
  marginBottom: { xs: 0.5, sm: 1, md: 1.5, lg: 2 },
  overflow: "hidden",
};

// Area
export const listCardMediaSx: SxProps<Theme> = {
  width: { xs: 250, sm: 300, md: 580, lg: 720 },
  height: { xs: 140, sm: 200, md: 320, lg: 380 },
};

export const listCardContentSx: SxProps<Theme> = {
  gap: { xs: 3, sm: 2, md: 2, lg: 3 },
};

export const listTitleAreaSx: SxProps<Theme> = {
  width: { xs: 230, sm: 280, md: 550, lg: 680 },
  gap: { xs: 2, sm: 2, md: 3, lg: 3 },
};

export const listButtonAreaSx: SxProps<Theme> = {
  paddingLeft: { xs: 2, sm: 0.5, md: 2, lg: 4 },
  paddingRight: { xs: 2, sm: 0.5, md: 2, lg: 4 },
  width: { xs: 250, sm: 280, md: 550, lg: 680 },
};

export const listDayViewAreaSx: SxProps<Theme> = {
  width: { xs: 230, sm: 280, md: 170, lg: 230 },
  gap: { xs: 0.5, md: 1, lg: 1 },
  fontSize: { xs: 13, sm: 15, md: 20, lg: 25 },
};

// Element
export const listAvatarElementSx: SxProps<Theme> = {
  width: { xs: 30, sm: 35, md: 40, lg: 40 },
  height: { xs: 30, sm: 35, md: 40, lg: 40 },
};

export const listTitleElementSx: SxProps<Theme> = {
  width: { xs: 230, sm: 280, md: 320, lg: 400 },
  fontSize: { xs: 13, sm: 15, md: 20, lg: 25 },
};

export const listDayViewElementSx: SxProps<Theme> = {
  fontSize: { xs: 13, sm: 15, md: 16, lg: 20 },
};
