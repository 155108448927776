import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { RootState } from "app/store";
import { API_NAME, API_PATHS } from "common/constants";
import { initialState } from "ducks/auth/initialState";
import { AuthState } from "ducks/auth/type";

/**
 * ログインユーザ情報の取得
 **/
export const fetchAsyncGetUserInfo = createAsyncThunk(
  "auth/getUserInfo",
  async (args, thunkAPI) => {
    try {
      return await API.get(API_NAME, API_PATHS.USER_INFO, {});
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addHistories(state: AuthState, action: PayloadAction<string>) {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          histories: [...state.userInfo.histories, action.payload],
        },
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<AuthState>) => {
    builder
      .addCase(
        fetchAsyncGetUserInfo.fulfilled,
        (state: AuthState, action: PayloadAction<any>) => {
          return {
            ...state,
            userInfo: action.payload,
          };
        }
      )
      .addCase(
        fetchAsyncGetUserInfo.rejected,
        (state: AuthState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      );
  },
});

export const { addHistories } = authSlice.actions;

export const selectIsAdmin = (state: RootState) => state.auth.userInfo.isAdmin;
export const selectUserInfo = (state: RootState) => state.auth.userInfo;
export default authSlice.reducer;
