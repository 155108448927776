import { WelfareStateType } from "ducks/welfare/type";

export const initialState: WelfareStateType = {
  isLoading: true,
  isUpdateLoading: false,
  isUpdateFinished: false,
  kokonobiList: [],
  kokomemoList: [],
  kokokaraList: [],
  kokonegiList: [],
  kokohaguList: [],

  isDetailLoading: true,
  welfareDetail: {
    sortKey: "",
    name: "",
    icon: "",
    division: "",
    explanation: "",
    application: "",
  },
  putWelfareDetailInput: {
    sortKey: "",
    name: "",
    explanation: "",
    application: "",
    updatedAt: "",
  },
};
