import { ActivityReportAddState } from "./type";

export const initialState: ActivityReportAddState = {
  eyeCatchImg: "",
  title: "",
  texts: [
    {
      text: "",
      mdText: "",
      editFlg: false,
      type: 0,
    },
  ],
  htmlText: "",
  contributorId: "",
  draftReportList: [],
};
