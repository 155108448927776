import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { ActivityReportDetailState } from "./type";
import { initialState } from "ducks/activityReportHistory/initialState";
import { API_NAME, API_PATHS } from "common/constants";

/**
 * 活動報告閲覧済みリストの追加
 * @param partitionKey 活動報告記事のpartitionKey
 * @param accountId GoogleId
 **/
export const fetchAsyncputActivityReportViewedHistory = createAsyncThunk(
  "activityReportViewedHistory/putActivityReportViewedHistory",
  async (
    args: { partitionKey: string, accountId: string, updatedAt: string },
    thunkAPI
  ) => {
    // DB更新パラメータ
    const params = {
      body: {
        methodName: "fetchAsyncputActivityReportViewedHistory",
        partitionKey: args.accountId,
        addedArticleId: args.partitionKey,
        updatedAt: args.updatedAt,
      },
    };
    try {
      const res: any = await API.put(
        API_NAME,
        API_PATHS.ACTIVITY_REPORT_HISTORY,
        params
      );
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const activityReportHistorySlice = createSlice({
  name: "activityReportHistory",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ActivityReportDetailState>) => {
    builder

    .addCase(
      fetchAsyncputActivityReportViewedHistory.fulfilled,
      (state, action: PayloadAction<any>) => {
      }
    )
    .addCase(
      fetchAsyncputActivityReportViewedHistory.rejected,
      (state, action: PayloadAction<any>) => {
        window.location.href = "/error";
      }
    )
    .addCase(
      fetchAsyncputActivityReportViewedHistory.pending,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          isLoading: true,
        };
      }
    );
  },
});

export default activityReportHistorySlice.reducer;
