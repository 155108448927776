import { ACTIVITY_BUTTONS } from "common/constants";
import { TypeButton } from "common/types";
import { RowType } from "ducks/activityAdd/type";
import {
  ChangeEvent,
  ClipboardEvent,
  FC,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Icon } from "@iconify/react";
import { useActivityUpdate } from "hooks/activityUpdateHooks";

type ActivityUpdateEditRowProps = {
  row: RowType;
  index: number;
};
const ActivityUpdateEditRow: FC<ActivityUpdateEditRowProps> = ({
  row,
  index,
}) => {
  /* customHooks */
  const {
    texts,
    onClickP,
    onClickTypeButton,
    onClickHiddenInput,
    onClickRowImageType,
    onKeyEnterRow,
    onKeyBackSpaceRow,
    onBlurInput,
    onChangeInput,
    onChangeRowImage,
    dropAndPasteFunction,
    onPasteRow,
    judgeInputStyle,
    judgePStyle,
    judgeTypeIconJudge,
  } = useActivityUpdate();

  /* useState */
  const [openButtonPop, setOpenButtonPop] = useState<boolean>(false);

  /* useRef */
  const inputRef = useRef(null);
  const textRef = useRef(null);
  const buttonAreaRef = useRef(null);
  const imageRef = useRef(null);

  /* useEffect */
  useEffect(() => {
    if (inputRef.current) {
      const inputElem: HTMLInputElement = inputRef.current;
      inputElem.focus();
    }
  }, [row.editFlg]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        gap: 2,
      }}
    >
      {row.editFlg ? (
        <input
          type="text"
          id="name"
          name="name"
          placeholder="画像はドラッグ＆ドロップ"
          required
          value={row.text}
          style={judgeInputStyle(row)}
          onBlur={() => {
            onBlurInput(index);
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onChangeInput(e.target.value, index);
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key !== "Enter" && e.key !== "Backspace") return; //1
            if (!e.nativeEvent.isComposing && e.key === "Enter") {
              e.preventDefault(); //2
              onKeyEnterRow(index);
            } else if (
              e.key === "Backspace" &&
              texts.length > 1 &&
              row.text === ""
            ) {
              e.preventDefault(); //2
              onKeyBackSpaceRow(index);
            }
          }}
          onPaste={(e: ClipboardEvent<HTMLInputElement>) => {
            e.preventDefault();
            if (inputRef.current) {
              const inputElem: HTMLInputElement = inputRef.current;
              const selectionStart = inputElem.selectionStart;
              const selectionEnd = inputElem.selectionEnd;
              onPasteRow(
                index,
                selectionStart ? selectionStart : 0,
                selectionEnd ? selectionEnd : 0
              );
            }
          }}
          ref={inputRef}
        />
      ) : (
        <p
          ref={textRef}
          style={judgePStyle(row)}
          onClick={() => {
            onClickP(index);
          }}
        >
          {row.text}
        </p>
      )}
      <div
        style={{
          cursor: "pointer",
          border: openButtonPop ? "solid" : undefined,
          borderWidth: 0.5,
          borderRadius: 15,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: 26,
          height: 26,
        }}
        onClick={() => {
          setOpenButtonPop(!openButtonPop);
        }}
      >
        {openButtonPop ? (
          <Icon icon="majesticons:close" />
        ) : (
          <Icon icon="ion:open" />
        )}
      </div>
      <div>
        {row.type !== 0 && (
          <div style={{ color: "red" }}>
            <Icon icon={judgeTypeIconJudge(index)} />
          </div>
        )}
      </div>
      {openButtonPop && (
        <div
          style={{
            zIndex: 2,
            position: "absolute",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "start",
            gap: 3,
            backgroundColor: "white",
            padding: 6,
            right: -20,
            top: -40,
          }}
          ref={buttonAreaRef}
        >
          {ACTIVITY_BUTTONS.map((typeButton: TypeButton) => (
            <div
              style={{
                cursor: "pointer",
                width: 26,
                height: 26,
                border: "solid",
                borderWidth: 1,
                borderRadius: 15,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor:
                  row.type === typeButton.value ? "red" : undefined,
              }}
              onClick={() => {
                if (typeButton.value === 6 && row.type !== 6) {
                  onClickRowImageType(imageRef);
                } else {
                  onClickTypeButton(typeButton.value, index, setOpenButtonPop);
                }
              }}
            >
              <Icon icon={typeButton.icon} />
            </div>
          ))}
        </div>
      )}
      <input
        type="text"
        id="file"
        onClick={() => {
          onClickHiddenInput(index);
        }}
        onPaste={async (event) => {
          await dropAndPasteFunction(event.clipboardData, index);
        }}
        onDrop={async (event) => {
          event.preventDefault();
          await dropAndPasteFunction(event.dataTransfer, index);
        }}
        style={{
          zIndex: 1,
          position: "absolute",
          width: "60%",
          height: "100%",
          opacity: 0,
          cursor: "default",
        }}
      />
      <input
        style={{ display: "none" }}
        type="file"
        ref={imageRef}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChangeRowImage(e, index, setOpenButtonPop)
        }
        // 同じファイルを再度指定された時用
        onClick={(e: MouseEvent<HTMLInputElement>) => {
          (e.target as HTMLInputElement).value = "";
        }}
      />
    </div>
  );
};

export default ActivityUpdateEditRow;
