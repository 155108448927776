import { FC, useState, ChangeEvent } from "react";
import { TbCameraPlus } from "react-icons/tb";

import { DEFAULT_USER_ICON } from "common/constants";
import { useProfileEdit } from "hooks/profileEditHooks";
import EditPictureTrimming from "./EditPictureTrimming";
import styles from "styles/components/ProfileEdit/EditThumbnail.module.css";

/**
 * サムネ変更部分
 * @returns
 */
const EditThumbnail: FC = () => {
  const { profile } = useProfileEdit();

  const [isThumbnailLoading, setIsThumbnailLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalSrc, setModalSrc] = useState<string>("");

  /** 写真変更 */
  const changePicture = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file: File = e.target.files[0];

      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        setModalSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
    setIsModalOpen(true);
  };

  /** モーダルを閉じる */
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.edit_thumbnail}>
      <div className={styles.thumbnail}>
        <img
          src={profile.picture}
          alt="thumbnail"
          style={{ display: isThumbnailLoading ? "block" : "none" }}
          onLoad={() => setIsThumbnailLoading(true)}
          onError={(e: ChangeEvent<HTMLImageElement>) =>
            (e.target.src = DEFAULT_USER_ICON)
          }
        />
      </div>
      <div className={styles.thumbnail_upload_btn}>
        <label>
          <span>
            <TbCameraPlus />
          </span>
          <input
            onChange={changePicture}
            type="file"
            accept=".jpg,.gif,.png,image/gif,image/jpeg,image/png"
          />
        </label>
      </div>
      <EditPictureTrimming
        isOpen={isModalOpen}
        src={modalSrc}
        closeModal={closeModal}
      />
    </div>
  );
};

export default EditThumbnail;
