import { FC } from "react";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import styles from "styles/components/Login.module.css";
import { LOGO_FILE_NAME } from "common/constants";
import { selectUserInfo } from "ducks/auth/slice";
import { UserInfo } from "ducks/auth/type";
import { Button } from "components/Shadcn/Button";
import { FcGoogle } from "react-icons/fc";

/**
 * ログイン
 * @returns
 */
const Login: FC = () => {
  const userInfo: UserInfo = useSelector(selectUserInfo);
  return (
    <div className={styles.root}>
      <div className={styles.component}>
        <img
          className={styles.portal_logo}
          src={LOGO_FILE_NAME}
          alt="not found"
        />
        <Button
          variant="outline"
          className="text-xl mt-4 h-14 w-80"
          onClick={() =>
            Auth.federatedSignIn({
              provider: CognitoHostedUIIdentityProvider.Google,
            })
          }>
          <FcGoogle className="size-10 mr-2" />
          Sign in with Google
        </Button>
        {userInfo.deleteFlg === 1 && (
          <p className={styles.error}>社員資格が無効です</p>
        )}
      </div>
    </div>
  );
};

export default Login;
