import { FC, useState, MouseEvent, useCallback } from "react";
import { BiChevronRight } from "react-icons/bi";
import { AiOutlineEdit } from "react-icons/ai";

import { lockModalBack, unlockModalBack } from "common/common";
import InfoLabel from "components/Common/InfoLabel";
import NoticeEditModal from "components/Home/NoticeEditModal";
import { useHome } from "hooks/homeHooks";
import { NoticeDetail } from "ducks/notice/type";

import styles from "styles/components/Home/Notice.module.css";

/**
 * お知らせ
 * @param param0
 * @returns
 */
const Notice: FC = () => {
  const { isAdmin, notices, convertNoticeDateFormat, checkIsNewInfo } =
    useHome();

  const [scrollPos, setScrollPos] = useState<number>(0);
  const [updateTitle, setUpdateTitle] = useState<string>("");
  const [updateContent, setUpdateContent] = useState<string>("");
  const [updateCreatedAt, setUpdateCreatedAt] = useState<string>("");
  const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);

  /** お知らせ編集モーダル(追加/更新) */
  const [isNoticeEditModal, setIsNoticeEditModal] = useState<boolean>(false);
  const openNoticeEditModal = useCallback(
    (
      title: string = "",
      content: string = "",
      createdAt: string = "",
      isUpdate: boolean = false
    ) => {
      setUpdateTitle(title);
      setUpdateContent(content);
      setUpdateCreatedAt(createdAt);
      setIsUpdateMode(isUpdate);
      setScrollPos(window.pageYOffset);
      lockModalBack(scrollPos);
      setIsNoticeEditModal(true);
    },
    [scrollPos]
  );
  const closeNoticeEditModal = useCallback(() => {
    setIsNoticeEditModal(false);
    unlockModalBack(scrollPos);
  }, [scrollPos]);

  return (
    <div className={styles.notice}>
      <section className={styles.notice_section}>
        <h1 id="noticeBoard" className={styles.notice_section_title}>
          お知らせ
          {isAdmin && (
            <span
              className={styles.addIcon}
              onClick={(e: MouseEvent<HTMLSpanElement>) =>
                openNoticeEditModal()
              }
            >
              <AiOutlineEdit />
            </span>
          )}
        </h1>
        <ul className={styles.notice_section_contents}>
          {notices.map((notice: NoticeDetail, index: number) => (
            <li key={index}>
              <input
                id={notice.title}
                className={`${styles.notice_check} hidden`}
                type="checkbox"
              />
              <label htmlFor={notice.title}>
                <div className={styles.notice_item}>
                  <small className={styles.created_at}>
                    {convertNoticeDateFormat(notice.createdAt)}
                  </small>
                  <div className={styles.notice_item_container}>
                    <div className={styles.notice_item_title_wrapper}>
                      <h2 className={styles.notice_item_title}>
                        {checkIsNewInfo(notice.createdAt) && (
                          <span className={styles.new_label}>
                            <InfoLabel>New</InfoLabel>
                          </span>
                        )}
                        {notice.title}
                      </h2>
                    </div>
                    <BiChevronRight className={styles.notice_item_arrow_icon} />
                  </div>
                  <div className={styles.notice_item_content}>
                    {notice.content}
                  </div>
                </div>
              </label>
              {isAdmin && (
                <span
                  className={styles.updateIcon}
                  onClick={(e: MouseEvent<HTMLSpanElement>) =>
                    openNoticeEditModal(
                      notice.title,
                      notice.content,
                      notice.createdAt,
                      true
                    )
                  }
                >
                  <AiOutlineEdit />
                </span>
              )}
            </li>
          ))}
        </ul>
      </section>

      {isNoticeEditModal && (
        <NoticeEditModal
          isOpen={isNoticeEditModal}
          closeModal={closeNoticeEditModal}
          isUpdateMode={isUpdateMode}
          title={updateTitle}
          content={updateContent}
          createdAt={updateCreatedAt}
        />
      )}
    </div>
  );
};

export default Notice;
