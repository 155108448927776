import { FC } from "react";
import { Box } from "@mui/material";

type Props = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel: FC<Props> = ({ children, index, value }) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={index.toString()}>
      {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
