import { FC, memo, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";

import { PAGES } from "common/PAGES";
import { LOCAL_STORAGE_KEYS } from "common/constants";
import { AppDispatch } from "app/store";
import { lockModalBack, unlockModalBack } from "common/common";
import WelfareDetailModal from "components/Welfares/WelfareDetailModal";
import { fetchAsyncGetWelfareDetail } from "ducks/welfare/slice";
import { selectIsAdmin } from "ducks/auth/slice";
import { Button } from "components/Shadcn/Button";
import { useNavigate } from "react-router-dom";

type Props = {
  sortKey: string;
  name: string;
  icon: string;
};

/**
 * 福利厚生アイテム
 * @param Props
 * @returns
 */
const WelfareItem: FC<Props> = ({ sortKey, name, icon }) => {
  const navigate = useNavigate();
  const isAdmin: boolean = useSelector(selectIsAdmin);

  // field
  const dispatch = useDispatch<AppDispatch>();
  const [isDetailModal, setIsDetailModal] = useState<boolean>(false);
  const [scrollPos, setScrollPos] = useState<number>(0);

  // function
  const openDetailModal = () => {
    dispatch(fetchAsyncGetWelfareDetail(sortKey));
    const pos = window.pageYOffset;
    setScrollPos(pos);
    lockModalBack(scrollPos);
    setIsDetailModal(true);
  };

  const closeDetailModal = () => {
    unlockModalBack(scrollPos);
    setIsDetailModal(false);
  };

  const clickEditWelfare = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.WELFARE_EDIT_KEY, sortKey);
    navigate(PAGES.WELFARE_EDIT.PATH);
  };

  return (
    <>
      <li className="relative lg:w-[184px] lg:h-[184px] mx-[10px] mb-[12px] text-white bg-[#0e1d3e] rounded-[var(--default-border-radius)] shadow-[0_0_3px_0_rgba(0,0,0,0.12),0_2px_3px_0_rgba(0,0,0,0.22)]
      md:w-[170px] md:h-[170px] w-[140px] h-[140px]
        hover:cursor-pointer hover:opacity-90" onClick={openDetailModal}>
        <div className="lg:mt-[12px] lg:text-[18px] text-center
        md:mt-[10px] md:text-[16px] mt-[10px] text-[14px]">{name}</div>
        <Icon className="block lg:w-[64px] lg:h-[64px] lg:mt-[25px] mx-auto mb-0
        md:w-[52px] md:h-[52px] w-[34px] h-[34px] mt-[20px]" icon={icon} />
        {isAdmin && (
          <Button
            variant="admin"
            className="absolute right-[5px] bottom-[5px] h-[18px] text-[10px] md:h-[26px] lg:right-[10px] lg:bottom-[10px] lg:h-[22px] lg:text-[13px]"
            onClick={clickEditWelfare}>
            <span className="text-center">
              <AiOutlineEdit />
            </span>
          </Button>
        )}
      </li>
      <WelfareDetailModal
        isOpen={isDetailModal}
        closeModal={closeDetailModal}
      />
    </>
  );
};

export default memo(WelfareItem);
