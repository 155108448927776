import { PageType } from "common/PAGES";
import { FC, memo } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "./Shadcn/Breadcrumb";

type Props = {
  breadcrumbs: PageType[];
};

/**
 * 引数をもとに、パンくずのHTMLを生成する
 * @param Props 表示するパンくずの情報
 * @returns パンくずリスト
 */
const Breadcrumbs: FC<Props> = ({ breadcrumbs }) => {
  const navigate = useNavigate();
  return (
    <Breadcrumb className="max-w-[1200px] mx-auto pt-[10px] pl-[10px] md:pl-[50px]" >
      <BreadcrumbList className="my-4">
        {breadcrumbs.map((breadcrumb: PageType, index: number) => {
          return (
            <BreadcrumbItem>
              {breadcrumbs.length === index + 1 ? (
                <BreadcrumbPage>{breadcrumb.NAME}</BreadcrumbPage>
              ) : (
                <>
                  <BreadcrumbLink onClick={() => navigate(breadcrumb.PATH)}>
                    {breadcrumb.NAME}
                  </BreadcrumbLink>
                  <BreadcrumbSeparator />
                </>
              )}
            </BreadcrumbItem>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export default memo(Breadcrumbs);
