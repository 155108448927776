import { PAGES } from "common/PAGES";
import {
  APPLICATION_LINKS,
  LOGO_FILE_NAME,
} from "common/constants";
import ThumbnailMenu from "components/Header/ThumbnailMenu";
import { cn } from "lib/utils";
import { Menu } from "lucide-react";
import React, { FC, memo } from "react";
import { Link } from "react-router-dom";
import { Button } from "./Shadcn/Button";
import { NavigationMenu, NavigationMenuContent, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, NavigationMenuTrigger } from "./Shadcn/NavigationMenu";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from "./Shadcn/Sheet";

type Props = {
  currentPageName: string;
};

/**
 * 共通ヘッダー
 * @param Props
 * @returns
 */
const Header: FC<Props> = ({ currentPageName }) => {

  /**
   * ナビゲーション用のスタイルを返却
   * @param pageName
   * @returns className
   */
  const getLinkStyle = (pageName: string) => {
    if (currentPageName === pageName) {
      return "text-primary font-semibold transition-colors hover:text-primary";
    } else {
      return "text-muted-foreground transition-colors hover:text-foreground";
    }
  };

  return (
    <>
      <header className="z-50 sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
        <nav className="hidden flex-col gap-6 text-lg font-medium whitespace-nowrap md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
          <Link to={PAGES.HOME.PATH} className="w-[110px] -mt-2">
            <img src={LOGO_FILE_NAME} alt="kokonavi_logo" />
          </Link>
          {Object.entries(PAGES)
            .filter(([key, PAGE]) => PAGE !== PAGES.ERROR && PAGE.IS_ON_HEADER)
            .map(([key, PAGE]) => (
              <Link key={key} to={PAGE.PATH} className={getLinkStyle(PAGE.NAME)}>{PAGE.NAME}</Link>
            ))}
          <NavigationMenu>
            <NavigationMenuList>
              <NavigationMenuItem>
                <NavigationMenuTrigger className={cn("p-0 focus:bg-background focus:text-muted-foreground hover:bg-background data-[active]:bg-background data-[state=open]:bg-background", getLinkStyle(""))}>各種申請</NavigationMenuTrigger>
                <NavigationMenuContent>
                  {/* 現状は項目が少ないため2列表示(lg:grid-cols-2)にはしない */}
                  <ul className="grid w-[250px] gap-3 p-4 lg:w-[350px]">
                    {APPLICATION_LINKS.map((link) => (
                      <ListItem
                        key={link.title}
                        title={link.title}
                        href={link.href}
                        className="whitespace-normal"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.description}
                      </ListItem>
                    ))}
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
        </nav>
        <Sheet>
          <SheetTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="shrink-0 md:hidden"
            >
              <Menu className="h-5 w-5" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left">
            <SheetHeader>
              <Link to={PAGES.HOME.PATH} className="w-[110px]">
                <img src={LOGO_FILE_NAME} alt="kokonavi_logo" />
              </Link>
              {/* コンソールエラー、警告を回避するため、空のSheetTitle, Descriptionを追加 */}
              <SheetTitle></SheetTitle>
              <SheetDescription></SheetDescription>
            </SheetHeader>
            <nav className="flex flex-col space-y-6 overflow-y-auto pl-4 h-[calc(100vh-6rem)]">
              <div className="flex flex-col space-y-2">
                {Object.entries(PAGES)
                  .filter(([key, PAGE]) => PAGE !== PAGES.ERROR && PAGE.IS_ON_HEADER)
                  .map(([key, PAGE]) => (
                    <Link key={key} to={PAGE.PATH} className={`${getLinkStyle(PAGE.NAME)}`}>{PAGE.NAME}</Link>
                  ))}
              </div>
              <div className="flex flex-col space-y-2">
                <h5 className="text-black">各種申請</h5>
                {APPLICATION_LINKS.map((link, index) => (
                  <a key={index} href={link.href} className={`${getLinkStyle("")} pl-4 border-l hover:border-slate-500`} target="_blank" rel="noopener noreferrer">{link.title}</a>
                ))}
              </div>
            </nav>
          </SheetContent>
        </Sheet>
        <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
          <div className="ml-auto flex-initial">
            <ThumbnailMenu />
          </div>
        </div>
      </header>
    </>
  );
};

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})
ListItem.displayName = "ListItem"

export default memo(Header);
