import { FC, useMemo } from "react";

import { PAGES, PageType } from "common/PAGES";
import FloatingLink from "components/Home/FloatingLink";
import HomeTop from "components/Home/HomeTop";
import Notice from "components/Home/Notice";
import Layout from "components/Layout";

const Home: FC = () => {
  const pageName: string = useMemo(() => PAGES.HOME.NAME, []);
  const breadcrumbs: PageType[] = useMemo(() => [], []);


  return (
    <Layout pageName={pageName} breadcrumbs={breadcrumbs}>
      <div className="flex flex-col max-w-[1100px] pb-[100px] m-auto">
        <div className="flex items-center justify-center w-full">
          <HomeTop />
        </div>
        <div className="flex items-center justify-center w-full">
          <Notice />
        </div>
        <FloatingLink />
      </div>
    </Layout>
  );
};

export default Home;
