import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { AppDispatch } from "app/store";
import { getDateTimeNow2 } from "common/common";
import {
  fetchAsyncGetNotices,
  selectIsUpdateFinished,
  selectIsUpdateLoading,
  selectNotices,
} from "ducks/notice/slice";
import { NoticeDetail } from "ducks/notice/type";
import { selectIsLoading } from "ducks/employee/slice";
import { selectIsAdmin } from "ducks/auth/slice";

/**
 * ホーム画面用カスタムフックス
 * @returns
 */
export const useHome = () => {
  const dispatch = useDispatch<AppDispatch>();

  const isAdmin: boolean = useSelector(selectIsAdmin);
  const isLoading: boolean = useSelector(selectIsLoading);
  const isUpdateLoading: boolean = useSelector(selectIsUpdateLoading);
  const isUpdateFinished: boolean = useSelector(selectIsUpdateFinished);
  const notices: NoticeDetail[] = useSelector(selectNotices);

  /**
   * お知らせ表示用のフォーマットに変換
   * @param createdAt yyyy:mm:dd hh:mm:ss
   * @returns string yyyy.mm.dd
   */
  const convertNoticeDateFormat = useCallback((createdAt: string): string => {
    return createdAt.substring(0, createdAt.indexOf(" ")).replaceAll("/", ".");
  }, []);

  /**
   * Newラベルを付けるかの判定
   * 30日前までのはNewラベルを表示
   * @param createdAt 作成日時
   * @returns true/false
   */
  const checkIsNewInfo = useCallback((createdAt: string): boolean => {
    const dateStr: string = createdAt.substring(0, createdAt.indexOf(" "));
    const createdAtDate: Date = new Date(dateStr);
    const newLineDate: Date = new Date();
    newLineDate.setDate(newLineDate.getDate() - 30);
    newLineDate.setHours(0);
    newLineDate.setMinutes(0);
    newLineDate.setSeconds(0);
    return createdAtDate.getTime() >= newLineDate.getTime();
  }, []);

  /** お知らせ取得 */
  const getNotices = useCallback(() => {
    const date = getDateTimeNow2();
    dispatch(fetchAsyncGetNotices(date));
  }, [dispatch]);

  useEffect(() => {
    getNotices();
  }, [getNotices]);

  return {
    dispatch,
    isAdmin,
    notices,
    isLoading,
    isUpdateLoading,
    isUpdateFinished,
    convertNoticeDateFormat,
    checkIsNewInfo,
    getNotices,
  };
};
