import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "app/store";
import { getDateTimeNow, escapeForAwsIntegra } from "common/common";
import {
  LOCAL_STORAGE_KEYS,
  WELFARE_ITEM_MAX_LENGTH,
  WELFARE_INPUT_KEY,
} from "common/constants";
import { PAGES, PageType } from "common/PAGES";
import NoticeCard from "components/Common/NoticeCard";
import Layout from "components/Layout";
import Loader from "components/Loader";
import PortalBtn from "components/Common/PortalBtn";
import {
  fetchAsyncGetWelfareDetail,
  fetchAsyncPutWelfareDetail,
  selectIsDetailLoading,
  selectIsUpdateFinished,
  selectIsUpdateLoading,
  selectWelfareDetail,
} from "ducks/welfare/slice";
import { WelfareDetailType, PutWelfareDetailInput } from "ducks/welfare/type";

import styles from "styles/containers/WelfareEdit.module.css";
import { Textarea } from "components/Shadcn/Textarea";
import { Label } from "components/Shadcn/Label";
import { Input } from "components/Shadcn/Input";

/**
 * 福利厚生編集画面
 * @returns
 */
const WelfareEdit: FC = () => {
  const pageName: string = useMemo(() => PAGES.WELFARE_EDIT.NAME, []);
  const breadcrumbs: PageType[] = useMemo(
    () => [PAGES.HOME, PAGES.WELFARE_INFO, PAGES.WELFARE_EDIT],
    []
  );

  const isLoading: boolean = useSelector(selectIsDetailLoading);
  const isUpdateLoading: boolean = useSelector(selectIsUpdateLoading);
  const isUpdateFinished: boolean = useSelector(selectIsUpdateFinished);
  const dispatch = useDispatch<AppDispatch>();
  const welfare: WelfareDetailType = useSelector(selectWelfareDetail);
  const now: string = getDateTimeNow();

  const [isDisabledExe, setIsDisabeldExe] = useState<boolean>(false);
  const [nameVal, setNameVal] = useState<string>(welfare.name);
  const [explanationVal, setExplanationVal] = useState<string>(
    welfare.explanation
  );
  const [applicationVal, setApplicationVal] = useState<string>(
    welfare.application
  );

  const changeNameVal = (e: ChangeEvent<HTMLInputElement>) => {
    setNameVal(e.target.value);
  };
  const changeExplanationVal = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setExplanationVal(e.target.value);
  };
  const changeApplicationVal = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setApplicationVal(e.target.value);
  };

  /** 更新実行処理 */
  const clickUpdate = () => {
    // 更新
    const input: PutWelfareDetailInput = {
      sortKey: welfare.sortKey,
      name: nameVal,
      explanation: escapeForAwsIntegra(explanationVal),
      application: escapeForAwsIntegra(applicationVal),
      updatedAt: now,
    };
    dispatch(fetchAsyncPutWelfareDetail(input));
  };

  useEffect(() => {
    const targetKey: string | null = localStorage.getItem(
      LOCAL_STORAGE_KEYS.WELFARE_EDIT_KEY
    );
    targetKey && dispatch(fetchAsyncGetWelfareDetail(targetKey));
  }, [dispatch]);

  // 更新用の値をセット
  useEffect(() => {
    setNameVal(welfare.name);
    setExplanationVal(welfare.explanation);
    setApplicationVal(welfare.application);
  }, [welfare]);

  // 更新チェック
  useEffect(() => {
    if (
      (nameVal === welfare.name &&
        explanationVal === welfare.explanation &&
        applicationVal === welfare.application) ||
      !nameVal ||
      !explanationVal ||
      !applicationVal
    ) {
      setIsDisabeldExe(true);
    } else {
      setIsDisabeldExe(false);
    }
  }, [nameVal, explanationVal, applicationVal, welfare]);

  return (
    <Layout pageName={pageName} breadcrumbs={breadcrumbs}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.container}>
          <div className={styles.card}>
            <form className={styles.form}>
              <div>
                <div>
                  <div>
                    <Label htmlFor={WELFARE_INPUT_KEY.NAME}>福利厚生名</Label>
                    <Input
                      type="text"
                      id={WELFARE_INPUT_KEY.NAME}
                      className="mt-2"
                      maxLength={WELFARE_ITEM_MAX_LENGTH.NAME}
                      defaultValue={nameVal}
                      onChange={changeNameVal}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.input_boxes}>
                <div>
                  <div className="mt-8">
                    <Label htmlFor={WELFARE_INPUT_KEY.EXPLANATION}>概要</Label>
                    <Textarea
                      className="mt-2"
                      id={WELFARE_INPUT_KEY.EXPLANATION}
                      defaultValue={explanationVal}
                      maxLength={WELFARE_ITEM_MAX_LENGTH.EXPLANATION}
                      onChange={changeExplanationVal}
                    />
                  </div>
                </div>

                <div className="mb-8">
                  <div className="mt-8">
                    <Label htmlFor={WELFARE_INPUT_KEY.APPLICATION}>
                      申請方法
                    </Label>
                    <Textarea
                      className="mt-2"
                      id={WELFARE_INPUT_KEY.APPLICATION}
                      defaultValue={applicationVal}
                      maxLength={WELFARE_ITEM_MAX_LENGTH.APPLICATION}
                      onChange={changeApplicationVal}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className={styles.update_btn}>
            {
              <PortalBtn
                clickFunc={clickUpdate}
                disabled={isDisabledExe}
                isLoading={isUpdateLoading}
              >
                更新
              </PortalBtn>
            }
          </div>
          <NoticeCard isFadedIn={isUpdateFinished}>
            更新が完了しました。
          </NoticeCard>
        </div>
      )}
    </Layout>
  );
};

export default WelfareEdit;
