import { ChangeEvent, FC, useCallback, useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "app/store";

import uuid from "react-uuid";
import { getDateTimeNow } from "common/common";
import NoticeCard from "components/Common/NoticeCard";
import PortalBtn from "components/Common/PortalBtn";
import { fetchAsyncPostFaqTitle, fetchAsyncPutFaqTitle } from "ducks/faq/slice";
import { PostFaqTitleInput, PutFaqTitleInput } from "ducks/faq/type";

import {
  fetchAsyncGetFaqs,
  selectIsUpdateFinished,
  selectIsUpdateLoading,
  unsetIsPostFinished,
} from "ducks/faq/slice";

import styles from "styles/components/Faq/FaqTitleEditModal.module.css";
import { Input } from "components/Shadcn/Input";
import { Label } from "components/Shadcn/Label";

type Props = {
  isOpen: boolean;
  closeModal: VoidFunction;
  isUpdateMode?: boolean;
  title?: string;
  titlePartitionKey?: string;
  sortOrder?: number;
};

/**
 * FAQ詳細追加/編集モーダル
 * @param param0
 * @returns
 */
const FaqTitleEditModal: FC<Props> = ({
  isOpen,
  closeModal,
  isUpdateMode = false,
  title = "",
  titlePartitionKey = "",
  sortOrder = 0,
}) => {
  const isUpdateLoading: boolean = useSelector(selectIsUpdateLoading);
  const isUpdateFinished: boolean = useSelector(selectIsUpdateFinished);
  const dispatch = useDispatch<AppDispatch>();

  const [titleVal, setTitleVal] = useState<string>(title);
  const [sortOrderVal, setSortOrderVal] = useState<number>(sortOrder);
  const [isDisabledExe, setIsDisabeldExe] = useState<boolean>(false);

  const actionMode: "登録" | "更新" = isUpdateMode ? "更新" : "登録";

  /** input */
  const changeSortOrderVal = (e: ChangeEvent<HTMLInputElement>) => {
    setSortOrderVal(Number(e.target.value));
  };
  const changeTitleVal = (e: ChangeEvent<HTMLInputElement>) => {
    setTitleVal(e.target.value);
  };
  const initVal = () => {
    setSortOrderVal(0);
    setTitleVal("");
  };

  /** モーダルクローズ */
  const closeModalOverride = useCallback(() => {
    initVal();
    dispatch(fetchAsyncGetFaqs());
    dispatch(unsetIsPostFinished());
    closeModal();
  }, [dispatch, closeModal]);

  /** 処理実行 */
  const clickExe = () => {
    const now: string = getDateTimeNow();
    if (isUpdateMode) {
      // 更新
      const input: PutFaqTitleInput = {
        titlePartitionKey: titlePartitionKey,
        titleSortKey: "faq#title",
        title: titleVal,
        sortOrder: sortOrderVal,
        updatedAt: now,
      };
      dispatch(fetchAsyncPutFaqTitle(input));
    } else {
      // 追加
      const input: PostFaqTitleInput = {
        titlePartitionKey: uuid(),
        titleSortKey: "faq#title",
        title: titleVal,
        sortOrder: sortOrderVal,
        createdAt: now,
      };
      dispatch(fetchAsyncPostFaqTitle(input));
    }
  };

  useEffect(() => {
    isUpdateFinished && !isUpdateMode && initVal();
  }, [isUpdateFinished, isUpdateMode]);

  //TODO:数字チェックを入れる
  /** input check */
  useEffect(() => {
    if (isUpdateMode) {
      // 更新用
      if (
        (titleVal === title && sortOrderVal === sortOrder) ||
        !titleVal ||
        !sortOrderVal
      ) {
        setIsDisabeldExe(true);
      } else {
        setIsDisabeldExe(false);
      }
    } else {
      // 追加用
      if (!sortOrderVal || !titleVal) {
        setIsDisabeldExe(true);
      } else {
        setIsDisabeldExe(false);
      }
    }
  }, [dispatch, sortOrderVal, titleVal, sortOrder, title, isUpdateMode]);

  return (
    <>
      {isOpen && (
        <div className={styles.overlay} onClick={closeModalOverride}>
          <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
            <div className={styles.modal_contents}>
              <div className={styles.title_box}>
                {isUpdateMode && <p>目次編集</p>}
                {!isUpdateMode && <p>目次追加</p>}
              </div>
              <div className="mt-3">
                <div>
                  <Label htmlFor={"title_input"}>タイトル</Label>
                  <Input
                    type="text"
                    id={"title_input"}
                    placeholder="タイトルを入力"
                    maxLength={50}
                    value={titleVal}
                    onChange={changeTitleVal}
                  />
                </div>
              </div>

              <div className="mt-4">
                <div>
                  <Label htmlFor={"sort_oder_area"}>
                    表示順（昇順で表示されます）
                  </Label>
                  <Input
                    type="number"
                    id={"sort_oder_area"}
                    placeholder="表示順を入力（数字で入力）"
                    value={sortOrderVal}
                    onChange={changeSortOrderVal}
                  />
                </div>
              </div>

              <div className={styles.btns}>
                <PortalBtn
                  variant="outline"
                  clickFunc={closeModalOverride}
                  isLoading={isUpdateLoading}
                >
                  閉じる
                </PortalBtn>
                <PortalBtn
                  clickFunc={clickExe}
                  isLoading={isUpdateLoading}
                  disabled={isDisabledExe}
                >
                  {actionMode}
                </PortalBtn>
              </div>
            </div>
          </div>
        </div>
      )}
      <NoticeCard isFadedIn={isUpdateFinished}>
        {`${actionMode}が完了しました`}
      </NoticeCard>
    </>
  );
};

export default memo(FaqTitleEditModal);
