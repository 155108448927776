import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "ducks/auth/slice";
import employeeReducer from "ducks/employee/slice";
import profileReducer from "ducks/profile/slice";
import welfareReducer from "ducks/welfare/slice";
import noticeReducer from "ducks/notice/slice";
import activityReportReducer from "ducks/activity/slice";
import activityReportAddReducer from "ducks/activityAdd/slice";
import activityReportDetailReducer from "ducks/activityReportDetail/slice";
import activityReportUpdateReducer from "ducks/activityReportUpdate/slice";
import workRegulationReducer from "ducks/workRegulation/slice";
import faqReducer from "ducks/faq/slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    employee: employeeReducer,
    profile: profileReducer,
    welfare: welfareReducer,
    notice: noticeReducer,
    activityReport: activityReportReducer,
    activityReportAdd: activityReportAddReducer,
    activityReportDetail: activityReportDetailReducer,
    activityReportUpdate: activityReportUpdateReducer,
    workRegulation: workRegulationReducer,
    faq: faqReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["payload.file"],
        ignoredPaths: ["profile.uploadSkillSheet", "profile.uploadPicture"],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
