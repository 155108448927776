import { ActivityReportDetailState } from "./type";

export const initialState: ActivityReportDetailState = {
  isLoading: true,
  activityReportDetail: {
    partitionKey: "",
    title: "",
    createdAt: "",
    viewCount: 0,
    eyeCatchImg: "",
    mdText: "",
    htmlText: "",
    contributorName: "",
    contributorJobId: 0,
    contributorImg: "",
  },
};
