import {
  createSlice,
  createAsyncThunk,
  ActionReducerMapBuilder,
  PayloadAction,
} from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { API_NAME, API_PATHS } from "common/constants";
import { RootState } from "app/store";
import { initialState } from "ducks/welfare/initialState";
import {
  WelfareStateType,
  WelfareType,
  PutWelfareDetailInput,
} from "ducks/welfare/type";

/**
 * 福利厚生一覧取得
 * @param args none
 **/
export const fetchAsyncGetWelfares = createAsyncThunk(
  "welfare/getWelfares",
  async (args, thunkAPI) => {
    try {
      return await API.get(API_NAME, API_PATHS.WELFARES, {});
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * 福利厚生（モーダル用）取得
 * @param searchVal 検索入力値
 **/
export const fetchAsyncGetWelfareDetail = createAsyncThunk(
  "welfare/getWelfareDetail",
  async (searchVal: string, thunkAPI) => {
    const params = {
      queryStringParameters: {
        searchVal,
      },
    };
    try {
      return await API.get(API_NAME, API_PATHS.WELFARE_DETAIL, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * 福利厚生更新
 * @param input
 **/
export const fetchAsyncPutWelfareDetail = createAsyncThunk(
  "welfare/putWelfareDetail",
  async (input: PutWelfareDetailInput, thunkAPI) => {
    const params = {
      body: {
        sortKey: input.sortKey,
        name: input.name,
        explanation: input.explanation,
        application: input.application,
        updatedAt: input.updatedAt,
      },
    };
    try {
      return await API.put(API_NAME, API_PATHS.WELFARE_DETAIL, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const welfareSlice = createSlice({
  name: "welfare",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<WelfareStateType>) => {
    builder
      .addCase(
        fetchAsyncGetWelfares.fulfilled,
        (state: WelfareStateType, action: PayloadAction<any>) => {
          const kokonobiEdit: WelfareType[] = [];
          const kokomemoEdit: WelfareType[] = [];
          const kokokaraEdit: WelfareType[] = [];
          const kokonegiEdit: WelfareType[] = [];
          const kokohaguEdit: WelfareType[] = [];

          action.payload.Items.forEach((welfare: WelfareType) => {
            switch (welfare.data) {
              case "kokonobi":
                kokonobiEdit.push(welfare);
                break;
              case "kokomemo":
                kokomemoEdit.push(welfare);
                break;
              case "kokokara":
                kokokaraEdit.push(welfare);
                break;
              case "kokonegi":
                kokonegiEdit.push(welfare);
                break;
              case "kokohagu":
                kokohaguEdit.push(welfare);
                break;
              default:
                break;
            }
          });

          return {
            ...state,
            isLoading: false,
            kokonobiList: kokonobiEdit,
            kokomemoList: kokomemoEdit,
            kokokaraList: kokokaraEdit,
            kokonegiList: kokonegiEdit,
            kokohaguList: kokohaguEdit,
          };
        }
      )
      .addCase(
        fetchAsyncGetWelfares.rejected,
        (state: WelfareStateType, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncGetWelfares.pending,
        (state: WelfareStateType, action: PayloadAction<any>) => {
          return {
            ...state,
            isLoading: true,
          };
        }
      )
      .addCase(
        fetchAsyncGetWelfareDetail.fulfilled,
        (state: WelfareStateType, action: PayloadAction<any>) => {
          return {
            ...state,
            isDetailLoading: false,
            welfareDetail: action.payload.Item,
          };
        }
      )
      .addCase(
        fetchAsyncGetWelfareDetail.rejected,
        (state: WelfareStateType, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncGetWelfareDetail.pending,
        (state: WelfareStateType, action: PayloadAction<any>) => {
          return {
            ...state,
            isDetailLoading: true,
          };
        }
      )
      .addCase(
        fetchAsyncPutWelfareDetail.fulfilled,
        (state: WelfareStateType, action: PayloadAction<any>) => {
          return {
            ...state,
            isUpdateLoading: false,
            isUpdateFinished: true,
          };
        }
      )
      .addCase(
        fetchAsyncPutWelfareDetail.pending,
        (state: WelfareStateType, action: PayloadAction<any>) => {
          return {
            ...state,
            isUpdateLoading: true,
            isUpdateFinished: false,
          };
        }
      )
      .addCase(
        fetchAsyncPutWelfareDetail.rejected,
        (state: WelfareStateType, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      );
  },
});

// export const { setName, setExplanation, setApplication } = welfareSlice.actions;

export const selectIsLoading = (state: RootState) => state.welfare.isLoading;

export const selectIsUpdateLoading = (state: RootState) =>
  state.welfare.isUpdateLoading;
export const selectIsUpdateFinished = (state: RootState) =>
  state.welfare.isUpdateFinished;

export const selectKokonobiList = (state: RootState) =>
  state.welfare.kokonobiList;
export const selectKokomemoList = (state: RootState) =>
  state.welfare.kokomemoList;
export const selectKokokaraList = (state: RootState) =>
  state.welfare.kokokaraList;
export const selectKokonegiList = (state: RootState) =>
  state.welfare.kokonegiList;
export const selectKokohaguList = (state: RootState) =>
  state.welfare.kokohaguList;
export const selectIsDetailLoading = (state: RootState) =>
  state.welfare.isDetailLoading;
export const selectWelfareDetail = (state: RootState) =>
  state.welfare.welfareDetail;
export const selectPutWelfareDetailInput = (state: RootState) =>
  state.welfare.putWelfareDetailInput;
export default welfareSlice.reducer;
