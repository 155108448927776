import {
  FC,
  useState,
  useMemo,
  useEffect,
  useCallback,
  MouseEvent,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { lockModalBack, unlockModalBack } from "common/common";

import { BiChevronRight } from "react-icons/bi";
import { AiOutlineEdit } from "react-icons/ai";

import { AppDispatch } from "app/store";
import { PAGES, PageType } from "common/PAGES";
import FaqDetailEditModal from "components/Faq/FaqDetailEditModal";
import FaqTitleEditModal from "components/Faq/FaqTitleEditModal";
import Layout from "components/Layout";
import Loader from "components/Loader";
import styles from "styles/containers/Faq.module.css";

import {
  fetchAsyncGetFaqs,
  selectIsDetailLoading,
  selectFaqList,
  selectIsAdmin,
} from "ducks/faq/slice";
import { FaqType, FaqDatailType } from "ducks/faq/type";

/**
 * FAQ画面
 * @returns
 */
const Faq: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const pageName: string = useMemo(() => PAGES.FAQ.NAME, []);
  const breadcrumbs: PageType[] = useMemo(() => [PAGES.HOME, PAGES.FAQ], []);
  const isLoading: boolean = useSelector(selectIsDetailLoading);
  const isAdmin: boolean = useSelector(selectIsAdmin);
  const faqList: FaqType[] = useSelector(selectFaqList);

  useEffect(() => {
    dispatch(fetchAsyncGetFaqs());
  }, [dispatch]);

  const [scrollPos, setScrollPos] = useState<number>(0);
  const [updateTitle, setUpdateTitle] = useState<string>("");
  const [updateSortOrder, setSortOrder] = useState<number>(0);
  const [titlePartitionKey, setTitlePartitionKey] = useState<string>("");
  const [detailPartitionKey, setDetailPartitionKey] = useState<string>("");
  const [updateQuestion, setUpdateQuestion] = useState<string>("");
  const [updateAnswer, setUpdateAnswer] = useState<string>("");
  const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);

  /** FAQ詳細編集モーダル(追加/更新) */
  const [isFaqDetailEditModal, setIsFaqDetailEditModal] =
    useState<boolean>(false);
  const openFaqDetailEditModal = useCallback(
    (
      title: string = "",
      titlePartitionKey: string = "",
      detailPartitionKey: string = "",
      question: string = "",
      answer: string = "",
      isUpdate: boolean = false
    ) => {
      setUpdateTitle(title);
      setTitlePartitionKey(titlePartitionKey);
      setDetailPartitionKey(detailPartitionKey);
      setUpdateQuestion(question);
      setUpdateAnswer(answer);
      setIsUpdateMode(isUpdate);
      setScrollPos(window.pageYOffset);
      lockModalBack(scrollPos);
      setIsFaqDetailEditModal(true);
    },
    [scrollPos]
  );
  const closeFaqDetailEditModal = useCallback(() => {
    setIsFaqDetailEditModal(false);
    unlockModalBack(scrollPos);
  }, [scrollPos]);

  /** FAQタイトル編集モーダル(追加/更新) */
  const [isFaqTitleEditModal, setIsFaqTitleEditModal] =
    useState<boolean>(false);
  const openFaqTitleEditModal = useCallback(
    (
      title: string = "",
      titlePartitionKey: string = "",
      sortOrder: number = 0,
      isUpdate: boolean = false
    ) => {
      setUpdateTitle(title);
      setTitlePartitionKey(titlePartitionKey);
      setSortOrder(sortOrder);
      setIsUpdateMode(isUpdate);
      setScrollPos(window.pageYOffset);
      lockModalBack(scrollPos);
      setIsFaqTitleEditModal(true);
    },
    [scrollPos]
  );
  const closeFaqTitleEditModal = useCallback(() => {
    setIsFaqTitleEditModal(false);
    unlockModalBack(scrollPos);
  }, [scrollPos]);

  return (
    <Layout pageName={pageName} breadcrumbs={breadcrumbs}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.container}>
          <div className={styles.faq}>
            <section className={styles.faq_section}>
              <h1
                className={`${styles.faq_section_title} ${styles.faq_link_title} border-2 border-gray-500`}
              >
                目次
                {isAdmin && (
                  <span
                    className={styles.addIcon}
                    onClick={(e: MouseEvent<HTMLSpanElement>) =>
                      openFaqTitleEditModal("", "", 0, false)
                    }
                  >
                    <AiOutlineEdit />
                  </span>
                )}
              </h1>
              <div className={styles.faq_section_contents}>
                <div
                  className={`${styles.faq_item} ${styles.faq_link} border-2 border-gray-500`}
                >
                  {faqList.map((faqContent: FaqType, index) => (
                    <li key={faqContent.partitionKey}>
                      <a href={`#faq_section_${index + 1}`}>{`${index + 1}.${
                        faqContent.title
                      }`}</a>

                      {isAdmin && (
                        <span
                          className={styles.updateIcon}
                          onClick={(e: MouseEvent<HTMLSpanElement>) =>
                            openFaqTitleEditModal(
                              faqContent.title,
                              faqContent.partitionKey,
                              faqContent.sortOrder,
                              true
                            )
                          }
                        >
                          <AiOutlineEdit />
                        </span>
                      )}
                    </li>
                  ))}
                </div>
              </div>
            </section>
            {faqList.map((faqContent: FaqType, index) => (
              <section
                key={faqContent.title}
                className={styles.faq_section}
                id={`faq_section_${index + 1}`}
              >
                <h1 className={`${styles.faq_section_title}`}>
                  {faqContent.title}
                  {isAdmin && (
                    <span
                      className={styles.addIcon}
                      onClick={(e: MouseEvent<HTMLSpanElement>) =>
                        openFaqDetailEditModal(
                          faqContent.title,
                          faqContent.partitionKey,
                          "",
                          "",
                          "",
                          false
                        )
                      }
                    >
                      <AiOutlineEdit />
                    </span>
                  )}
                </h1>
                <ul className={styles.faq_section_contents}>
                  {faqContent.faqDatailList.map((element: FaqDatailType) => (
                    <li key={element.partitionKey}>
                      <input
                        id={element.partitionKey}
                        className={`${styles.faq_check} hidden`}
                        type="checkbox"
                      />
                      <label htmlFor={element.partitionKey}>
                        <div className={styles.faq_item}>
                          <div className={styles.faq_item_container}>
                            <div className={styles.faq_item_question_wrapper}>
                              <h2 className={styles.faq_item_question}>
                                <span>Q.</span>
                                {element.question}
                              </h2>
                            </div>
                            <BiChevronRight
                              className={styles.faq_item_arrow_icon}
                            />
                          </div>
                          <div className={styles.faq_item_answer}>
                            {element.answer}
                          </div>
                        </div>
                      </label>
                      {isAdmin && (
                        <span
                          className={styles.updateIcon}
                          onClick={(e: MouseEvent<HTMLSpanElement>) =>
                            openFaqDetailEditModal(
                              faqContent.title,
                              faqContent.partitionKey,
                              element.partitionKey,
                              element.question,
                              element.answer,
                              true
                            )
                          }
                        >
                          <AiOutlineEdit />
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </section>
            ))}
            {isFaqTitleEditModal && (
              <FaqTitleEditModal
                isOpen={isFaqTitleEditModal}
                closeModal={closeFaqTitleEditModal}
                isUpdateMode={isUpdateMode}
                title={updateTitle}
                titlePartitionKey={titlePartitionKey}
                sortOrder={updateSortOrder}
              />
            )}
            {isFaqDetailEditModal && (
              <FaqDetailEditModal
                isOpen={isFaqDetailEditModal}
                closeModal={closeFaqDetailEditModal}
                isUpdateMode={isUpdateMode}
                title={updateTitle}
                titlePartitionKey={titlePartitionKey}
                detailPartitionKey={detailPartitionKey}
                question={updateQuestion}
                answer={updateAnswer}
              />
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Faq;
