import { FC, useMemo, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Layout from "components/Layout";
import Loader from "components/Loader";
import { JOBS, UNREGISTERED } from "common/constants";
import { ActivityDetail } from "ducks/activityReportDetail/type";
import { PAGES, PageType } from "common/PAGES";
import {
  fetchAsyncPutActivityReportViewCount,
  selecActivityReportDetail,
  selectIsLoadingDetail,
} from "ducks/activityReportDetail/slice";
import { useActivities } from "hooks/activitiesHooks";
import { fetchAsyncGetActivityReportDetail } from "ducks/activityReportDetail/slice";
import { Icon } from "@iconify/react";
import { Card, CardMedia, Avatar, Typography, Stack } from "@mui/material";
import {
  CustomContainer,
  TitleArea,
  DayViewArea,
  AuthorArea,
  ArticleArea,
  containerSx,
  titleAreaSx,
  cardMediaSx,
  dayViewElementSx,
  dayViewAreaSx,
  authorAreaSx,
  avatarElementSx,
  typographySx,
  articleAreaSx,
  cardSx,
  infoAreaSx,
  Article,
  SideAuthorArea,
  sideAvatarElementSx,
  sideAvatarName,
  sideAvatarJobId,
  sideAuthorAreaSx,
} from "styles/components/Activities/activityDetailTheme";
import { useLocation, useNavigate } from "react-router-dom";
import { addHistories, selectUserInfo } from "ducks/auth/slice";
import { UserInfo } from "ducks/auth/type";
import { getDateTimeNow } from "common/common";
import { fetchAsyncputActivityReportViewedHistory } from "ducks/activityReportHistory/slice";
import { Button } from "components/Shadcn/Button";

/**
 * 活動報告詳細
 */
const ActivityReportDetail: FC = () => {
  const navigate = useNavigate();
  /* URLパラメータの取得 */
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const reportId = query.get("reportId");

  const pageName: string = useMemo(() => PAGES.ACTIVITY_REPORT_DETAIL.NAME, []);
  const breadcrumbs: PageType[] = useMemo(
    () => [PAGES.HOME, PAGES.ACTIVITY_REPORT, PAGES.ACTIVITY_REPORT_DETAIL],
    []
  );
  const { dispatch } = useActivities();
  const userInfo: UserInfo = useSelector(selectUserInfo);
  const activityDetail: ActivityDetail = useSelector(selecActivityReportDetail);
  const isLoading: boolean = useSelector(selectIsLoadingDetail);

  /** 活動報告詳細を取得 **/
  const effectFunc = useCallback(async () => {
    // ログインユーザーの情報が設定されていない場合は処理しない。
    if (userInfo.partitionKey === "") return;

    if (reportId) {
      const countUpdateFlg = !userInfo.histories.includes(reportId);
      const accountId = userInfo.partitionKey;
      const now: string = getDateTimeNow();
      // viewCountをインクリメントする。
      if (countUpdateFlg) {
        await dispatch(
          fetchAsyncPutActivityReportViewCount({
            partitionKey: reportId,
            updatedAt: now,
          })
        );
        await dispatch(
          fetchAsyncputActivityReportViewedHistory({
            partitionKey: reportId,
            accountId: accountId,
            updatedAt: now,
          })
        );
      }
      dispatch(addHistories(reportId));

      // 活動報告詳細情報を取得する。
      await dispatch(fetchAsyncGetActivityReportDetail(reportId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reportId, userInfo.partitionKey]);

  useEffect(() => {
    effectFunc();
  }, [effectFunc]);

  const jobId = activityDetail.contributorJobId;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Layout pageName={pageName} breadcrumbs={breadcrumbs}>
          <CustomContainer sx={containerSx}>
            <SideAuthorArea sx={sideAuthorAreaSx}>
              <Avatar
                variant="rounded"
                src={activityDetail.contributorImg}
                sx={sideAvatarElementSx}
              />
              <Stack direction="column">
                <Typography sx={sideAvatarName}>
                  {activityDetail.contributorName}
                </Typography>
                <Typography sx={sideAvatarJobId}>
                  {jobId >= 0 && jobId < JOBS.length ? JOBS[jobId] : UNREGISTERED}
                </Typography>
              </Stack>
            </SideAuthorArea>
            <Card sx={cardSx}>
              {userInfo.isAdmin && (
                <Button
                  variant="admin"
                  className="mb-2"
                  onClick={() => {
                    navigate(`${PAGES.ACTIVITY_REPORT_UPDATE.PATH}?reportId=${reportId}`);
                  }}
                  disabled={!reportId}
                >
                  Update
                </Button>
              )}
              <CardMedia
                component="img"
                image={activityDetail.eyeCatchImg}
                alt="ACTIVITY_ONE"
                sx={cardMediaSx}
              />
              <Stack direction="row" sx={infoAreaSx}>
                <AuthorArea sx={authorAreaSx}>
                  <Avatar
                    src={activityDetail.contributorImg}
                    sx={avatarElementSx}
                  />
                  <Typography variant="h6" sx={typographySx}>
                    {activityDetail.contributorName}
                  </Typography>
                </AuthorArea>
                <DayViewArea sx={dayViewAreaSx}>
                  <Icon icon="ic:round-access-time" />
                  <Typography sx={dayViewElementSx}>
                    {activityDetail.createdAt.substring(
                      0,
                      activityDetail.createdAt.indexOf(" ")
                    )}
                  </Typography>
                  <Icon icon="carbon:view" />
                  <Typography sx={dayViewElementSx}>
                    {activityDetail.viewCount}
                  </Typography>
                </DayViewArea>
              </Stack>
              <TitleArea sx={titleAreaSx}>{activityDetail.title}</TitleArea>
              <ArticleArea sx={articleAreaSx}>
                <Article
                  dangerouslySetInnerHTML={{ __html: activityDetail.htmlText }}
                />
              </ArticleArea>
            </Card>
          </CustomContainer>
        </Layout>
      )}
    </>
  );
};

export default ActivityReportDetail;
