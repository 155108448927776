import { FC, memo } from "react";
import styles from "styles/components/Home/HomeTop.module.css";
import ImageSlider from "./ImageSlider";

type Props = {};

/**
 * ホームトップ
 * @param param0
 * @returns
 */
const HomeTop: FC<Props> = () => {
  return (
    <div className={styles.home_top}>
      <div className={styles.slider_container}>
        <ImageSlider />
      </div>
    </div>
  );
};

export default memo(HomeTop);
