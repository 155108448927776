import { FC, ChangeEvent, useRef, MouseEvent, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";

import { Skill } from "common/types";
import {
  ERR_MSG,
  PROFILE_INPUT_KEY,
  PROFILE_ITEM_MAX_LENGTH,
  PROFILE_ITEM_MIN_LENGTH,
  UNREGISTERED,
} from "common/constants";
import SkillTag from "components/Common/SkillTag";
import EditSkiilsModal from "components/ProfileEdit/EditSkillsModal";
import { setUploadSkillSheet } from "ducks/profile/slice";
import { useProfileEdit } from "hooks/profileEditHooks";
import styles from "styles/components/ProfileEdit/EditBoxes.module.css";
import { Button } from "components/Shadcn/Button";
import { cn } from "lib/utils";
import { Textarea } from "components/Shadcn/Textarea";
import { Label } from "components/Shadcn/Label";

/**
 * 趣味、スキル、フリースペースの編集
 * @returns
 */
const EditBoxes: FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  // const uploadSkillSheet: File | null = useSelector(selectUploadSkillSheet);

  const { dispatch, isAdmin, profile, handleChange } = useProfileEdit();

  /** スキル編集モーダル */
  const [isSkillsModal, setIsSkillsModal] = useState<boolean>(false);
  const openSkillsModal = () => {
    setIsSkillsModal(true);
  };
  const closeSkillsModal = () => {
    setIsSkillsModal(false);
  };

  /** スキル編集ボタンクリック */
  const clickSkillEdit = () => {
    openSkillsModal();
  };

  /** スキルシート登録ボタンクリック */
  // const clickSkillSheet = () => {
  //   inputRef.current?.click();
  // };

  /** スキルシート登録解除ボタンクリック */
  // const clickUnsetSkillSheet = () => {
  //   dispatch(setUploadSkillSheet({ file: null }));
  // };

  /** スキルシート変更 */
  const changeSkillSheet = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file: File = e.target.files[0];
      dispatch(setUploadSkillSheet({ file }));
    }
  };

  return (
    <div className="mt-8">
      <div>
        <Label className="mb-1" htmlFor={PROFILE_INPUT_KEY.SKILLS}>
          スキル
        </Label>
        <div
          className={`pt-2 px-2 bg-white border border-input rounded-md relative`}
        >
          {profile.skills.length > 0 ? (
            <div id={PROFILE_INPUT_KEY.SKILLS} className={styles.tags}>
              {profile.skills.map((skill: Skill, index: number) => {
                return (
                  <SkillTag key={index} skill={skill}>
                    {skill.skillName}
                  </SkillTag>
                );
              })}
            </div>
          ) : (
            <p id={PROFILE_INPUT_KEY.SKILLS}>{UNREGISTERED}</p>
          )}
          <div className={cn(styles.skill_btns, "h-[40px]")}>
            <div className="absolute bottom-2.5 right-2.5">
              <Button
                variant="secondary"
                type="button"
                onClick={clickSkillEdit}
              >
                スキルを編集
                <span className="pl-1">
                  <AiOutlineEdit />
                </span>
              </Button>

              {/* {uploadSkillSheet !== null ? (
                <>
                  <button type="button" onClick={clickUnsetSkillSheet}>
                    スキルシート登録解除
                    <span>
                      <BsTrash />
                    </span>
                  </button>

                  <div className={styles.skill_sheet_nm}>
                    <p>{uploadSkillSheet.name}</p>
                  </div>
                </>
              ) : (
                <button type="button" onClick={clickSkillSheet}>
                  スキルシート登録
                  <span>
                    <BsCloudUpload />
                  </span>
                </button>
              )} */}

              <input
                style={{ display: "none" }}
                type="file"
                ref={inputRef}
                onChange={changeSkillSheet}
                // 同じファイルを再度指定された時用
                onClick={(e: MouseEvent<HTMLInputElement>) => {
                  (e.target as HTMLInputElement).value = "";
                }}
              />
            </div>
          </div>
        </div>
        {!isAdmin &&
          profile.jobId !== 1 &&
          profile.skills.length < PROFILE_ITEM_MIN_LENGTH.SKILLS && (
            <small>
              ※{PROFILE_ITEM_MIN_LENGTH.SKILLS}
              個以上のスキルを登録してください。
            </small>
          )}
      </div>

      <div className="my-4">
        <div className="">
          <Label className="mb-1" htmlFor={PROFILE_INPUT_KEY.HOBBIES}>
            趣味
          </Label>
          <Textarea
            className="h-32"
            id={PROFILE_INPUT_KEY.HOBBIES}
            defaultValue={profile.hobby}
            maxLength={PROFILE_ITEM_MAX_LENGTH.HOBBIES}
            onChange={handleChange}
          />
        </div>
        {!isAdmin && !profile.hobby && <small>{ERR_MSG.EMPTY}</small>}
      </div>

      <div className="mb-4">
        <div className="">
          <Label className="mb-1" htmlFor={PROFILE_INPUT_KEY.FREE_SPACE}>
            フリースペース
          </Label>
          <Textarea
            className="h-60"
            id={PROFILE_INPUT_KEY.FREE_SPACE}
            defaultValue={profile.freeSpace}
            maxLength={PROFILE_ITEM_MAX_LENGTH.FREE_SPACE}
            onChange={handleChange}
          />
        </div>
        {!isAdmin &&
          profile.freeSpace.length < PROFILE_ITEM_MIN_LENGTH.FREE_SPACE && (
            <small>
              ※{PROFILE_ITEM_MIN_LENGTH.FREE_SPACE}文字以上を入力してください。
            </small>
          )}
      </div>

      <EditSkiilsModal isOpen={isSkillsModal} closeModal={closeSkillsModal} />
    </div>
  );
};

export default EditBoxes;
