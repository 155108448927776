import { SxProps, Theme } from "@mui/material/styles";
import { Container } from "@mui/material";
import emotionStyled from "@emotion/styled";

// Container
export const CustomContainer = emotionStyled(Container)`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: left;
align-items: start;
position: relative;
`;

export const containerSx: SxProps<Theme> = {
  width: { xs: 360, sm: 500, md: 800, lg: 1100 },
};
