import { FC, ReactNode } from "react";
import { SKILL_TYPES } from "common/constants";
import { Skill } from "common/types";
import { CgClose } from "react-icons/cg";
import { BsPlusCircle } from "react-icons/bs";
import { cva } from "class-variance-authority";

type SkillTypeName = (typeof SKILL_TYPES)[number]["name"];

const spanVariants = cva(
  "inline-flex items-center gap-x-0.5 rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset mr-1 my-1",
  {
    variants: {
      color: {
        backend: "bg-blue-50 text-blue-700 ring-blue-600/20",
        frontend: "bg-indigo-50 text-indigo-700 ring-indigo-700/10",
        infrastructure: "bg-violet-50 text-violet-700 ring-violet-700/10",
        ai: "bg-purple-50 text-purple-700 ring-purple-700/10",
        qualification: "bg-sky-50 text-sky-700 ring-sky-700/10",
        other: "bg-gray-100 text-gray-600 ring-gray-500/10",
      },
    },
  }
);

const buttonVariants = cva("group relative -mr-1 h-3.5 w-3.5 rounded-sm", {
  variants: {
    color: {
      backend: "hover:bg-blue-600/20",
      frontend: "hover:bg-indigo-600/20",
      infrastructure: "hover:bg-violet-600/20",
      ai: "hover:bg-purple-600/20",
      qualification: "hover:bg-sky-600/20",
      other: "hover:bg-gray-500/20",
    },
  },
});

const svgVariants = cva("h-3.5 w-3.5", {
  variants: {
    color: {
      backend: "text-blue-700",
      frontend: "text-indigo-700",
      infrastructure: "text-violet-700",
      ai: "text-purple-700",
      qualification: "text-sky-700",
      other: "text-gray-600",
    },
  },
});

const getSkillStyles = (code: number) => {
  const skillTypeName: SkillTypeName =
    SKILL_TYPES.find((skillType) => skillType.code === code)?.name || "other";
  const span = spanVariants({ color: skillTypeName });
  const button = buttonVariants({ color: skillTypeName });
  const svg = svgVariants({ color: skillTypeName });

  return { span, button, svg };
};

type Props = {
  skill: Skill;
  children: ReactNode;
  clickFunc?: Function;
  edit?: string;
};

/**
 * スキルタグ
 * @param Props
 * @returns
 */
const SkillTag: FC<Props> = ({ skill, children, clickFunc, edit }) => {
  const { span, button, svg } = getSkillStyles(skill.skillType);

  if (clickFunc) {
    const editMode =
      edit === "remove" ? (
        <CgClose className={svg} />
      ) : (
        <BsPlusCircle className={svg} />
      );
    return (
      <span className={span}>
        #{children}
        <button
          type="button"
          className={button}
          onClick={() => clickFunc(skill)}
        >
          {editMode}
        </button>
      </span>
    );
  } else {
    return <span className={span}>#{children}</span>;
  }
};

export default SkillTag;
