import { FC, memo, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import {
  NEW_SLIDE,
} from "common/constants";
import "@splidejs/splide/css";
import styles from "styles/components/Home/ImageSlider.module.css";

type Props = {};

const imageSources = [
  NEW_SLIDE.HOME_SLIDE_ONE,
  NEW_SLIDE.HOME_SLIDE_TWO,
  NEW_SLIDE.HOME_SLIDE_THREE,
  NEW_SLIDE.HOME_SLIDE_FOUR,
  NEW_SLIDE.HOME_SLIDE_FIVE,
  NEW_SLIDE.HOME_SLIDE_SIX,
  NEW_SLIDE.HOME_SLIDE_SEVEN,
  NEW_SLIDE.HOME_SLIDE_EIGHT,
];

/**
 * 画像スライダー
 * @param param0
 * @returns
 */
const ImageSlider: FC<Props> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedImage, setClickedImage] = useState("");

  const onClick = (slide: any) => {
    if (slide.index >= imageSources.length) {
      return;
    }
    setClickedImage(imageSources[slide.index]);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setClickedImage("");
    setIsModalOpen(false);
  };

  return (
    <>
      <Splide
        aria-label="slide"
        options={{
          autoplay: true, // 自動再生を有効
          interval: 6000, // 自動再生の間隔(ms)
          speed: 2000, // 移動速度(ms)
          rewind: true, // 末尾まで移動した際に先頭に戻る
          rewindSpeed: 2000,
          width: "100%",
          height: "100%",
          arrows: true,
          pagination: true,
          pauseOnFocus: true,
          pauseOnHover: false,
        }}
        onClick={onClick}
      >
        {imageSources.map((src, index) => (
          <SplideSlide key={index}>
            <img src={src} alt={`slide${index + 1}`} />
          </SplideSlide>
        ))}
      </Splide>

      {isModalOpen && (
        <div onClick={handleClose} className={styles.image_modal_overlay}>
          <div className={styles.image_modal_content}>
            <img src={clickedImage} alt="slide" />
          </div>
        </div>
      )}
    </>
  );
};

export default memo(ImageSlider);
