import {
  createSlice,
  createAsyncThunk,
  ActionReducerMapBuilder,
  PayloadAction,
} from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { API_NAME, API_PATHS } from "common/constants";
import { RootState } from "app/store";
import { initialState } from "ducks/faq/initialState";
import {
  FaqStateType,
  FaqType,
  PutFaqDetailInput,
  PostFaqDetailInput,
  PutFaqTitleInput,
  PostFaqTitleInput,
} from "ducks/faq/type";

/**
 * FAQ一覧取得
 * @param args none
 **/
export const fetchAsyncGetFaqs = createAsyncThunk(
  "faq/getFaqs",
  async (args, thunkAPI) => {
    try {
      return await API.get(API_NAME, API_PATHS.FAQS, {});
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * FAQ詳細追加
 * @param input
 **/
export const fetchAsyncPostFaqDetail = createAsyncThunk(
  "faq/postFaqDetail",
  async (input: PostFaqDetailInput, thunkAPI) => {
    const params = {
      body: {
        partitionKey: input.detailPartitionKey,
        sortKey: input.titlePartitionKey,
        question: input.question,
        answer: input.answer,
        createdAt: input.createdAt,
      },
    };
    try {
      return await API.post(API_NAME, API_PATHS.FAQ_DETAIL, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * FAQ詳細更新
 * @param input
 **/
export const fetchAsyncPutFaqDetail = createAsyncThunk(
  "faq/putFaqDetail",
  async (input: PutFaqDetailInput, thunkAPI) => {
    const params = {
      body: {
        partitionKey: input.detailPartitionKey,
        sortKey: input.titlePartitionKey,
        question: input.question,
        answer: input.answer,
        updatedAt: input.updatedAt,
      },
    };
    try {
      return await API.put(API_NAME, API_PATHS.FAQ_DETAIL, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * FAQタイトル（目次）追加
 * @param input
 **/
export const fetchAsyncPostFaqTitle = createAsyncThunk(
  "faq/postFaqTitle",
  async (input: PostFaqTitleInput, thunkAPI) => {
    const params = {
      body: {
        partitionKey: input.titlePartitionKey,
        sortKey: input.titleSortKey,
        title: input.title,
        sortOrder: input.sortOrder,
        createdAt: input.createdAt,
      },
    };
    try {
      return await API.post(API_NAME, API_PATHS.FAQ_TITLE, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * FAQタイトル（目次）更新
 * @param input
 **/
export const fetchAsyncPutFaqTitle = createAsyncThunk(
  "faq/putFaqTitle",
  async (input: PutFaqTitleInput, thunkAPI) => {
    const params = {
      body: {
        partitionKey: input.titlePartitionKey,
        sortKey: input.titleSortKey,
        title: input.title,
        sortOrder: input.sortOrder,
        updatedAt: input.updatedAt,
      },
    };
    try {
      return await API.put(API_NAME, API_PATHS.FAQ_TITLE, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    unsetIsPostFinished(state: FaqStateType) {
      return {
        ...state,
        isUpdateFinished: false,
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<FaqStateType>) => {
    builder
      .addCase(
        fetchAsyncGetFaqs.fulfilled,
        (state: FaqStateType, action: PayloadAction<any>) => {
          const faqList: FaqType[] = action.payload.faqs;
          const sortedFaqList = faqList.sort(
            (a, b) => a.sortOrder - b.sortOrder
          );
          return {
            ...state,
            isDetailLoading: false,
            faqList: sortedFaqList,
          };
        }
      )
      .addCase(
        fetchAsyncGetFaqs.rejected,
        (state: FaqStateType, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncGetFaqs.pending,
        (state: FaqStateType, action: PayloadAction<any>) => {
          return {
            ...state,
            isLoading: true,
          };
        }
      )
      .addCase(
        fetchAsyncPostFaqDetail.fulfilled,
        (state: FaqStateType, action: PayloadAction<any>) => {
          return {
            ...state,
            isUpdateLoading: false,
            isUpdateFinished: true,
          };
        }
      )
      .addCase(
        fetchAsyncPostFaqDetail.pending,
        (state: FaqStateType, action: PayloadAction<any>) => {
          return {
            ...state,
            isUpdateLoading: true,
            isUpdateFinished: false,
          };
        }
      )
      .addCase(
        fetchAsyncPostFaqDetail.rejected,
        (state: FaqStateType, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncPutFaqDetail.fulfilled,
        (state: FaqStateType, action: PayloadAction<any>) => {
          return {
            ...state,
            isUpdateLoading: false,
            isUpdateFinished: true,
          };
        }
      )
      .addCase(
        fetchAsyncPutFaqDetail.pending,
        (state: FaqStateType, action: PayloadAction<any>) => {
          return {
            ...state,
            isUpdateLoading: true,
            isUpdateFinished: false,
          };
        }
      )
      .addCase(
        fetchAsyncPutFaqDetail.rejected,
        (state: FaqStateType, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncPostFaqTitle.fulfilled,
        (state: FaqStateType, action: PayloadAction<any>) => {
          return {
            ...state,
            isUpdateLoading: false,
            isUpdateFinished: true,
          };
        }
      )
      .addCase(
        fetchAsyncPostFaqTitle.pending,
        (state: FaqStateType, action: PayloadAction<any>) => {
          return {
            ...state,
            isUpdateLoading: true,
            isUpdateFinished: false,
          };
        }
      )
      .addCase(
        fetchAsyncPostFaqTitle.rejected,
        (state: FaqStateType, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncPutFaqTitle.fulfilled,
        (state: FaqStateType, action: PayloadAction<any>) => {
          return {
            ...state,
            isUpdateLoading: false,
            isUpdateFinished: true,
          };
        }
      )
      .addCase(
        fetchAsyncPutFaqTitle.pending,
        (state: FaqStateType, action: PayloadAction<any>) => {
          return {
            ...state,
            isUpdateLoading: true,
            isUpdateFinished: false,
          };
        }
      )
      .addCase(
        fetchAsyncPutFaqTitle.rejected,
        (state: FaqStateType, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      );
  },
});

export const { unsetIsPostFinished } = faqSlice.actions;

export const selectIsLoading = (state: RootState) => state.faq.isLoading;

export const selectIsUpdateLoading = (state: RootState) =>
  state.faq.isUpdateLoading;
export const selectIsUpdateFinished = (state: RootState) =>
  state.faq.isUpdateFinished;

export const selectFaqList = (state: RootState) => state.faq.faqList;

export const selectIsDetailLoading = (state: RootState) =>
  state.faq.isDetailLoading;

export const selectIsAdmin = (state: RootState) => state.auth.userInfo.isAdmin;
export default faqSlice.reducer;
