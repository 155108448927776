import { FC } from "react";
import styles from "styles/components/Common/NoticeCard.module.css";

type Props = {
  children: string;
  isFadedIn?: boolean;
};

const NoticeCard: FC<Props> = ({ children, isFadedIn = false }) => {
  return (
    <div
      className={
        isFadedIn
          ? `${styles.notice_card} ${styles.fade_in}`
          : styles.notice_card
      }
    >
      <div className={styles.border}></div>
      <div className={styles.contents}>
        <p>{children}</p>
      </div>
    </div>
  );
};

export default NoticeCard;
