import parse from "html-react-parser";
import { useActivityAdd } from "hooks/activityAddHooks";
import { FC } from "react";
import { PreviewDiv } from "styles/containers/activityAddTheme";

const ActivityAddPreviewArea: FC = () => {
  /* customHooks */
  const { htmlText } = useActivityAdd();

  return <PreviewDiv>{parse(htmlText)}</PreviewDiv>;
};

export default ActivityAddPreviewArea;
