import { FC, useEffect, useState, ChangeEvent, KeyboardEvent } from "react";

import { Skill } from "common/types";
import { lockModalBack, unlockModalBack } from "common/common";
import { PROFILE_ITEM_MAX_LENGTH } from "common/constants";
import SkillTag from "components/Common/SkillTag";
import PortalBtn from "components/Common/PortalBtn";
import { useProfileEdit } from "hooks/profileEditHooks";
import { fetchAsyncGetSkills, setSkill, unSetSkill } from "ducks/profile/slice";
import styles from "styles/components/ProfileEdit/EditSkillsModal.module.css";
import { Label } from "components/Shadcn/Label";
import { Input } from "components/Shadcn/Input";

type Props = {
  isOpen: boolean;
  closeModal: VoidFunction;
};

/**
 * スキル編集モーダル
 * @param param0
 * @returns
 */
const EditSkiilsModal: FC<Props> = ({ isOpen, closeModal }) => {
  const { dispatch, currentSkills, searchedSkills } = useProfileEdit();

  const [scrollPos, setScrollPos] = useState<number>(0);
  const [searchedInputVal, setSearchedInputVal] = useState<string>("");
  const [isOverSkills, setIsOverSkills] = useState<boolean>(false);

  /** 検索入力 */
  const changeSearchedInputVal = (e: ChangeEvent<HTMLInputElement>) => {
    const inputVal: string = e.target.value.toLowerCase();
    setSearchedInputVal(inputVal);
    dispatch(fetchAsyncGetSkills(inputVal));
  };

  /** 現在のスキルタグをクリック */
  const clickCurrentSkill = (skill: Skill) => {
    currentSkills.length <= PROFILE_ITEM_MAX_LENGTH.SKILLS && setIsOverSkills(false);
    dispatch(unSetSkill(skill));
    dispatch(fetchAsyncGetSkills(searchedInputVal));
  };

  /** 検索結果のスキルタグをクリック */
  const clickSearchedSkill = (skill: Skill) => {
    if (currentSkills.length === PROFILE_ITEM_MAX_LENGTH.SKILLS) {
      setIsOverSkills(true);
    } else {
      dispatch(setSkill(skill));
      dispatch(fetchAsyncGetSkills(searchedInputVal));
    }
  };

  useEffect(() => {
    dispatch(fetchAsyncGetSkills(""));
  }, [dispatch]);

  /** モーダルオープン時のスクロール位置取得 */
  useEffect(() => {
    isOpen && setScrollPos(window.pageYOffset);
  }, [isOpen]);

  /** モーダルの背景固定 */
  useEffect(() => {
    if (isOpen) {
      lockModalBack(scrollPos);
    } else {
      unlockModalBack(scrollPos);
    }
  }, [isOpen, scrollPos]);

  return (
    <>
      {isOpen && (
        <div className={styles.overlay} onClick={closeModal}>
          <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
            <div className={styles.modal_contents}>
              <div className={styles.box_item}>
                <Label className="mb-1" htmlFor="current_skills">
                  スキル
                </Label>
                <div
                  className={`py-2 px-2 bg-white border border-input rounded-md`}
                >
                  <div id="current_skills">
                    {currentSkills.map((skill: Skill, index: number) => {
                      return (
                        <SkillTag
                          key={index}
                          skill={skill}
                          clickFunc={clickCurrentSkill}
                          edit="remove"
                        >
                          {skill.skillName}
                        </SkillTag>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className={styles.box_item}>
                <div className={styles.search_box}>
                  <Label className="mb-1" htmlFor="search_input">
                    検索
                  </Label>
                  <Input
                    id="search_input"
                    type="text"
                    placeholder=""
                    maxLength={50}
                    defaultValue={searchedInputVal}
                    onChange={changeSearchedInputVal}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </div>
              </div>

              <div className={styles.box_item}>
                {isOverSkills && (
                  <small>
                    ※登録できるスキルは{PROFILE_ITEM_MAX_LENGTH.SKILLS}
                    個までです。
                  </small>
                )}
                <Label className="mb-1" htmlFor="searched_skills">
                  検索結果
                </Label>
                <div
                  className={`py-2 px-2 bg-white border border-input rounded-md`}
                >
                  <div id="searched_skills">
                    {searchedSkills
                      .filter((skill: Skill) => {
                        return !currentSkills.some(
                          (currentSkill: Skill) => currentSkill.skillName === skill.skillName
                        );
                      })
                      .map((skill: Skill, index: number) => {
                        return (
                          <SkillTag
                            key={index}
                            skill={skill}
                            clickFunc={clickSearchedSkill}
                            edit="add"
                          >
                            {skill.skillName}
                          </SkillTag>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className={styles.finish_btn}>
                <PortalBtn clickFunc={closeModal}>完了</PortalBtn>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditSkiilsModal;
