import { FC, memo, useState } from "react";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { JOBS, STATEIDS, UNREGISTERED } from "common/constants";
import { calculateAge, copyText } from "common/common";
import styles from "styles/components/Profiles/ProfileDetailModal/ProfileBasicInfo.module.css";

type Props = {
  staffId: string;
  jobId: number;
  birthday: string;
  hireDate: string;
  email: string;
  stateId: number;
  city: string;
};

/**
 * 社員基本情報
 * @param Props
 * @returns
 */
const ProfileBasicInfo: FC<Props> = ({
  staffId,
  jobId,
  birthday,
  hireDate,
  email,
  stateId,
  city,
}) => {
  const [isShownCopied, setIsShownCopied] = useState<boolean>(false);
  const clickCopyBoard = (email: string) => {
    if (!isShownCopied) {
      setIsShownCopied(true);
      copyText(email);
      setTimeout(() => {
        setIsShownCopied(false);
      }, 3000);
    }
  };

  return (
    <div className={styles.basic_info}>
      <ul className={styles.basic_items}>
        <li>
          <div className={styles.basic_item}>
            <p>社員ID</p>
            <p>{staffId ? staffId : UNREGISTERED}</p>
          </div>
        </li>
        <li>
          <div className={styles.basic_item}>
            <p>職務</p>
            <p>
              {jobId >= 0 && jobId < JOBS.length ? JOBS[jobId] : UNREGISTERED}
            </p>
          </div>
        </li>
        <li>
          <div className={styles.basic_item}>
            <p>年齢</p>
            <p>{birthday ? calculateAge(birthday) : ""}</p>
          </div>
        </li>
        <li>
          <div className={styles.basic_item}>
            <p>誕生日</p>
            <p>{birthday ? birthday : UNREGISTERED}</p>
          </div>
        </li>
        <li>
          <div className={styles.basic_item}>
            <p>入社日</p>
            <p>{hireDate ? hireDate : UNREGISTERED}</p>
          </div>
        </li>
      </ul>
      <div className={styles.email}>
        <p>メールアドレス</p>
        <div className={styles.email_content}>
          {email ? (
            <>
              <div className={styles.email_txt}>
                <p>
                  {email.substring(0, email.indexOf("@"))}
                  <span>{email.substring(email.indexOf("@"))}</span>
                </p>
              </div>
              <div className={styles.email_copy_board}>
                <p
                  className={
                    isShownCopied ? styles.txt_shown : styles.txt_hidden
                  }
                >
                  Copied!
                </p>
                <span onClick={() => clickCopyBoard(email)}>
                  <HiOutlineClipboardCopy />
                </span>
              </div>
            </>
          ) : (
            <div className={styles.email_txt}>
              <p>{UNREGISTERED}</p>
            </div>
          )}
        </div>
      </div>
      <ul className={styles.basic_items}>
        <li>
          <div className={styles.basic_item}>
            <p>都道府県</p>
            <p>
              {stateId >= 0 && stateId < STATEIDS.length
                ? STATEIDS[stateId]
                : UNREGISTERED}
            </p>
          </div>
        </li>
        <li>
          <div className={styles.basic_item}>
            <p>市区町村</p>
            <p>{city ? city : UNREGISTERED}</p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default memo(ProfileBasicInfo);
