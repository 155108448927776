import { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import { Command } from "./Shadcn/Command";
import { useSelector } from "react-redux";
import { selectConfirmSearchForm } from "ducks/employee/slice";
import { useSearchArea } from "hooks/profiles/searchAreaHooks";
import { fetchAsyncGetSkills } from "ducks/profile/slice";
import SearchItems from "./SearchItems";
import { Input } from "./Shadcn/Input";
import { useDebounce } from 'use-debounce';
import { useProfile } from "hooks/profileHooks";
import { ConfirmSearchForm } from "ducks/employee/type";
import { useSearchParams } from "react-router-dom";

/**
 * 検索入力ボックス
 * @returns
 */
const SearchInput: FC = () => {

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);

  // query parmeter
  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get("category")
  const word = searchParams.get("word")

  // useHooks
  const {
    dispatch,
    clickSearch,
    searchItemOptions,
  } = useSearchArea();
  const {
    employees,
  } = useProfile();

  // スキル取得
  useEffect(() => {
    dispatch(fetchAsyncGetSkills(""));
  }, [dispatch]);

  // 特定の状態でのキー操作制御
  const inputRef = useRef<HTMLInputElement>(null!); 
  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    const input = inputRef.current;
    if (!input) return;
    if (e.key === "Escape") {
      input.blur();
      return
    }
    // HACK: 下記の時にエラーが発生するため、押せないように制御する
    if (!open || !options.length) {
      if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
        // カーソルキー(上下)の入力を無効化
        e.preventDefault();
        return
      }
    }
    if (e.key === "Enter") {
      clickSearch();
    }
    // eslint-disable-next-line 
  }, [inputRef, open, options]);
  
 
  // 間引き検索実行
	const [ debouncedInput ] = useDebounce(word, 300);
  // eslint-disable-next-line
  useEffect(clickSearch, [debouncedInput]);

  
  // 入力制御
  const form = useSelector(selectConfirmSearchForm);
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((!employees.length || isConfirmSearchFormEmpty(form)) && !open) {
      setOpen(true)
    }
    searchParams.set("category", category ?? "1");
    searchParams.set("word", e.target.value);
    setSearchParams(searchParams);
  };
  const isConfirmSearchFormEmpty = (form: ConfirmSearchForm) => {
    return form.fullName === "" &&
           form.selectedSkill === null &&
           form.stateId === null &&
           form.hobbyFreeSpace === "";
  };

  // option設定
  useEffect(() => {
    setOptions(searchItemOptions);
    // eslint-disable-next-line
  }, [searchParams]);

  return (
    <Command 
      shouldFilter={true} 
      onKeyDown={handleKeyDown}
      onBlur={() => setOpen(false)}
      className="h-10 py-0 border border-l-0 border-input ring-0 rounded-l-none overflow-visible outline-none focus-visible:outline-none">
      <Input
        value={word ?? ""}
        className="h-10 px-3 rounded-l-none focus-visible:ring-0 ring-0 border-none shadow-none focus-visible:ring-offset-0"
        onFocus={() => setOpen(true)}
        onChange={handleOnChange}      
        ref={inputRef}
      />
      {open && options.length !== 0 && (
      <SearchItems 
        options={options}
        setOpen={setOpen}        
      ></SearchItems>
      )}
    </Command>
  );
}

export default memo(SearchInput);