import { SxProps, Theme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import emotionStyled from "@emotion/styled";

export const SearchErrorMessage = emotionStyled(Typography)`
color: red;
`;

export const searchErrorMessageSx: SxProps<Theme> = {
  fontSize: { xs: 12, sm: 16, md: 20, lg: 24 },
};
