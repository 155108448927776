import parse from "html-react-parser";
import { FC } from "react";
import { PreviewDiv } from "styles/containers/activityAddTheme";
import { useActivityUpdate } from "hooks/activityUpdateHooks";

const ActivityUpdatePreviewArea: FC = () => {
  /* customHooks */
  const { htmlText } = useActivityUpdate();

  return <PreviewDiv>{parse(htmlText)}</PreviewDiv>;
};

export default ActivityUpdatePreviewArea;
