import { WorkRegulationState } from "./type";
import { S3_PREFIXES } from "common/constants";

export const initialState: WorkRegulationState = {
  isLoading: true,
  workRegulationsInfo: {
    folder: S3_PREFIXES.WORK_REGULATION,
    files: [],
    addFile: null,
  },
  childcareLeavesInfo: {
    folder: S3_PREFIXES.CHILDCARE_LEAVE,
    files: [],
    addFile: null,
  },
  hygieneManagementsInfo: {
    folder: S3_PREFIXES.HYGIENE_MANAGEMENT,
    files: [],
    addFile: null,
  },
  definedContributionPensionInfo: {
    folder: S3_PREFIXES.DEFINED_CONTRIBUTION_PENSION,
    files: [],
    addFile: null,
  },
  pMarkAndISMSInfo: {
    folder: S3_PREFIXES.P_MARK_AND_ISMS,
    files: [],
    addFile: null,
  },
};
