import { FC } from "react";
import { Avatar } from "@mui/material";
import {
  SideBox,
  sideBoxSx,
  SideCard,
  sideCardSx,
  SideAvatarArea,
  sideAvatarAreaSx,
  SideLink,
  sideLinkSx,
  sideAvatarElementSx,
} from "styles/components/Activities/activitySideTheme";
import { ActivityReport } from "ducks/activity/type";
import { PAGES } from "common/PAGES";
import { useActivities } from "hooks/activitiesHooks";
import { useNavigate } from "react-router-dom";
import { Button } from "components/Shadcn/Button";
import { BsPlusCircle } from "react-icons/bs";

export const ActivitySide: FC = () => {
  const navigate = useNavigate();
  let viewCnt = 0;
  const { activitiesSide, userInfo, onClickDetail } = useActivities();

  return (
    <SideBox sx={sideBoxSx}>
      {userInfo.isAdmin && (
        <Button className="mb-2" onClick={() => navigate(PAGES.ACTIVITY_REPORT_ADD.PATH)}>
          <BsPlusCircle className="mr-1" />
          Add Post
        </Button>
      )}
      {activitiesSide.map((activity: ActivityReport, key: number) => {
        if (activity.status === "1" && userInfo.isAdmin === false) {
          return <></>;
        } else {
          if (viewCnt < 4) {
            viewCnt = viewCnt + 1;
            return (
              <SideCard sx={sideCardSx} key={key}>
                <SideLink
                  onClick={() => {
                    onClickDetail(activity.partitionKey);
                  }}
                  sx={sideLinkSx}
                >
                  {activity.status === "1"
                    ? `【下書き記事】${activity.title}`
                    : activity.title}
                </SideLink>
                <SideAvatarArea sx={sideAvatarAreaSx}>
                  <Avatar
                    src={activity.contributorImg}
                    sx={sideAvatarElementSx}
                  />
                </SideAvatarArea>
              </SideCard>
            );
          } else {
            return <></>;
          }
        }
      })}
    </SideBox>
  );
};

export default ActivitySide;
