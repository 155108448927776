import { FC } from "react";
import styles from "styles/components/Profiles/Badge.module.css";

export const BadgeStyle = {
  None: 0,
  New: 1,
  Update: 2,
} as const;

type Props = {
  badgeStyle: number;
};

const getBadgeText = (badgeStyle: number): string => {
  switch (badgeStyle) {
    case BadgeStyle.New:
      return "NEW";
    default:
      return "";
  }
};

const getBadgeClass = (badgeStyle: number): string => {
  switch (badgeStyle) {
    case BadgeStyle.New:
      return styles.badge_new;
    default:
      return "";
  }
};

/**
 * 新規登録情報バッジ
 * @param Props
 * @returns
 */
const Badge: FC<Props> = ({ badgeStyle }) => {
  return (
    <>
      {badgeStyle !== BadgeStyle.None ? (
        <div className={styles.profile_badge_wrapper}>
          <span
            className={`${styles.profile_badge} ${getBadgeClass(badgeStyle)}`}
          >
            {getBadgeText(badgeStyle)}
          </span>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Badge;
