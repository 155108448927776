import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "aws-amplify";
import { RootState } from "app/store";
import { initialState } from "ducks/activityReportUpdate/initialState";
import { API_NAME, API_PATHS } from "common/constants";

import { ActivityReportUpdateState, RowType } from "./type";

/**
 * 活動報告下書き情報取得
 * @param reportId 活動記事のID
 **/
export const fetchAsyncGetActivityReportUpdate = createAsyncThunk(
  "activityReportUpdate/getActivityReportUpdate",
  async (reportId: string, thunkAPI) => {
    const getUpdateParams = {
      queryStringParameters: {
        reportId: reportId,
      },
    };
    try {
      return await API.get(
        API_NAME,
        API_PATHS.ACTIVITY_REPORT_UPDATE,
        getUpdateParams
      );
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * 画像のアップロード
 * @param args none
 **/
export const fetchAsyncUploadImg = createAsyncThunk(
  "activityReport/upLoadImg",
  async (args: { uploadFileName: string; file: File }, thunkAPI) => {
    const presignedGetParams = {
      queryStringParameters: {
        uploadFileName: args.uploadFileName,
      },
    };
    try {
      // 署名付きURL取得
      const presignedUrl: any = await API.post(
        API_NAME,
        API_PATHS.ACTIVITY_REPORT_UPLOAD_GET,
        presignedGetParams
      );

      // ファイルアップロード
      const options = {
        headers: {
          "Content-Type": args.file.type,
        },
      };
      await axios.put(presignedUrl.pdfUrl, args.file, options);

      return;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * 投稿を更新
 * @param args none
 **/
export const fetchAsyncPostActivityReport = createAsyncThunk(
  "activityReportUpdate/postActivityReportUpdate",
  async (
    args: {
      reportId: string;
      eyeCatchImg: string;
      title: string;
      texts: RowType[];
      htmlText: string;
    },
    thunkAPI
  ) => {
    // DB更新パラメータ
    const params = {
      body: { ...args },
    };
    try {
      console.log(params);
      return await API.put(API_NAME, API_PATHS.ACTIVITY_REPORT_UPDATE, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const activityReportUpdateSlice = createSlice({
  name: "activityReportUpdate",
  initialState,
  reducers: {
    setEyeCatchImg(
      state: ActivityReportUpdateState,
      action: PayloadAction<string>
    ) {
      return {
        ...state,
        eyeCatchImg: action.payload,
      };
    },
    setTitle(state: ActivityReportUpdateState, action: PayloadAction<string>) {
      return {
        ...state,
        title: action.payload,
      };
    },
    setTexts(
      state: ActivityReportUpdateState,
      action: PayloadAction<RowType[]>
    ) {
      return {
        ...state,
        texts: action.payload,
      };
    },
    setHtmlText(
      state: ActivityReportUpdateState,
      action: PayloadAction<string>
    ) {
      return {
        ...state,
        htmlText: action.payload,
      };
    },
  },
  extraReducers: (
    builder: ActionReducerMapBuilder<ActivityReportUpdateState>
  ) => {
    builder
      .addCase(
        fetchAsyncGetActivityReportUpdate.fulfilled,
        (state: ActivityReportUpdateState, action: PayloadAction<any>) => {
          console.log(action.payload);
          return {
            ...action.payload,
          };
        }
      )
      .addCase(
        fetchAsyncGetActivityReportUpdate.rejected,
        (state: ActivityReportUpdateState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncGetActivityReportUpdate.pending,
        (state: ActivityReportUpdateState, action: PayloadAction<any>) => {}
      )
      .addCase(
        fetchAsyncUploadImg.fulfilled,
        (state: ActivityReportUpdateState, action: PayloadAction<any>) => {}
      )
      .addCase(
        fetchAsyncUploadImg.rejected,
        (state: ActivityReportUpdateState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncUploadImg.pending,
        (state: ActivityReportUpdateState, action: PayloadAction<any>) => {}
      )
      .addCase(
        fetchAsyncPostActivityReport.fulfilled,
        (state: ActivityReportUpdateState, action: PayloadAction<any>) => {
          console.log(action);
        }
      )
      .addCase(
        fetchAsyncPostActivityReport.rejected,
        (state: ActivityReportUpdateState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncPostActivityReport.pending,
        (state: ActivityReportUpdateState, action: PayloadAction<any>) => {}
      );
  },
});

export const { setEyeCatchImg, setTitle, setTexts, setHtmlText } =
  activityReportUpdateSlice.actions;

export const selectEyeCatchImg = (state: RootState) =>
  state.activityReportUpdate.eyeCatchImg;
export const selectTitle = (state: RootState) =>
  state.activityReportUpdate.title;
export const selectTexts = (state: RootState) =>
  state.activityReportUpdate.texts;
export const selectHtmlText = (state: RootState) =>
  state.activityReportUpdate.htmlText;

export default activityReportUpdateSlice.reducer;
