import { RowType } from "ducks/activityAdd/type";
import { FC } from "react";
import ActivityUpdateEditRow from "./ActivityUpdateEditRow";
import { useActivityUpdate } from "hooks/activityUpdateHooks";

const ActivityUpdateEditArea: FC = () => {
  /* customHooks */
  const { texts } = useActivityUpdate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {texts.map((text: RowType, index: number) => (
          <ActivityUpdateEditRow row={text} index={index} />
        ))}
      </div>
    </div>
  );
};

export default ActivityUpdateEditArea;
