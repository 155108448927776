import { FC } from "react";
import { Icon } from "@iconify/react";
import { Card, CardHeader, CardMedia, Avatar, Typography } from "@mui/material";
import {
  ListBox,
  listBoxSx,
  listCardSx,
  listCardMediaSx,
  listAvatarElementSx,
  ListCardContent,
  listCardContentSx,
  ListTitleArea,
  listTitleAreaSx,
  ListButtonArea,
  listButtonAreaSx,
  ListTitleElement,
  listTitleElementSx,
  ListDayViewArea,
  listDayViewAreaSx,
  listDayViewElementSx,
  NewLabel,
  newLabelSx,
  NewLabelWrapper,
  newLabelWrapperSx,
} from "styles/components/Activities/activityListTheme";
import { ActivityReport } from "ducks/activity/type";
import { useActivities } from "hooks/activitiesHooks";
import { PAGES } from "common/PAGES";
import { Button } from "components/Shadcn/Button";
import { useNavigate } from "react-router-dom";

export const ActivityList: FC = () => {
  const navigate = useNavigate();
  const { userInfo, activitiesList, onClickDetail, judgeNewLabel } =
    useActivities();
  return (
    <ListBox sx={listBoxSx}>
      {activitiesList.map((activity: ActivityReport) => {
        if (activity.status === "1" && userInfo.isAdmin === false) {
          return <></>;
        } else {
          return (
            <Card
              sx={listCardSx}
              key={`activity-list-${activity.partitionKey}`}
            >
              <NewLabelWrapper sx={newLabelWrapperSx}>
                {judgeNewLabel(activity.createdAt) && (
                  <NewLabel sx={newLabelSx}>New</NewLabel>
                )}
                <CardHeader
                  avatar={
                    <Avatar
                      src={activity.contributorImg}
                      sx={listAvatarElementSx}
                    />
                  }
                  title={activity.contributorName}
                />
                <CardMedia
                  component="img"
                  image={activity.eyeCatchImg}
                  alt="サムネ画像"
                  sx={listCardMediaSx}
                />
                <ListCardContent sx={listCardContentSx}>
                  <ListTitleArea sx={listTitleAreaSx}>
                    <ListTitleElement sx={listTitleElementSx}>
                      {activity.status === "1"
                        ? `【下書き記事】${activity.title}`
                        : activity.title}
                    </ListTitleElement>
                    <ListDayViewArea sx={listDayViewAreaSx}>
                      <Icon icon="ic:round-access-time" />
                      <Typography sx={listDayViewElementSx}>
                        {activity.createdAt.substring(
                          0,
                          activity.createdAt.indexOf(" ")
                        )}
                      </Typography>
                      <Icon icon="carbon:view" />
                      <Typography sx={listDayViewElementSx}>
                        {activity.viewCount}
                      </Typography>
                    </ListDayViewArea>
                  </ListTitleArea>
                  <ListButtonArea sx={listButtonAreaSx}>
                    <Button
                      onClick={() => {
                        onClickDetail(activity.partitionKey);
                      }}
                    >
                      View more
                    </Button>
                    {userInfo.isAdmin && (
                      <Button
                        variant="admin"
                        className="ml-4"
                        onClick={() => {
                          navigate(`${PAGES.ACTIVITY_REPORT_UPDATE.PATH}?reportId=${activity.partitionKey}`);
                        }}
                      >
                        Update
                      </Button>
                    )}
                  </ListButtonArea>
                </ListCardContent>
              </NewLabelWrapper>
            </Card>
          );
        }
      })}
    </ListBox>
  );
};

export default ActivityList;
