import { ChangeEvent, FC, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GrFormClose } from "react-icons/gr";

import { AppDispatch } from "app/store";
import { lockModalBack, unlockModalBack } from "common/common";
import { DEFAULT_USER_ICON } from "common/constants";
import PortalBtn from "components/Common/PortalBtn";
import Loader from "components/Loader";
import ProfileName from "components/Profiles/ProfileDetailModal/ProfileName";
import ProfileBasicInfo from "components/Profiles/ProfileDetailModal/ProfileBasicInfo";
import ProfileBoxes from "components/Profiles/ProfileDetailModal/ProfileBoxes";
import { EmployeeDetail } from "ducks/employee/type";
import {
  fetchAsyncGetEmployeeDetail,
  selectEmployeeDetail,
  selectIsDetailLoading,
} from "ducks/employee/slice";
import styles from "styles/components/Profiles/ProfileDetailModal.module.css";

type Props = {
  isOpen: boolean;
  closeModal: VoidFunction;
  id: string;
};

/**
 * 社員詳細モーダル
 * @param Props
 * @returns
 */
const ProfileDetailModal: FC<Props> = ({ isOpen, closeModal, id }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [scrollPos, setScrollPos] = useState<number>(0);

  const isLoading: boolean = useSelector(selectIsDetailLoading);
  const employee: EmployeeDetail = useSelector(selectEmployeeDetail);

  /** モーダルオープン時のスクロール位置取得 */
  useEffect(() => {
    isOpen && setScrollPos(window.pageYOffset);
  }, [isOpen]);

  /** モーダルの背景固定 */
  useEffect(() => {
    if (isOpen) {
      lockModalBack(scrollPos);
      dispatch(fetchAsyncGetEmployeeDetail(id));
    } else {
      unlockModalBack(scrollPos);
    }
  }, [dispatch, isOpen, id, scrollPos]);

  return (
    <>
      {isOpen && (
        <div className={styles.overlay} onClick={closeModal}>
          <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
            <div className={styles.close}>
              <span onClick={closeModal}>
                <GrFormClose size={"2em"} />
              </span>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <div className={styles.modal_contents}>
                <div className={styles.thumbnail}>
                  <img
                    src={employee.picture}
                    alt="thumbnail"
                    onError={(e: ChangeEvent<HTMLImageElement>) =>
                      (e.target.src = DEFAULT_USER_ICON)
                    }
                  />
                </div>

                <div className={styles.profile_name}>
                  <ProfileName
                    firstName={employee.firstName}
                    firstNameKana={employee.firstNameKana}
                    lastName={employee.lastName}
                    lastNameKana={employee.lastNameKana}
                  />
                </div>

                <div className={styles.profile_base_info}>
                  <ProfileBasicInfo
                    staffId={employee.staffId}
                    jobId={employee.jobId}
                    birthday={employee.birthday}
                    hireDate={employee.hireDate}
                    email={employee.email}
                    stateId={employee.stateId}
                    city={employee.city}
                  />
                </div>

                <div className={styles.profile_boxes}>
                  <ProfileBoxes
                    hobby={employee.hobby}
                    skills={employee.skills}
                    freeSpace={employee.freeSpace}
                    skillSheet={employee.skillSheet}
                  />
                </div>

                <div className={styles.close_mdl_btn}>
                  <PortalBtn clickFunc={closeModal}>閉じる</PortalBtn>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default memo(ProfileDetailModal);
