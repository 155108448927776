import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "app/store";
import { checkDateFormat } from "common/common";
import { PROFILE_INPUT_KEY, PROFILE_ITEM_MIN_LENGTH } from "common/constants";
import { Profile } from "ducks/profile/type";
import {
  selectProfile,
  selectSearchedSkills,
  selectSkills,
  setBirthday,
  setEmail,
  setFirstName,
  setFirstNameKana,
  setFreeSpace,
  setHireDate,
  setHobby,
  setJobId,
  setLastName,
  setLastNameKana,
  setStaffId,
  setDeleteFlg,
  setStateId,
  setCity,
} from "ducks/profile/slice";
import { selectIsAdmin, selectUserInfo } from "ducks/auth/slice";
import { UserInfo } from "ducks/auth/type";
import { Skill } from "common/types";

/** 社員詳細編集フック */
export const useProfileEdit = () => {
  const dispatch = useDispatch<AppDispatch>();

  const isAdmin: boolean = useSelector(selectIsAdmin);
  const userInfo: UserInfo = useSelector(selectUserInfo);
  const profile: Profile = useSelector(selectProfile);
  const currentSkills: Skill[] = useSelector(selectSkills);
  const searchedSkills: Skill[] = useSelector(selectSearchedSkills);
  const [isDisabledUpdate, setIsDisabledUpdate] = useState<boolean>(false);

  /** 誕生日フォーマット */
  const [isBirthdayFormatErr, setIsBirthdayFormatErr] =
    useState<boolean>(false);
  /** 入社年月日フォーマット */
  const [isHireDateFormatErr, setIsHireDateFormatErr] =
    useState<boolean>(false);

  /** 入力 */
  const handleChange = useCallback(
    (
      e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
      const value: string = e.target.value;

      switch (e.target.id) {
        case PROFILE_INPUT_KEY.LAST_NAME:
          dispatch(setLastName(value));
          break;

        case PROFILE_INPUT_KEY.FIRST_NAME:
          dispatch(setFirstName(value));
          break;

        case PROFILE_INPUT_KEY.LAST_NAME_KANA:
          dispatch(setLastNameKana(value));
          break;

        case PROFILE_INPUT_KEY.FIRST_NAME_KANA:
          dispatch(setFirstNameKana(value));
          break;

        case PROFILE_INPUT_KEY.STAFF_ID:
          dispatch(setStaffId(value));
          break;

        case PROFILE_INPUT_KEY.BIRTHDAY:
          setIsBirthdayFormatErr(!checkDateFormat(value));
          dispatch(setBirthday(value));
          break;

        case PROFILE_INPUT_KEY.JOB:
          dispatch(setJobId(Number(value) || 0));
          break;

        case PROFILE_INPUT_KEY.HIRE_DATE:
          setIsHireDateFormatErr(!checkDateFormat(value));
          dispatch(setHireDate(value));
          break;

        case PROFILE_INPUT_KEY.EMAIL:
          dispatch(setEmail(value));
          break;

        case PROFILE_INPUT_KEY.STATEID:
          dispatch(setStateId(Number(value)));
          break;

        case PROFILE_INPUT_KEY.CITY:
          dispatch(setCity(value));
          break;

        case PROFILE_INPUT_KEY.HOBBIES:
          dispatch(setHobby(value));
          break;

        case PROFILE_INPUT_KEY.FREE_SPACE:
          dispatch(setFreeSpace(value));
          break;

        case PROFILE_INPUT_KEY.DELETE_FLG:
          const num = Number(value);
          if (num === 0) {
            dispatch(setDeleteFlg(1));
          } else {
            dispatch(setDeleteFlg(0));
          }
          break;

        default:
          console.error("Not Found Key");
      }
    },
    [dispatch]
  );

  /**
   * インプットチェック
   * @return true: OK, false: NG
   * */
  const checkInputProfile = useCallback((): boolean => {
    let res: boolean = true;
    // 共通確認項目
    if (
      !profile.lastName ||
      !profile.firstName ||
      !profile.lastNameKana ||
      !profile.firstNameKana ||
      !profile.stateId ||
      !profile.city
    ) {
      return false;
    }

    if (isAdmin) {
      // 管理者編集
      if (
        !profile.staffId ||
        !profile.birthday ||
        !checkDateFormat(profile.birthday) ||
        !profile.hireDate ||
        !checkDateFormat(profile.hireDate) ||
        !profile.email
      ) {
        res = false;
      }
    } else {
      // 一般ユーザ編集
      if (
        (profile.jobId !== 1 &&
          profile.skills.length < PROFILE_ITEM_MIN_LENGTH.SKILLS) ||
        !profile.hobby ||
        profile.freeSpace.length < PROFILE_ITEM_MIN_LENGTH.FREE_SPACE
      ) {
        res = false;
      }
    }
    return res;
  }, [isAdmin, profile]);

  /** 入力チェック監視 */
  useEffect(() => {
    const res: boolean = checkInputProfile();
    setIsDisabledUpdate(!res);
  }, [checkInputProfile]);

  return {
    dispatch,
    isAdmin,
    userInfo,
    profile,
    currentSkills,
    searchedSkills,
    isDisabledUpdate,
    isBirthdayFormatErr,
    isHireDateFormatErr,
    handleChange,
  };
};
