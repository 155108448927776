import { FC } from "react";

import styles from "styles/components/Common/InfoLabel.module.css";
type Props = {
  children: string;
};

const InfoLabel: FC<Props> = ({ children }) => {
  return <div className={styles.info_label}>{children}</div>;
};

export default InfoLabel;
