import { FC, memo } from "react";
import { WelfareType } from "ducks/welfare/type";
import WelfareItem from "components/Welfares/WelfareItem";

type Props = {
  kokoTitle: string;
  kokoList: WelfareType[];
};

const WelfareBlock: FC<Props> = ({ kokoTitle, kokoList }) => {
  return (
    <div className="flex flex-col lg:mt-[15px] md:mt-[12px] mt-[6px]">
      <div className="relative lg:h-[40px] lg:ml-[40px] md:h-[36px] md:ml-[36px] h-[22px] ml-0">
        <div className="absolute z-[2] lg:px-[10px] lg:ml-[40px] lg:text-[30px] font-bold lg:tracking-[10px] bg-[var(--main-bg-color)]
        md:px-[8px] md:ml-[36px] md:text-[22px] md:tracking-[4px] px-[8px] ml-[28px] text-[18px] tracking-[4px]">{kokoTitle}</div>
        <div className="absolute lg:top-[20px] z-[1] w-[95%] h-0 lg:ml-[10px] border-[#808080] lg:border-[3px] opacity-[0.4]
        md:top-[18px] md:ml-[10px] top-[10px] ml-[10px] border-[2px]"></div>
      </div>
      <ul className="flex flex-wrap justify-start lg:mt-[20px] lg:pl-10 md:mt-[18px] md:pl-8 p-0 mt-[12px]">
        {kokoList.map((kokoItem: WelfareType, index: number) => {
          return (
            <WelfareItem
              key={index}
              sortKey={kokoItem.sortKey}
              name={kokoItem.name}
              icon={kokoItem.icon}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default memo(WelfareBlock);
