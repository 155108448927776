import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "aws-amplify";

import { RootState } from "app/store";
import { Skill } from "common/types";
import { sortSkill } from "common/common";
import { API_NAME, API_PATHS } from "common/constants";
import { initialState } from "ducks/profile/initialState";
import { Profile, ProfileState, PutEmployeeDetail } from "ducks/profile/type";

/**
 * ユーザの社員詳細取得
 * @param partitionKey 社員パーティションキー
 **/
export const fetchAsyncGetProfile = createAsyncThunk(
  "profile/getProfile",
  async (partitionKey: string, thunkAPI) => {
    const params = {
      queryStringParameters: {
        partitionKey,
      },
    };
    try {
      return await API.get(API_NAME, API_PATHS.EMPLOYEE_DETAIL, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * ユーザの社員詳細更新
 * @param partitionKey 社員パーティションキー
 **/
export const fetchAsyncUpdateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (args, thunkAPI) => {
    const stateAll: any = thunkAPI.getState();
    const uploadPicture: File | null = stateAll.profile.uploadPicture;
    const uploadSkillSheet: File | null = stateAll.profile.uploadSkillSheet;
    const state: Profile = stateAll.profile.profile;
    const userPartitionKey = stateAll.auth.userInfo.partitionKey;

    const skillSheetNm: string = uploadSkillSheet ? uploadSkillSheet.name : "";

    // 署名付きURL取得パラメータ
    const getPresignedUrlparams = {
      queryStringParameters: {
        partitionKey: state.partitionKey,
        skillSheetNm,
      },
    };

    // DB更新パラメータ
    const params: PutEmployeeDetail = {
      body: {
        userPartitionKey,
        partitionKey: state.partitionKey,
        staffId: state.staffId,
        firstName: state.firstName,
        lastName: state.lastName,
        stateId: state.stateId,
        city: state.city,
        firstNameKana: state.firstNameKana,
        lastNameKana: state.lastNameKana,
        birthday: state.birthday,
        hireDate: state.hireDate,
        email: state.email,
        deleteFlg: state.deleteFlg,
        jobId: state.jobId,
        hobby: state.hobby,
        skills: state.skills,
        freeSpace: state.freeSpace,
        skillSheet: skillSheetNm,
      },
    };
    try {
      // DB更新
      const res: any = await API.put(
        API_NAME,
        API_PATHS.EMPLOYEE_DETAIL,
        params
      );

      // S3更新
      if (uploadPicture || uploadSkillSheet) {
        // 署名付きURL取得
        const presignedUrl: any = await API.get(
          API_NAME,
          API_PATHS.PRESIGN_FOR_PUT,
          getPresignedUrlparams
        );

        // 写真更新
        if (uploadPicture && presignedUrl.pictureUrl) {
          const options = {
            headers: {
              "Content-Type": uploadPicture.type,
            },
          };
          await axios.put(presignedUrl.pictureUrl, uploadPicture, options);
        }

        // スキルシート更新
        if (uploadSkillSheet && presignedUrl.skillSheetUrl) {
          const options = {
            headers: {
              "Content-Type": uploadSkillSheet.type,
            },
          };
          await axios.put(
            presignedUrl.skillSheetUrl,
            uploadSkillSheet,
            options
          );
        }
      }

      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * スキル検索
 * @param searchVal 検索入力値
 **/
export const fetchAsyncGetSkills = createAsyncThunk(
  "profile/getSkills",
  async (searchVal: string, thunkAPI) => {
    const params = {
      queryStringParameters: {
        searchVal,
      },
    };
    try {
      return await API.get(API_NAME, API_PATHS.SKILLS, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setUploadPicture(
      state: ProfileState,
      action: PayloadAction<{ file: File | null }>
    ) {
      return {
        ...state,
        uploadPicture: action.payload.file,
      };
    },
    setUploadSkillSheet(
      state: ProfileState,
      action: PayloadAction<{ file: File | null }>
    ) {
      return {
        ...state,
        uploadSkillSheet: action.payload.file,
      };
    },
    setPicture(state: ProfileState, action: PayloadAction<string>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          picture: action.payload,
        },
      };
    },
    setLastName(state: ProfileState, action: PayloadAction<string>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          lastName: action.payload,
        },
      };
    },
    setFirstName(state: ProfileState, action: PayloadAction<string>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          firstName: action.payload,
        },
      };
    },
    setLastNameKana(state: ProfileState, action: PayloadAction<string>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          lastNameKana: action.payload,
        },
      };
    },
    setFirstNameKana(state: ProfileState, action: PayloadAction<string>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          firstNameKana: action.payload,
        },
      };
    },
    setStaffId(state: ProfileState, action: PayloadAction<string>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          staffId: action.payload,
        },
      };
    },
    setJobId(state: ProfileState, action: PayloadAction<number>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          jobId: action.payload,
        },
      };
    },
    setBirthday(state: ProfileState, action: PayloadAction<string>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          birthday: action.payload,
        },
      };
    },
    setHireDate(state: ProfileState, action: PayloadAction<string>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          hireDate: action.payload,
        },
      };
    },
    setEmail(state: ProfileState, action: PayloadAction<string>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          email: action.payload,
        },
      };
    },
    setStateId(state: ProfileState, action: PayloadAction<number>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          stateId: action.payload,
        },
      };
    },
    setCity(state: ProfileState, action: PayloadAction<string>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          city: action.payload,
        },
      };
    },
    setHobby(state: ProfileState, action: PayloadAction<string>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          hobby: action.payload,
        },
      };
    },
    setDeleteFlg(state: ProfileState, action: PayloadAction<number>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          deleteFlg: action.payload,
        },
      };
    },
    setFreeSpace(state: ProfileState, action: PayloadAction<string>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          freeSpace: action.payload,
        },
      };
    },
    setSkill(state: ProfileState, action: PayloadAction<Skill>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          skills: [...state.profile.skills, action.payload].sort(sortSkill),
        },
      };
    },
    unSetSkill(state: ProfileState, action: PayloadAction<Skill>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          skills: state.profile.skills
            .filter(
              (skill: Skill) => skill.skillName !== action.payload.skillName
            )
            .sort(sortSkill),
        },
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ProfileState>) => {
    builder
      .addCase(
        fetchAsyncGetProfile.fulfilled,
        (state: ProfileState, action: PayloadAction<any>) => {
          const profile: Profile = {
            ...action.payload,
            skills: action.payload.skills.sort(sortSkill),
          };
          return {
            ...state,
            profile,
            isLoading: false,
          };
        }
      )
      .addCase(
        fetchAsyncGetProfile.rejected,
        (state: ProfileState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncGetProfile.pending,
        (state: ProfileState, action: PayloadAction<any>) => {
          return {
            ...state,
            isLoading: true,
          };
        }
      )

      .addCase(
        fetchAsyncUpdateProfile.fulfilled,
        (state: ProfileState, action: PayloadAction<any>) => {
          return {
            ...state,
            isUpdateLoading: false,
            isUpdateFinished: true,
            uploadPicture: null,
            uploadSkillSheet: null,
          };
        }
      )
      .addCase(
        fetchAsyncUpdateProfile.rejected,
        (state: ProfileState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncUpdateProfile.pending,
        (state: ProfileState, action: PayloadAction<any>) => {
          return {
            ...state,
            isUpdateLoading: true,
            isUpdateFinished: false,
          };
        }
      )

      .addCase(
        fetchAsyncGetSkills.fulfilled,
        (state: ProfileState, action: PayloadAction<any>) => {
          return {
            ...state,
            searchedSkills: action.payload.skills.sort(sortSkill),
          };
        }
      )
      .addCase(
        fetchAsyncGetSkills.rejected,
        (state: ProfileState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncGetSkills.pending,
        (state: ProfileState, action: PayloadAction<any>) => {}
      );
  },
});

export const {
  setUploadPicture,
  setPicture,
  setLastName,
  setFirstName,
  setLastNameKana,
  setFirstNameKana,
  setStaffId,
  setJobId,
  setBirthday,
  setHireDate,
  setEmail,
  setStateId,
  setCity,
  setHobby,
  setFreeSpace,
  setDeleteFlg,
  setUploadSkillSheet,
  setSkill,
  unSetSkill,
} = profileSlice.actions;
export const selectIsLoading = (state: RootState) => state.profile.isLoading;
export const selectIsUpdateLoading = (state: RootState) =>
  state.profile.isUpdateLoading;
export const selectIsUpdateFinished = (state: RootState) =>
  state.profile.isUpdateFinished;
export const selectUploadSkillSheet = (state: RootState) =>
  state.profile.uploadSkillSheet;
export const selectProfile = (state: RootState) => state.profile.profile;
export const selectSkills = (state: RootState) => state.profile.profile.skills;
export const selectSearchedSkills = (state: RootState) =>
  state.profile.searchedSkills;
export default profileSlice.reducer;
