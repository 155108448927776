import { ChangeEvent, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";

import {
  fetchAsyncgetWorkRegulationUrl,
  fetchAsyncDeleteFile,
  fetchAsyncUploadFile,
  selectWorkRegulationsInfo,
  selectChildcareLeavesInfo,
  selectHygieneManagementsInfo,
  selectDefinedContributionPensionInfo,
  selectPMarkAndISMSInfo,
  setAddWorkRegulationFile,
  setAddChildcareLeaveFile,
  setAddHygieneManagementFile,
  setAddDefinedContributionPensionFile,
  setAddPMarkAndISMSFile,
} from "ducks/workRegulation/slice";
import { WorkRegulationInfo } from "ducks/workRegulation/type";
import { selectUserInfo } from "ducks/auth/slice";
import { UserInfo } from "ducks/auth/type";

import { S3_PREFIXES } from "common/constants";

/** 就業規則フック */
export const useWorkRegulations = () => {
  const dispatch = useDispatch<AppDispatch>();
  const workRegulationRef = useRef<HTMLInputElement>(null);
  const childcareLeaveRef = useRef<HTMLInputElement>(null);
  const hygieneManagementRef = useRef<HTMLInputElement>(null);
  const definedContributionPensionRef = useRef<HTMLInputElement>(null);
  const pMarkAndISMSRef = useRef<HTMLInputElement>(null);
  const [workRegulationAcordionOpen, setWorkRegulationAcordionOpen] =
    useState<boolean>(false);
  const [childcareLeaveAcordionOpen, setChildcareLeaveAcordionOpen] =
    useState<boolean>(false);
  const [hygieneManagementAcordionOpen, setHygieneManagementAcordionOpen] =
    useState<boolean>(false);
  const [definedContributionPensionAcordionOpen, setDefinedContributionPensionAcordionOpen] =
    useState<boolean>(false);
  const [pMarkAndISMSAcordionOpen, setPMarkAndISMSAcordionOpen] =
    useState<boolean>(false);

  const userInfo: UserInfo = useSelector(selectUserInfo);

  const workRegulationsInfo: WorkRegulationInfo = useSelector(
    selectWorkRegulationsInfo
  );

  const childcareLeavesInfo: WorkRegulationInfo = useSelector(
    selectChildcareLeavesInfo
  );

  const hygieneManagementsInfo: WorkRegulationInfo = useSelector(
    selectHygieneManagementsInfo
  );

  const definedContributionPensionInfo: WorkRegulationInfo = useSelector(
    selectDefinedContributionPensionInfo
  );

  const pMarkAndISMSInfo: WorkRegulationInfo = useSelector(
    selectPMarkAndISMSInfo
  );

  const viewPdfs: WorkRegulationInfo[] = [
    workRegulationsInfo,
    childcareLeavesInfo,
    hygieneManagementsInfo,
    definedContributionPensionInfo,
    pMarkAndISMSInfo,
  ];

  const judgeRef = (folder: string) => {
    switch (folder) {
      case S3_PREFIXES.WORK_REGULATION:
        return workRegulationRef;

      case S3_PREFIXES.CHILDCARE_LEAVE:
        return childcareLeaveRef;

      case S3_PREFIXES.HYGIENE_MANAGEMENT:
        return hygieneManagementRef;

      case S3_PREFIXES.DEFINED_CONTRIBUTION_PENSION:
        return definedContributionPensionRef;

      case S3_PREFIXES.P_MARK_AND_ISMS:
        return pMarkAndISMSRef;

      default:
        break;
    }
  };

  /** 就業規則PDF登録ボタンクリック */
  const clickUpload = (folder: string) => {
    switch (folder) {
      case S3_PREFIXES.WORK_REGULATION:
        workRegulationRef.current?.click();
        if (workRegulationAcordionOpen) {
          setWorkRegulationAcordionOpen(true);
        } else {
          setWorkRegulationAcordionOpen(false);
        }
        break;

      case S3_PREFIXES.CHILDCARE_LEAVE:
        childcareLeaveRef.current?.click();
        if (childcareLeaveAcordionOpen) {
          setChildcareLeaveAcordionOpen(true);
        } else {
          setChildcareLeaveAcordionOpen(false);
        }
        break;

      case S3_PREFIXES.HYGIENE_MANAGEMENT:
        hygieneManagementRef.current?.click();
        if (hygieneManagementAcordionOpen) {
          setHygieneManagementAcordionOpen(true);
        } else {
          setHygieneManagementAcordionOpen(false);
        }
        break;

      case S3_PREFIXES.DEFINED_CONTRIBUTION_PENSION:
        definedContributionPensionRef.current?.click();
        if (definedContributionPensionAcordionOpen) {
          setDefinedContributionPensionAcordionOpen(true);
        } else {
          setDefinedContributionPensionAcordionOpen(false);
        }
        break;

      case S3_PREFIXES.P_MARK_AND_ISMS:
        pMarkAndISMSRef.current?.click();
        if (pMarkAndISMSAcordionOpen) {
          setPMarkAndISMSAcordionOpen(true);
        } else {
          setPMarkAndISMSAcordionOpen(false);
        }
        break;

      default:
        break;
    }
  };

  /** アコーディオンの開閉判定 */
  const judgeAcordionOpen = (folder: string) => {
    switch (folder) {
      case S3_PREFIXES.WORK_REGULATION:
        return workRegulationAcordionOpen;

      case S3_PREFIXES.CHILDCARE_LEAVE:
        return childcareLeaveAcordionOpen;

      case S3_PREFIXES.HYGIENE_MANAGEMENT:
        return hygieneManagementAcordionOpen;

      case S3_PREFIXES.DEFINED_CONTRIBUTION_PENSION:
        return definedContributionPensionAcordionOpen;

      case S3_PREFIXES.P_MARK_AND_ISMS:
        return pMarkAndISMSAcordionOpen;

      default:
        break;
    }
  };

  /** アコーディオンのクリック */
  const clickAcordion = (folder: string) => {
    switch (folder) {
      case S3_PREFIXES.WORK_REGULATION:
        setWorkRegulationAcordionOpen(!workRegulationAcordionOpen);
        break;

      case S3_PREFIXES.CHILDCARE_LEAVE:
        setChildcareLeaveAcordionOpen(!childcareLeaveAcordionOpen);
        break;

      case S3_PREFIXES.HYGIENE_MANAGEMENT:
        setHygieneManagementAcordionOpen(!hygieneManagementAcordionOpen);
        break;

      case S3_PREFIXES.DEFINED_CONTRIBUTION_PENSION:
        setDefinedContributionPensionAcordionOpen(!definedContributionPensionAcordionOpen);
        break;

      case S3_PREFIXES.P_MARK_AND_ISMS:
        setPMarkAndISMSAcordionOpen(!pMarkAndISMSAcordionOpen);
        break;

      default:
        break;
    }
  };

  /** [Cancel]ボタンクリック */
  const clickCancel = (folder: string) => {
    switch (folder) {
      case S3_PREFIXES.WORK_REGULATION:
        dispatch(setAddWorkRegulationFile({ file: null }));
        break;

      case S3_PREFIXES.CHILDCARE_LEAVE:
        dispatch(setAddChildcareLeaveFile({ file: null }));
        break;

      case S3_PREFIXES.HYGIENE_MANAGEMENT:
        dispatch(setAddHygieneManagementFile({ file: null }));
        break;

      case S3_PREFIXES.DEFINED_CONTRIBUTION_PENSION:
        dispatch(setAddDefinedContributionPensionFile({ file: null }));
        break;

      case S3_PREFIXES.P_MARK_AND_ISMS:
        dispatch(setAddPMarkAndISMSFile({ file: null }));
        break;

      default:
        break;
    }
  };

  /** [Confirm]ボタンクリック */
  const clickConfirm = (folder: string) => {
    switch (folder) {
      case S3_PREFIXES.WORK_REGULATION:
        if (workRegulationsInfo.addFile !== null) {
          dispatch(
            fetchAsyncUploadFile({
              folder: folder,
              file: workRegulationsInfo.addFile,
            })
          );
        }
        break;

      case S3_PREFIXES.CHILDCARE_LEAVE:
        if (childcareLeavesInfo.addFile !== null) {
          dispatch(
            fetchAsyncUploadFile({
              folder: folder,
              file: childcareLeavesInfo.addFile,
            })
          );
        }
        break;

      case S3_PREFIXES.HYGIENE_MANAGEMENT:
        if (hygieneManagementsInfo.addFile !== null) {
          dispatch(
            fetchAsyncUploadFile({
              folder: folder,
              file: hygieneManagementsInfo.addFile,
            })
          );
        }
        break;

      case S3_PREFIXES.DEFINED_CONTRIBUTION_PENSION:
        if (definedContributionPensionInfo.addFile !== null) {
          dispatch(
            fetchAsyncUploadFile({
              folder: folder,
              file: definedContributionPensionInfo.addFile,
            })
          );
        }
        break;

      case S3_PREFIXES.P_MARK_AND_ISMS:
        if (pMarkAndISMSInfo.addFile !== null) {
          dispatch(
            fetchAsyncUploadFile({
              folder: folder,
              file: pMarkAndISMSInfo.addFile,
            })
          );
        }
        break;

      default:
        break;
    }
  };

  /** 就業規則PDF変更 */
  const changeUploadPdf = (
    e: ChangeEvent<HTMLInputElement>,
    folder: string
  ) => {
    if (e.target.files && e.target.files[0]) {
      const file: File = e.target.files[0];
      switch (folder) {
        case S3_PREFIXES.WORK_REGULATION:
          dispatch(setAddWorkRegulationFile({ file }));
          break;

        case S3_PREFIXES.CHILDCARE_LEAVE:
          dispatch(setAddChildcareLeaveFile({ file }));
          break;

        case S3_PREFIXES.HYGIENE_MANAGEMENT:
          dispatch(setAddHygieneManagementFile({ file }));
          break;

        case S3_PREFIXES.DEFINED_CONTRIBUTION_PENSION:
          dispatch(setAddDefinedContributionPensionFile({ file }));
          break;

        case S3_PREFIXES.P_MARK_AND_ISMS:
          dispatch(setAddPMarkAndISMSFile({ file }));
          break;

        default:
          break;
      }
    }
  };

  // ファイルリンククリック（ダウンロード）
  const onClickFile = (folder: string, file: string) => {
    dispatch(fetchAsyncgetWorkRegulationUrl({ folder: folder, file: file }));
  };

  // 削除ボタン押下（削除）
  const onClickDelete = (folder: string, file: string) => {
    dispatch(fetchAsyncDeleteFile({ folder: folder, file: file }));
  };

  return {
    dispatch,
    userInfo,
    workRegulationRef,
    childcareLeaveRef,
    hygieneManagementRef,
    definedContributionPensionRef,
    pMarkAndISMSRef,
    judgeAcordionOpen,
    clickAcordion,
    workRegulationsInfo,
    childcareLeavesInfo,
    hygieneManagementsInfo,
    definedContributionPensionInfo,
    pMarkAndISMSInfo,
    viewPdfs,
    judgeRef,
    clickUpload,
    clickCancel,
    clickConfirm,
    changeUploadPdf,
    onClickFile,
    onClickDelete,
  };
};
