import { ChangeEvent, FC, useCallback, useEffect, useState, memo } from "react";

import { convertIntoRowDate, getDateTimeNow } from "common/common";
import NoticeCard from "components/Common/NoticeCard";
import PortalBtn from "components/Common/PortalBtn";
import { useHome } from "hooks/homeHooks";
import {
  fetchAsyncPostNotice,
  fetchAsyncPutNotice,
  unsetIsPostFinished,
} from "ducks/notice/slice";
import { PostNoticeInput, PutNoticeInput } from "ducks/notice/type";
import { Input } from "components/Shadcn/Input";
import { Textarea } from "components/Shadcn/Textarea";
import { Label } from "components/Shadcn/Label";

import styles from "styles/components/Home/NoticeEditModal.module.css";

type Props = {
  isOpen: boolean;
  closeModal: VoidFunction;
  isUpdateMode?: boolean;
  title?: string;
  content?: string;
  createdAt?: string;
};

/**
 * お知らせ追加/編集モーダル
 * @param param0
 * @returns
 */
const NoticeEditModal: FC<Props> = ({
  isOpen,
  closeModal,
  isUpdateMode = false,
  title = "",
  content = "",
  createdAt = "",
}) => {
  const { dispatch, isUpdateLoading, isUpdateFinished, getNotices } = useHome();

  const [titleVal, setTitleVal] = useState<string>(title);
  const [contentVal, setContentVal] = useState<string>(content);
  const [isDisabledExe, setIsDisabeldExe] = useState<boolean>(false);

  const actionMode: "登録" | "更新" = isUpdateMode ? "更新" : "登録";

  /** input */
  const changeTitleVal = (e: ChangeEvent<HTMLInputElement>) => {
    setTitleVal(e.target.value);
  };
  const changeContentVal = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setContentVal(e.target.value);
  };
  const initVal = () => {
    setTitleVal("");
    setContentVal("");
  };

  /** モーダルクローズ */
  const closeModalOverride = useCallback(() => {
    initVal();
    getNotices();
    dispatch(unsetIsPostFinished());
    closeModal();
  }, [dispatch, closeModal, getNotices]);

  /** 処理実行 */
  const clickExe = () => {
    const now: string = getDateTimeNow();
    if (isUpdateMode) {
      // 更新
      const input: PutNoticeInput = {
        title: titleVal,
        content: contentVal,
        updatedAt: now,
        rawDate: convertIntoRowDate(createdAt),
      };
      dispatch(fetchAsyncPutNotice(input));
    } else {
      // 追加
      const input: PostNoticeInput = {
        title: titleVal,
        content: contentVal,
      };
      dispatch(fetchAsyncPostNotice(input));
    }
  };

  useEffect(() => {
    isUpdateFinished && !isUpdateMode && initVal();
  }, [isUpdateFinished, isUpdateMode]);

  /** input check */
  useEffect(() => {
    if (isUpdateMode) {
      // 更新用
      if (
        (titleVal === title && contentVal === content) ||
        !titleVal ||
        !contentVal
      ) {
        setIsDisabeldExe(true);
      } else {
        setIsDisabeldExe(false);
      }
    } else {
      // 追加用
      if (!titleVal || !contentVal) {
        setIsDisabeldExe(true);
      } else {
        setIsDisabeldExe(false);
      }
    }
  }, [dispatch, titleVal, contentVal, title, content, isUpdateMode]);

  return (
    <>
      {isOpen && (
        <div className={styles.overlay} onClick={closeModalOverride}>
          <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
            <div className={styles.modal_contents}>
              <div className="mt-5">
                <div className="grid w-full max-w-sm items-center gap-1.5">
                  <Label htmlFor="title_input">タイトル</Label>
                  <Input
                    type="text"
                    id="title_input"
                    placeholder="タイトルを入力"
                    maxLength={100}
                    value={titleVal}
                    onChange={changeTitleVal}
                  />
                </div>
              </div>

              <div className="mt-8">
                <div className="grid w-full gap-1.5">
                  <Label htmlFor="title_content_area">内容</Label>
                  <Textarea
                    className="h-80"
                    id="title_content_area"
                    placeholder="内容を入力"
                    value={contentVal}
                    maxLength={2000}
                    onChange={changeContentVal}
                  />
                </div>
              </div>

              <div className={styles.btns}>
                <PortalBtn
                  variant="outline"
                  clickFunc={closeModalOverride}
                  isLoading={isUpdateLoading}
                >
                  閉じる
                </PortalBtn>
                <PortalBtn
                  clickFunc={clickExe}
                  isLoading={isUpdateLoading}
                  disabled={isDisabledExe}
                >
                  {actionMode}
                </PortalBtn>
              </div>
            </div>
          </div>
        </div>
      )}
      <NoticeCard isFadedIn={isUpdateFinished}>
        {`${actionMode}が完了しました`}
      </NoticeCard>
    </>
  );
};

export default memo(NoticeEditModal);
