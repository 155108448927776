import { FC, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { LOCAL_STORAGE_KEYS } from "common/constants";
import { PAGES, PageType } from "common/PAGES";
import NoticeCard from "components/Common/NoticeCard";
import Layout from "components/Layout";
import EditThumbnail from "components/ProfileEdit/EditThumbnail";
import EditBasicInfo from "components/ProfileEdit/EditBasicInfo";
import Loader from "components/Loader";
import EditBoxes from "components/ProfileEdit/EditBoxes";
import PortalBtn from "components/Common/PortalBtn";
import { useProfileEdit } from "hooks/profileEditHooks";
import {
  fetchAsyncGetProfile,
  fetchAsyncUpdateProfile,
  selectIsLoading,
  selectIsUpdateFinished,
  selectIsUpdateLoading,
} from "ducks/profile/slice";

import styles from "styles/containers/ProfileEdit.module.css";

/**
 * 社員詳細編集画面
 * @returns
 */
const ProfileEdit: FC = () => {
  const pageName: string = useMemo(() => PAGES.PROFILE_EDIT.NAME, []);
  const breadcrumbs: PageType[] = useMemo(
    () => [PAGES.HOME, PAGES.PROFILE_EDIT],
    []
  );

  const isLoading: boolean = useSelector(selectIsLoading);
  const isUpdateLoading: boolean = useSelector(selectIsUpdateLoading);
  const isUpdateFinished: boolean = useSelector(selectIsUpdateFinished);

  const { dispatch, isDisabledUpdate } = useProfileEdit();

  /** 更新実行処理 */
  const clickUpdate = () => {
    dispatch(fetchAsyncUpdateProfile());
  };

  useEffect(() => {
    const targetKey: string | null = localStorage.getItem(
      LOCAL_STORAGE_KEYS.PROFILE_EDIT_KEY
    );
    targetKey && dispatch(fetchAsyncGetProfile(targetKey));
  }, [dispatch]);

  return (
    <Layout pageName={pageName} breadcrumbs={breadcrumbs}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.container}>
          <div className={styles.card}>
            <EditThumbnail />

            <form className={styles.form}>
              <EditBasicInfo />
              <EditBoxes />
            </form>
          </div>

          <div className={styles.update_btn}>
            {
              <PortalBtn
                clickFunc={clickUpdate}
                disabled={isDisabledUpdate}
                isLoading={isUpdateLoading}
              >
                更新
              </PortalBtn>
            }
          </div>
          <NoticeCard isFadedIn={isUpdateFinished}>
            更新が完了しました。
          </NoticeCard>
        </div>
      )}
    </Layout>
  );
};

export default ProfileEdit;
