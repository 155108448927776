import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import { Employee } from "ducks/employee/type";
import {
  selectIsLocked,
  selectIsLoading,
  selectPageTotal,
  selectEmployees,
  fetchAsyncSearchEmployees,
  setIsLocked,
  setNowPage,
  selectNowPage,
  selectConfirmSearchForm,
  selectRefreshFlg,
  setConfirmSearchForm,
} from "ducks/employee/slice";

/**
 * 社員一覧画面検索用カスタムフックス
 * @returns
 */
export const useProfile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isLocked: boolean = useSelector(selectIsLocked);
  const isLoading: boolean = useSelector(selectIsLoading);
  const pageTotal: number = useSelector(selectPageTotal);
  let employees: Employee[] = useSelector(selectEmployees);
  const confirmSearchForm = useSelector(selectConfirmSearchForm);
  const { hobbyFreeSpace, selectedSkill, stateId, fullName } = {
    ...confirmSearchForm,
  };
  const nowPage = useSelector(selectNowPage);
  const refreshFlg = useSelector(selectRefreshFlg);

  const pageChange = (pageNum: number) => {
    dispatch(setNowPage(pageNum));
    if (employees.length !== 0) {
      const inParam = {
        hobbyFreeSpace: hobbyFreeSpace,
        skill: selectedSkill,
        stateId: stateId,
        fullName: fullName,
        pageNum: pageNum,
      };
      dispatch(fetchAsyncSearchEmployees(inParam));
    } else {
      dispatch(fetchAsyncSearchEmployees());
      dispatch(setConfirmSearchForm());
    }
  };

  const lockOn = () => {
    dispatch(setIsLocked(true));
  }

  const lockOff = () => {
    dispatch(setIsLocked(false));
  }

  return {
    dispatch,
    isLocked,
    isLoading,
    lockOn,
    lockOff,
    pageTotal,
    employees,
    nowPage,
    pageChange,
    refreshFlg,
  };
};
