import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { RootState } from "app/store";
import { ActivityReportDetailState } from "./type";
import { initialState } from "ducks/activityReportDetail/initialState";
import { API_NAME, API_PATHS } from "common/constants";

/**
 * 活動報告詳細取得
 * @param partitionKey 活動報告記事のpartitionKey
 **/
export const fetchAsyncGetActivityReportDetail = createAsyncThunk(
  "activityReportDetail/getactivityReportDetail",
  async (partitionKey: string, thunkAPI) => {
    const params = {
      queryStringParameters: {
        partitionKey,
      },
    };
    try {
      return await API.get(API_NAME, API_PATHS.ACTIVITY_REPORT_DETAIL, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * 活動報告ビューカウント更新
 * @param partitionKey 活動報告記事のpartitionKey
 * @param countUpdateFlg viewCountインクリメント判定フラグ
 **/
export const fetchAsyncPutActivityReportViewCount = createAsyncThunk(
  "activityReportViewCount/putActivityReportViewCount",
  async (args: { partitionKey: string; updatedAt: string }, thunkAPI) => {
    // DB更新パラメータ
    const params = {
      body: {
        methodName: "fetchAsyncPutActivityReportViewCount",
        partitionKey: args.partitionKey,
        updatedAt: args.updatedAt,
      },
    };
    try {
      const res: any = await API.put(
        API_NAME,
        API_PATHS.ACTIVITY_REPORT_DETAIL,
        params
      );

      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const activityReportDetailSlice = createSlice({
  name: "activityReportDetail",
  initialState,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<ActivityReportDetailState>
  ) => {
    builder

      .addCase(
        fetchAsyncGetActivityReportDetail.fulfilled,
        (state: ActivityReportDetailState, action: PayloadAction<any>) => {
          return {
            ...state,
            isLoading: false,
            activityReportDetail: action.payload,
          };
        }
      )
      .addCase(
        fetchAsyncGetActivityReportDetail.rejected,
        (state: ActivityReportDetailState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncGetActivityReportDetail.pending,
        (state: ActivityReportDetailState, action: PayloadAction<any>) => {
          return {
            ...state,
            isLoading: true,
          };
        }
      )

      .addCase(
        fetchAsyncPutActivityReportViewCount.fulfilled,
        (state, action: PayloadAction<any>) => {}
      )
      .addCase(
        fetchAsyncPutActivityReportViewCount.rejected,
        (state, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncPutActivityReportViewCount.pending,
        (state, action: PayloadAction<any>) => {
          return {
            ...state,
            isLoading: true,
          };
        }
      );
  },
});

export const selectIsLoadingDetail = (state: RootState) =>
  state.activityReportDetail.isLoading;
export const selecActivityReportDetail = (state: RootState) =>
  state.activityReportDetail.activityReportDetail;

export default activityReportDetailSlice.reducer;
