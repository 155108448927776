import {
  FC,
  useEffect,
  useMemo,
  ChangeEvent,
  MouseEvent,
  useRef,
  useState,
} from "react";
import Layout from "components/Layout";
import { PAGES } from "common/PAGES";
import { PageType } from "common/PAGES";
import {
  setEyeCatchImg,
  setTitle,
  setContributorId,
  fetchAsyncGetActivityReportDrafts,
} from "ducks/activityAdd/slice";
import { useActivityAdd } from "hooks/activityAddHooks";

import {
  TitleArea,
  titleAreaSx,
  TitleTextField,
  titleTextFieldSx,
  EyeCatchArea,
  eyeCatchAreaSx,
  ButtonArea,
  buttonAreaSx,
  EyeCatchSelectIcon,
  EyeCatchDeleteIcon,
  PreviewOnIcon,
  PreviewOffIcon,
  PostIcon,
  SaveIcon,
  DraftIcon,
  DraftModalBox,
  DraftContentsBox,
  DraftContentTypography,
  DraftModal,
  draftContentsBoxSx,
} from "styles/containers/activityAddTheme";
import ActivityAddEditArea from "components/ActivityAdd/ActivityAddEditArea";
import ActivityAddPreviewArea from "components/ActivityAdd/ActivityAddPreviewArea";
import { Typography } from "@mui/material";
import { Button } from "components/Shadcn/Button";

const ActivityAdd: FC = () => {
  // 変数エリア
  const pageName: string = useMemo(() => PAGES.ACTIVITY_REPORT_ADD.NAME, []);
  const breadcrumbs: PageType[] = useMemo(
    () => [PAGES.HOME, PAGES.ACTIVITY_REPORT, PAGES.ACTIVITY_REPORT_ADD],
    []
  );

  /* useState */
  const [previewFlg, setPreviewFlg] = useState<boolean>(false);
  const [eyeCatchFile, setEyeCatchFile] = useState<File | null>(null);
  const [draftPartitionKey, setDraftPartitionKey] = useState<string>("");
  const [draftModalFlg, setDraftModalFlg] = useState<boolean>(false);

  /* useRef */
  const eyeCatchRef = useRef<HTMLInputElement>(null);

  // customHooks
  const {
    dispatch,
    userInfo,
    eyeCatchImg,
    title,
    draftReportList,
    onClickEyeCatch,
    onClickPost,
    onClickSave,
    onClickPreview,
    onClickDraftTitle,
    onChangeEyeCatch,
    judgePostDisabled,
    judgeDraftDisabled,
  } = useActivityAdd();

  // useEffect
  useEffect(() => {
    dispatch(setContributorId(userInfo.partitionKey));
    if (userInfo.partitionKey) {
      dispatch(fetchAsyncGetActivityReportDrafts(userInfo.partitionKey));
    }
  }, [dispatch, userInfo.partitionKey]);

  return (
    <Layout pageName={pageName} breadcrumbs={breadcrumbs}>
      <ButtonArea sx={buttonAreaSx}>
        {previewFlg ? (
          <Button variant="ghost">
            <PreviewOffIcon
              onClick={() => {
                setPreviewFlg(false);
              }}
            />
          </Button>
        ) : (
          <Button variant="ghost">
            <PreviewOnIcon
              onClick={() => {
                onClickPreview();
                setPreviewFlg(true);
              }}
            />
          </Button>
        )}
        <Button variant="ghost" disabled={judgePostDisabled()}>
          <PostIcon
            onClick={() => {
              onClickPost(
                draftPartitionKey,
                setEyeCatchFile,
                setDraftPartitionKey
              );
            }}
          />
        </Button>
        <Button variant="ghost">
          <SaveIcon
            onClick={() => {
              onClickSave(draftPartitionKey, setDraftPartitionKey);
            }}
          />
        </Button>
        <Button variant="ghost" disabled={judgeDraftDisabled()}>
          <DraftIcon
            onClick={() => {
              setDraftModalFlg(true);
            }}
          />
        </Button>
      </ButtonArea>

      {eyeCatchFile !== null && (
        <EyeCatchArea sx={eyeCatchAreaSx}>
          <img src={eyeCatchImg} alt="Loading…" />
        </EyeCatchArea>
      )}

      <TitleArea sx={titleAreaSx}>
        <TitleTextField
          id="post-title"
          placeholder="タイトル"
          variant="standard"
          inputProps={{ style: { fontSize: 28 } }}
          value={title}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dispatch(setTitle(e.target.value));
          }}
          sx={titleTextFieldSx}
        />
        <Button variant="ghost">
          {eyeCatchFile === null ? (
            <EyeCatchSelectIcon
              onClick={() => {
                onClickEyeCatch(eyeCatchRef);
              }}
            />
          ) : (
            <EyeCatchDeleteIcon
              onClick={() => {
                setEyeCatchFile(null);
                dispatch(setEyeCatchImg(""));
              }}
            />
          )}
        </Button>
        <input
          style={{ display: "none" }}
          type="file"
          ref={eyeCatchRef}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeEyeCatch(e, setEyeCatchFile)
          }
          // 同じファイルを再度指定された時用
          onClick={(e: MouseEvent<HTMLInputElement>) => {
            (e.target as HTMLInputElement).value = "";
          }}
        />
      </TitleArea>

      {previewFlg ? (
        <>
          <ActivityAddPreviewArea />
        </>
      ) : (
        <>
          <ActivityAddEditArea />
        </>
      )}
      {/* 下書き選択エリア */}
      <DraftModal
        open={draftModalFlg}
        onClose={() => {
          setDraftModalFlg(false);
        }}
        aria-labelledby="draft-modal-title"
        aria-describedby="draft-modal-content"
      >
        <DraftModalBox>
          <Typography id="draft-modal-title" variant="h3">
            下書き選択用モーダル
          </Typography>
          <DraftContentsBox id="draft-modal-content" sx={draftContentsBoxSx}>
            {draftReportList.length !== 0 ? (
              draftReportList.map((draftReport, index) => (
                <DraftContentTypography
                  key={index}
                  onClick={() => {
                    onClickDraftTitle(
                      draftReport,
                      setDraftPartitionKey,
                      setDraftModalFlg
                    );
                  }}
                >
                  {draftReport.title === ""
                    ? "タイトル未設定"
                    : draftReport.title}
                </DraftContentTypography>
              ))
            ) : (
              <div>一覧が存在しません。</div>
            )}
          </DraftContentsBox>
        </DraftModalBox>
      </DraftModal>
    </Layout>
  );
};

export default ActivityAdd;
