import { SxProps, Theme } from "@mui/material/styles";
import { Box, Container } from "@mui/material";
import emotionStyled from "@emotion/styled";

// Container
export const CustomContainer = emotionStyled(Container)`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
align-items: start;
position: relative;
`;

export const containerSx: SxProps<Theme> = {
  width: { xs: 1, sm: 1, md: 950, lg: 1100 },
  paddingX: { xs: 0, sm: 0, md: 1, lg: 1 },
};

/* Emotion */
export const TitleArea = emotionStyled(Box)`
display: flex;
justify-content: left;
font-weight: bold;
margin: 50px 0
`;

export const DayViewArea = emotionStyled(Box)`
display: flex;
align-items: center;
justify-content: center;
`;

export const AuthorArea = emotionStyled(Box)`
display: flex;
align-items: center;
justify-content: center;
`;

export const SideAuthorArea = emotionStyled(Box)`
display: flex;
align-items: center;
justify-content: center;
position: fixed;
left: 2%;
`;

export const ArticleArea = emotionStyled(Box)`
display: flex;
justify-content: left;
font-size: 1.25rem;
img {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
p {
  margin-bottom: 24px;
}
h1 {
  margin-bottom: 12px;
}
h2 {
  margin-bottom: 12px;
}
h3 {
  margin-bottom: 12px;
}
h4 {
  margin-bottom: 12px;
}
hr {
  margin-top: 12px;
  margin-bottom: 12px;
}
.break {
  margin-top: 0;
  margin-bottom: 0;
}`;

export const Article = emotionStyled.article`
max-width: 100%;
`;

// Area
export const cardSx: SxProps<Theme> = {
  maxWidth: 800,
  marginTop: 8,
  marginBottom: { xs: 8, sm: 8, md: 10, lg: 15 },
  padding: { xs: 2, sm: 2, md: 5, lg: 5 },
};

export const cardMediaSx: SxProps<Theme> = {
  maxWidth: { xs: "100%", sm: "100%", md: 850, lg: 1100 },
  maxHeight: { xs: "80%", sm: "80%", md: 600, lg: 700 },
};

export const titleAreaSx: SxProps<Theme> = {
  fontSize: { xs: "24px", sm: "24px", md: "26px", lg: "32px" },
};

export const infoAreaSx: SxProps<Theme> = {
  justifyContent: "space-between",
  alignItems: "center",
  mx: 1,
  my: 2,
};

export const dayViewElementSx: SxProps<Theme> = {
  fontSize: { xs: 13, sm: 15, md: 16, lg: 20 },
};

export const dayViewAreaSx: SxProps<Theme> = {
  gap: { xs: 0.5, md: 1, lg: 1 },
  fontSize: { xs: 13, sm: 15, md: 20, lg: 25 },
};

export const authorAreaSx: SxProps<Theme> = {
  gap: { xs: 0.2, md: 1, lg: 1 },
};

export const avatarElementSx: SxProps<Theme> = {
  width: { xs: 25, sm: 35, md: 40, lg: 40 },
  height: { xs: 25, sm: 35, md: 40, lg: 40 },
};

export const typographySx: SxProps<Theme> = {
  fontSize: { xs: 12, sm: 12, md: 18, lg: 20 },
  marginLeft: { xs: 1, md: 1, lg: 1 },
};

export const articleAreaSx: SxProps<Theme> = {
  marginTop: { xs: 5, md: 10, lg: 10 },
  marginBottom: { xs: 3, md: 3, lg: 5 },
};

export const sideAuthorAreaSx: SxProps<Theme> = {
  marginTop: 9,
  display: { xs: "none", sm: "none", md: "none", lg: "flex" },
};

export const sideAvatarElementSx: SxProps<Theme> = {
  width: { xs: 25, sm: 35, md: 40, lg: 55 },
  height: { xs: 25, sm: 35, md: 40, lg: 55 },
};

export const sideAvatarName: SxProps<Theme> = {
  fontSize: { lg: 20 },
  marginLeft: { xs: 1, md: 1, lg: 1 },
};

export const sideAvatarJobId: SxProps<Theme> = {
  fontSize: { lg: 16 },
  marginLeft: { xs: 1, md: 1, lg: 1 },
  color: "#808080",
};
