import { SEARCH_CATEGORY } from "common/constants";
import { EmployeeState } from "ducks/employee/type";

export const initialState: EmployeeState = {
  isLoading: true,
  employees: [],
  isDetailLoading: false,
  isLocked: false,
  employeeDetail: {
    partitionKey: "",
    staffId: "",
    firstName: "",
    lastName: "",
    firstNameKana: "",
    lastNameKana: "",
    birthday: "",
    hireDate: "",
    email: "",
    stateId: 0,
    city: "",
    picture: "",
    jobId: 0,
    hobby: "",
    skills: [],
    freeSpace: "",
    skillSheet: "",
  },
  searchInputBox: "",
  searchOptions: [],
  searchCategory: SEARCH_CATEGORY.NAME,
  searchForm: {
    hobbyFreeSpace: "",
    selectedSkill: null,
    stateId: null,
    fullName: "",
    errorMessage: null,
  },
  confirmSearchForm: {
    hobbyFreeSpace: "",
    selectedSkill: null,
    stateId: null,
    fullName: "",
  },
  pageTotal: 0,
  nowPage: 1,
  refreshFlg: true,
};
