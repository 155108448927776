import { useMemo } from "react";
import { PAGES, PageType } from "common/PAGES";
import { selectActivityList, selectActivitySide } from "ducks/activity/slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import { selectIsLoading } from "ducks/activity/slice";
import { ActivityReport } from "ducks/activity/type";
import { selectUserInfo } from "ducks/auth/slice";
import { UserInfo } from "ducks/auth/type";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

/** 活動報告一覧フック */
export const useActivities = () => {
  const navigate = useNavigate();
  // redux
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector(selectIsLoading);
  const activitiesList: ActivityReport[] = useSelector(selectActivityList);
  const activitiesSide: ActivityReport[] = useSelector(selectActivitySide);
  const userInfo: UserInfo = useSelector(selectUserInfo);

  // const
  const pageName: string = useMemo(() => PAGES.ACTIVITY_REPORT.NAME, []);
  const breadcrumbs: PageType[] = useMemo(
    () => [PAGES.HOME, PAGES.ACTIVITY_REPORT],
    []
  );

  // function
  const onClickDetail = async (partitionKey: string) => {
    // 画面遷移
    navigate(`${PAGES.ACTIVITY_REPORT_DETAIL.PATH}?reportId=${partitionKey}`);
  };

  const judgeNewLabel = (createdAt: string) => {
    return dayjs().diff(dayjs(createdAt), "month") < 1;
  };

  return {
    dispatch,
    isLoading,
    pageName,
    breadcrumbs,
    activitiesList,
    activitiesSide,
    userInfo,
    onClickDetail,
    judgeNewLabel,
  };
};
