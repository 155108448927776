import { FC, memo } from "react";

import { UNREGISTERED } from "common/constants";
import { Skill } from "common/types";
import SkillTag from "components/Common/SkillTag";
import styles from "styles/components/Profiles/ProfileDetailModal/ProfileBoxes.module.css";

type Props = {
  hobby: string;
  skills: Skill[];
  freeSpace: string;
  skillSheet: string;
};

/**
 * 社員技術経歴など
 * @param Props
 * @returns
 */
const ProfileBoxes: FC<Props> = ({ hobby, skills, freeSpace, skillSheet }) => {
  // スキルシート登録・DL
  // const [isDLFailed, setIsDLFailed] = useState<boolean>(false);
  // const [isDownloading, setIsDownloading] = useState<boolean>(false);

  // const clickSkillSheetDL = async () => {
  //   setIsDLFailed(false);
  //   setIsDownloading(true);
  //   const fileName = skillSheet;
  //   try {
  //     const presignedUrl = await getSkillSheetUrl(fileName);

  //     const res = await fetch(presignedUrl);
  //     if (res.status !== 200) {
  //       throw new Error();
  //     }

  //     const blob = await res.blob();
  //     saveAs(blob, fileName);
  //   } catch (e) {
  //     setIsDLFailed(true);
  //   } finally {
  //     setIsDownloading(false);
  //   }
  // };

  return (
    <>
      <div className={styles.input_box}>
        <div className={styles.frame}>
          <label htmlFor="skills">スキル</label>
          {skills.length > 0 ? (
            <div id="skills" className={styles.tags}>
              {skills.map((skill: Skill, index: number) => {
                return (
                  <SkillTag key={index} skill={skill}>
                    {skill.skillName}
                  </SkillTag>
                );
              })}
            </div>
          ) : (
            <p id="skills">{UNREGISTERED}</p>
          )}
          {/* <div className={styles.skill_download}>
            {skillSheet ? (
              isDownloading ? (
                <button disabled>ダウンロード中...</button>
              ) : (
                <button type="button" onClick={clickSkillSheetDL}>
                  スキルシートをダウンロード
                  <span>
                    <FiDownload />
                  </span>
                </button>
              )
            ) : (
              <button disabled>スキルシート未登録</button>
            )}
          </div> */}
        </div>
        {/* {isDLFailed && (
          <div className={styles.err_msg}>
            <p>スキルシートの取得に失敗しました。</p>
          </div>
        )} */}
      </div>

      <div className={styles.input_box}>
        <div className={styles.frame}>
          <label htmlFor="hobbies">趣味</label>
          <textarea
            id="hobbies"
            readOnly
            disabled
            className={styles.hobbies_input}
            defaultValue={hobby ? hobby : UNREGISTERED}
          ></textarea>
        </div>
      </div>

      <div className={styles.input_box}>
        <div className={styles.frame}>
          <label htmlFor="freeSpace">フリースペース</label>
          <textarea
            id="freeSpace"
            readOnly
            disabled
            className={styles.free_space_input}
            defaultValue={freeSpace ? freeSpace : UNREGISTERED}
          ></textarea>
        </div>
      </div>
    </>
  );
};

export default memo(ProfileBoxes);
