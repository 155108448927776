import { FC, ChangeEvent, useEffect, useMemo, MouseEvent } from "react";
import { PAGES, PageType } from "common/PAGES";
import Layout from "components/Layout";
import { Link } from "@mui/material";

import { fetchAsyncgetWorkRegulations } from "ducks/workRegulation/slice";
import { WorkRegulationInfo } from "ducks/workRegulation/type";
import {
  WorkRegulationArea,
  workRegulationAreaSx,
  WorkRegulationAccordion,
  workRegulationAccordionSx,
  WorkRegulationAccordionSummary,
  workRegulationAccordionSummarySx,
  WorkRegulationAccordionDetails,
  workRegulationAccordionDetailsSx,
  FolderTypography,
  UploadTypography,
  uploadTypographySx,
  UploadIcon,
  ExpandIcon,
  WorkRegulationsContainer,
  FileViewRow,
  fileViewRowSx,
  DeleteIcon,
  folderTypographySx,
  UploadModal,
  UploadModalCard,
  uploadModalCardSx,
  ModalStrArea,
  modalStrAreaSx,
  ModalFolderTypography,
  modalFolderTypographySx,
  ModalConfirmTypography,
  modalConfirmTypographySx,
  ModalFileTypography,
  modalFileTypographySx,
  ModalBtnArea,
  modalBtnAreaSx,
} from "styles/containers/workRegulationsTheme";

import { useWorkRegulations } from "hooks/workRegulationsHooks";
import { Button } from "components/Shadcn/Button";

const WorkRegulations: FC = () => {
  const {
    dispatch,
    userInfo,
    judgeAcordionOpen,
    clickAcordion,
    viewPdfs,
    judgeRef,
    clickUpload,
    clickCancel,
    clickConfirm,
    changeUploadPdf,
    onClickFile,
    onClickDelete,
  } = useWorkRegulations();
  const pageName: string = useMemo(() => PAGES.WORK_REGULATION.NAME, []);
  const breadcrumbs: PageType[] = useMemo(
    () => [PAGES.HOME, PAGES.WORK_REGULATION],
    []
  );

  // useEffect
  useEffect(() => {
    dispatch(fetchAsyncgetWorkRegulations());
  }, [dispatch]);

  return (
    <Layout pageName={pageName} breadcrumbs={breadcrumbs}>
      <WorkRegulationsContainer>
        {viewPdfs.map((viewPdf: WorkRegulationInfo) => {
          return (
            <>
              <WorkRegulationArea sx={workRegulationAreaSx}>
                <WorkRegulationAccordion
                  sx={workRegulationAccordionSx}
                  expanded={judgeAcordionOpen(viewPdf.folder)}
                >
                  <WorkRegulationAccordionSummary
                    expandIcon={<ExpandIcon />}
                    sx={workRegulationAccordionSummarySx}
                    onClick={() => {
                      clickAcordion(viewPdf.folder);
                    }}
                  >
                    <FolderTypography sx={folderTypographySx}>
                      {viewPdf.folder}
                    </FolderTypography>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      ref={judgeRef(viewPdf.folder)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        changeUploadPdf(e, viewPdf.folder)
                      }
                      // 同じファイルを再度指定された時用
                      onClick={(e: MouseEvent<HTMLInputElement>) => {
                        (e.target as HTMLInputElement).value = "";
                      }}
                    />
                  </WorkRegulationAccordionSummary>
                  <WorkRegulationAccordionDetails
                    sx={workRegulationAccordionDetailsSx}
                  >
                    {viewPdf.files.map((file: string) => {
                      return (
                        <FileViewRow sx={fileViewRowSx}>
                          <Link
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              onClickFile(viewPdf.folder, file);
                            }}
                          >
                            {file}
                          </Link>
                          {userInfo.isAdmin && (
                            <DeleteIcon
                              onClick={() => {
                                onClickDelete(viewPdf.folder, file);
                              }}
                            />
                          )}
                        </FileViewRow>
                      );
                    })}
                  </WorkRegulationAccordionDetails>
                </WorkRegulationAccordion>
                {userInfo.isAdmin && (
                  <Button
                    className="ml-2"
                    onClick={() => {
                      clickUpload(viewPdf.folder);
                    }}
                  >
                    <UploadTypography sx={uploadTypographySx}>
                      UPLOAD
                    </UploadTypography>
                    <UploadIcon className="ml-2" />
                  </Button>
                )}
              </WorkRegulationArea>
              <UploadModal
                open={viewPdf.addFile !== null}
                onClose={() => {
                  clickCancel(viewPdf.folder);
                }}
              >
                <UploadModalCard sx={uploadModalCardSx}>
                  <ModalStrArea sx={modalStrAreaSx}>
                    <ModalFolderTypography sx={modalFolderTypographySx}>
                      {`【${viewPdf.folder}】`}
                    </ModalFolderTypography>
                    <ModalConfirmTypography sx={modalConfirmTypographySx}>
                      以下のファイルをアップロードしますか？
                    </ModalConfirmTypography>
                    <ModalFileTypography sx={modalFileTypographySx}>
                      {viewPdf.addFile !== null && viewPdf.addFile.name}
                    </ModalFileTypography>
                  </ModalStrArea>
                  <ModalBtnArea sx={modalBtnAreaSx}>
                    <Button
                      variant="outline"
                      onClick={() => {
                        clickCancel(viewPdf.folder);
                      }}
                    >
                      キャンセル
                    </Button>
                    <Button
                      onClick={() => {
                        clickConfirm(viewPdf.folder);
                      }}
                    >
                      アップロード
                    </Button>
                  </ModalBtnArea>
                </UploadModalCard>
              </UploadModal>
            </>
          );
        })}
      </WorkRegulationsContainer>
    </Layout>
  );
};

export default WorkRegulations;
