import { FC, memo } from "react";
import { SearchErrorMessage, searchErrorMessageSx } from "styles/components/Profiles/searchAreaTheme";
import { useSearchArea } from "hooks/profiles/searchAreaHooks";
import SearchCategorySelect from "./SearchCategorySelect";
import SearchInput from "./SearchInput";

/**
 * 検索エリア
 * @returns
 */
const SearchArea: FC = () => {
  // useHooks
  const {
    errorMessage,
  } = useSearchArea();

  return (
    <>
      <div>
        <div className="mx-auto mt-1 items-center ">
          <div className="flex">
            <div className="group flex w-full rounded-md focus-within:outline-none focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
              <SearchCategorySelect></SearchCategorySelect>
              <SearchInput></SearchInput>
            </div>
          </div>
        </div>
      </div>
      {errorMessage && (
        <SearchErrorMessage sx={searchErrorMessageSx}>
          {errorMessage}
        </SearchErrorMessage>
      )}
    </>
  );
};

export default memo(SearchArea);