import { SxProps, Theme } from "@mui/material/styles";
import { Box, Pagination } from "@mui/material";
import emotionStyled from "@emotion/styled";

/* Emotion */
export const PaginationBox = emotionStyled(Box)`
  display: inline-block;
  text-align: center;
`;

export const EmployeesPagination = emotionStyled(Pagination)`
`;

/* Theme（レスポンシブ） */
export const PaginationBoxSx: SxProps<Theme> = {
  marginTop: 5,
};
