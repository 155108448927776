import { FC, memo } from "react";
import styles from "styles/components/Profiles/ProfileDetailModal/ProfileName.module.css";

type Props = {
  firstName: string;
  firstNameKana: string;
  lastName: string;
  lastNameKana: string;
};

/**
 * 社員名フリガナ付き
 * @param Props
 * @returns
 */
const ProfileName: FC<Props> = ({ firstName, firstNameKana, lastName, lastNameKana }) => {
  return (
    <div className={styles.name}>
      <p>
        <ruby>
          {lastName}
          <rt>{lastNameKana}</rt>
        </ruby>
        &ensp;
        <ruby>
          {firstName}
          <rt>{firstNameKana}</rt>
        </ruby>
      </p>
    </div>
  );
};

export default memo(ProfileName);
