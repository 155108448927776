import { FC, ReactNode } from "react";
import { PORTAL_BTN_LOADING } from "common/constants";
import { Button } from "components/Shadcn/Button";

type Props = {
  children: ReactNode;
  clickFunc: VoidFunction;
  disabled?: boolean;
  isLoading?: boolean;
  variant?: "default" | "destructive" | "outline" | "secondary" | "ghost" | "link" | "admin" | null | undefined;
};

/**
 * ポータル共通ボタン
 * @param Props
 * @returns
 */
const PortalBtn: FC<Props> = ({
  children,
  clickFunc,
  disabled = false,
  isLoading = false,
  variant,
}) => {
  if (isLoading) {
    return (
      <Button variant={variant} disabled={true}>
        <img src={PORTAL_BTN_LOADING} className="h-full" alt="load" />
      </Button>
    );
  } else {
    return (
      <Button variant={variant} onClick={clickFunc} disabled={disabled ? disabled : false}>
        {children}
      </Button>
    );
  }
};

export default PortalBtn;
