import { FC, useEffect, useState, ChangeEvent } from "react";
import {
  PROFILE_ITEM_MAX_LENGTH,
  ERR_MSG,
  JOBS,
  STATEIDS,
  PROFILE_INPUT_KEY,
  DATE_FORMAT,
} from "common/constants";
import { calculateAge } from "common/common";
import { useProfileEdit } from "hooks/profileEditHooks";
import styles from "styles/components/ProfileEdit/EditBasicInfo.module.css";
import { Input } from "components/Shadcn/Input";
import { Label } from "components/Shadcn/Label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/Shadcn/Select";

/**
 * 基本情報の編集部文
 * @returns
 */
const EditBasicInfo: FC = () => {
  const {
    isAdmin,
    userInfo,
    profile,
    isBirthdayFormatErr,
    isHireDateFormatErr,
    handleChange,
  } = useProfileEdit();

  const [age, setAge] = useState<string>("");

  // shaduiのselectボックス用
  const handleSelectChange = (id: string, value: string) => {
    const event = {
      target: {
        id,
        value,
      },
    } as ChangeEvent<HTMLInputElement>;

    handleChange(event);
  };

  /** 年齢計算 */
  useEffect(() => {
    const calResult: number = calculateAge(profile.birthday);
    if (isNaN(calResult)) {
      setAge("");
    } else {
      setAge(calResult.toString());
    }
  }, [profile.birthday]);

  return (
    <div>
      <div className="grid grid-cols-2 gap-4 mb-6 md:mb-4">
        <div>
          <Label className="mb-1" htmlFor={PROFILE_INPUT_KEY.LAST_NAME}>
            姓
          </Label>
          <Input
            type="text"
            id={PROFILE_INPUT_KEY.LAST_NAME}
            maxLength={PROFILE_ITEM_MAX_LENGTH.LAST_NAME}
            defaultValue={profile.lastName}
            onChange={handleChange}
          />
          {!profile.lastName && <small>{ERR_MSG.EMPTY}</small>}
        </div>
        <div>
          <Label className="mb-1" htmlFor={PROFILE_INPUT_KEY.FIRST_NAME}>
            名
          </Label>
          <Input
            type="text"
            id={PROFILE_INPUT_KEY.FIRST_NAME}
            maxLength={PROFILE_ITEM_MAX_LENGTH.FIRST_NAME}
            defaultValue={profile.firstName}
            onChange={handleChange}
          />
          {!profile.firstName && <small>{ERR_MSG.EMPTY}</small>}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6 md:mb-4">
        <div>
          <Label className="mb-1" htmlFor={PROFILE_INPUT_KEY.LAST_NAME_KANA}>
            姓（カナ）
          </Label>
          <Input
            type="text"
            id={PROFILE_INPUT_KEY.LAST_NAME_KANA}
            maxLength={PROFILE_ITEM_MAX_LENGTH.LAST_NAME_KANA}
            defaultValue={profile.lastNameKana}
            onChange={handleChange}
          />
          {!profile.lastNameKana && <small>{ERR_MSG.EMPTY}</small>}
        </div>
        <div>
          <Label className="mb-1" htmlFor={PROFILE_INPUT_KEY.FIRST_NAME_KANA}>
            名（カナ）
          </Label>
          <Input
            type="text"
            id={PROFILE_INPUT_KEY.FIRST_NAME_KANA}
            maxLength={PROFILE_ITEM_MAX_LENGTH.FIRST_NAME_KANA}
            defaultValue={profile.firstNameKana}
            onChange={handleChange}
          />
          {!profile.firstNameKana && <small>{ERR_MSG.EMPTY}</small>}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6 md:mb-4">
        <div>
          <Label className="mb-1" htmlFor={PROFILE_INPUT_KEY.STAFF_ID}>
            社員ID
          </Label>
          <Input
            type="text"
            disabled={!isAdmin}
            id={PROFILE_INPUT_KEY.STAFF_ID}
            maxLength={PROFILE_ITEM_MAX_LENGTH.STAFF_ID}
            defaultValue={profile.staffId}
            onChange={handleChange}
          />
          {isAdmin && !profile.staffId && <small>{ERR_MSG.EMPTY}</small>}
        </div>
        <div>
          <Label className="mb-1" htmlFor={PROFILE_INPUT_KEY.JOB}>
            職務
          </Label>
          {/* diabled時の見た目統一用 */}
          {isAdmin ? (
            <Select
              value={profile.jobId.toString()}
              onValueChange={(value) =>
                handleSelectChange(PROFILE_INPUT_KEY.JOB, value)
              }
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {JOBS.map((job: string, index: number) => {
                    return (
                      <SelectItem key={index} value={index.toString()}>
                        {job}
                      </SelectItem>
                    );
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
          ) : (
            <Input
              type="text"
              disabled
              id={PROFILE_INPUT_KEY.JOB}
              defaultValue={JOBS[profile.jobId]}
            />
          )}
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 mb-4">
        <div>
          <Label className="mb-1" htmlFor={PROFILE_INPUT_KEY.BIRTHDAY}>
            誕生日
          </Label>
          <Input
            type="text"
            disabled={!isAdmin}
            placeholder={DATE_FORMAT}
            id={PROFILE_INPUT_KEY.BIRTHDAY}
            maxLength={PROFILE_ITEM_MAX_LENGTH.BIRTHDAY}
            defaultValue={profile.birthday}
            onChange={handleChange}
          />
          {isAdmin && !profile.birthday ? (
            <small>{ERR_MSG.EMPTY}</small>
          ) : (
            isAdmin &&
            isBirthdayFormatErr && <small>{ERR_MSG.DATE_FORMAT}</small>
          )}
        </div>
        <div>
          <Label className="mb-1" htmlFor={PROFILE_INPUT_KEY.HIRE_DATE}>
            入社日
          </Label>
          <Input
            type="text"
            disabled={!isAdmin}
            placeholder={DATE_FORMAT}
            id={PROFILE_INPUT_KEY.HIRE_DATE}
            maxLength={PROFILE_ITEM_MAX_LENGTH.HIRE_DATE}
            defaultValue={profile.hireDate}
            onChange={handleChange}
          />
          {isAdmin && !profile.hireDate ? (
            <small>{ERR_MSG.EMPTY}</small>
          ) : (
            isAdmin &&
            isHireDateFormatErr && <small>{ERR_MSG.DATE_FORMAT}</small>
          )}
        </div>
        <div className={styles.age}>
          <Label className="mb-1" htmlFor={PROFILE_INPUT_KEY.AGE}>
            年齢
          </Label>
          <p id={PROFILE_INPUT_KEY.AGE}>{age}</p>
        </div>
      </div>

      <div className="mb-4">
        <div>
          <Label className="mb-1" htmlFor={PROFILE_INPUT_KEY.EMAIL}>
            メールアドレス
          </Label>
          <Input
            type="email"
            disabled={!isAdmin}
            id={PROFILE_INPUT_KEY.EMAIL}
            maxLength={PROFILE_ITEM_MAX_LENGTH.MAIL}
            defaultValue={profile.email}
            onChange={handleChange}
          />
          {isAdmin && !profile.email && <small>{ERR_MSG.EMPTY}</small>}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6 md:mb-4">
        <div>
          <Label className="mb-1" htmlFor={PROFILE_INPUT_KEY.AGE}>
            都道府県
          </Label>
          <Select
            value={profile.stateId.toString()}
            onValueChange={(value) =>
              handleSelectChange(PROFILE_INPUT_KEY.STATEID, value)
            }
          >
            <SelectTrigger className="">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {STATEIDS.map((stateId: string, index: number) => {
                  return (
                    <SelectItem key={index} value={index.toString()}>
                      {stateId}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
          {(!profile.stateId || profile.stateId === 0) && (
            <small>{ERR_MSG.EMPTY}</small>
          )}
        </div>
        <div>
          <Label className="mb-1" htmlFor={PROFILE_INPUT_KEY.CITY}>
            市区町村
          </Label>
          <Input
            type="text"
            id={PROFILE_INPUT_KEY.CITY}
            maxLength={PROFILE_ITEM_MAX_LENGTH.CITY}
            defaultValue={profile.city}
            onChange={handleChange}
          />
          {!profile.city && <small>{ERR_MSG.EMPTY}</small>}
        </div>
      </div>

      {isAdmin && userInfo.partitionKey !== profile.partitionKey && (
        <div className={styles.input_row_check}>
          <div className="flex items-center">
            <div>
              <input
                id={PROFILE_INPUT_KEY.DELETE_FLG}
                type="checkbox"
                value={profile.deleteFlg}
                onChange={handleChange}
                checked={profile.deleteFlg === 0 ? false : true}
              />
            </div>
            <div className="ml-3">
              <label htmlFor={PROFILE_INPUT_KEY.DELETE_FLG}>退職済み</label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditBasicInfo;
