import { FC } from "react";
import styles from "styles/components/Loader.module.css";

/**
 * ローディング
 * @returns
 */
const Loader: FC = () => {
  return (
    <div className={styles.load_container}>
      <div className={styles.loader}></div>
      <p className={styles.loader_text}>Now loading...</p>
    </div>
  );
};

export default Loader;
