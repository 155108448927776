import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { RootState } from "app/store";

import { escapeForAwsIntegra } from "common/common";
import { API_NAME, API_PATHS } from "common/constants";
import { initialState } from "ducks/notice/initialState";
import {
  NoticeState,
  PostNoticeInput,
  PutNoticeInput,
} from "ducks/notice/type";

/**
 * お知らせ情報取得
 * @param date 日時(yyyy/mm/dd hh:mm:ss形式)
 **/
export const fetchAsyncGetNotices = createAsyncThunk(
  "notice/getNotices",
  async (date: string, thunkAPI) => {
    const params = {
      queryStringParameters: {
        date,
      },
    };
    try {
      return await API.get(API_NAME, API_PATHS.NOTICES, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * お知らせ情報追加
 * @param input
 **/
export const fetchAsyncPostNotice = createAsyncThunk(
  "notice/postNotice",
  async (input: PostNoticeInput, thunkAPI) => {
    const params = {
      body: {
        title: input.title,
        content: input.content,
      },
    };
    try {
      return await API.post(API_NAME, API_PATHS.NOTICES, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * お知らせ情報更新
 * @param input
 **/
export const fetchAsyncPutNotice = createAsyncThunk(
  "notice/putNotice",
  async (input: PutNoticeInput, thunkAPI) => {
    const params = {
      body: {
        title: input.title,
        content: escapeForAwsIntegra(input.content),
        updatedAt: input.updatedAt,
        rawDate: input.rawDate,
      },
    };
    try {
      return await API.put(API_NAME, API_PATHS.NOTICES, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const noticeSlice = createSlice({
  name: "notice",
  initialState,
  reducers: {
    unsetIsPostFinished(state: NoticeState) {
      return {
        ...state,
        isUpdateFinished: false,
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoticeState>) => {
    builder
      .addCase(
        fetchAsyncGetNotices.fulfilled,
        (state: NoticeState, action: PayloadAction<any>) => {
          return {
            ...state,
            notices: action.payload.data,
            isLoading: false,
          };
        }
      )
      .addCase(
        fetchAsyncGetNotices.pending,
        (state: NoticeState, action: PayloadAction<any>) => {
          return {
            ...state,
            isLoading: true,
          };
        }
      )
      .addCase(
        fetchAsyncGetNotices.rejected,
        (state: NoticeState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )

      .addCase(
        fetchAsyncPostNotice.fulfilled,
        (state: NoticeState, action: PayloadAction<any>) => {
          return {
            ...state,
            isUpdateLoading: false,
            isUpdateFinished: true,
          };
        }
      )
      .addCase(
        fetchAsyncPostNotice.pending,
        (state: NoticeState, action: PayloadAction<any>) => {
          return {
            ...state,
            isUpdateLoading: true,
            isUpdateFinished: false,
          };
        }
      )
      .addCase(
        fetchAsyncPostNotice.rejected,
        (state: NoticeState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )

      .addCase(
        fetchAsyncPutNotice.fulfilled,
        (state: NoticeState, action: PayloadAction<any>) => {
          return {
            ...state,
            isUpdateLoading: false,
            isUpdateFinished: true,
          };
        }
      )
      .addCase(
        fetchAsyncPutNotice.pending,
        (state: NoticeState, action: PayloadAction<any>) => {
          return {
            ...state,
            isUpdateLoading: true,
            isUpdateFinished: false,
          };
        }
      )
      .addCase(
        fetchAsyncPutNotice.rejected,
        (state: NoticeState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      );
  },
});

export const { unsetIsPostFinished } = noticeSlice.actions;

export const selectIsLoading = (state: RootState) => state.notice.isLoading;
export const selectIsUpdateLoading = (state: RootState) =>
  state.notice.isUpdateLoading;
export const selectIsUpdateFinished = (state: RootState) =>
  state.notice.isUpdateFinished;
export const selectNotices = (state: RootState) => state.notice.notices;
export default noticeSlice.reducer;
