import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "aws-amplify";
import { RootState } from "app/store";
import { initialState } from "ducks/workRegulation/initialState";
import { WorkRegulationState } from "./type";
import { API_NAME, API_PATHS } from "common/constants";

/**
 * 就業規則PDF一覧取得
 * @param args none
 **/
export const fetchAsyncgetWorkRegulations = createAsyncThunk(
  "workRegulation/getFiles",
  async (args, thunkAPI) => {
    try {
      return await API.get(API_NAME, API_PATHS.WORK_REGULATIONS, {});
    } catch (e) {
      console.log(e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * 就業規則PDFのダウンロード
 * @param args ｛folder: string, file: string｝
 **/
export const fetchAsyncgetWorkRegulationUrl = createAsyncThunk(
  "workRegulation/downLoadFile",
  async (args: { folder: string; file: string }, thunkAPI) => {
    const params = {
      queryStringParameters: {
        folder: args.folder,
        file: args.file,
      },
    };
    try {
      return await API.post(
        API_NAME,
        API_PATHS.WORK_REGULATION_DOWNLOAD_GET,
        params
      );
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * 就業規則PDFの削除
 * @param args ｛folder: string, file: string｝
 **/
export const fetchAsyncDeleteFile = createAsyncThunk(
  "workRegulation/deleteFile",
  async (args: { folder: string; file: string }, thunkAPI) => {
    const params = {
      queryStringParameters: {
        folder: args.folder,
        file: args.file,
      },
    };
    try {
      const res = await API.post(
        API_NAME,
        API_PATHS.WORK_REGULATION_DELETE,
        params
      );
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * 就業規則PDFのアップロード
 * @param args ｛folder: string, file: File｝
 **/
export const fetchAsyncUploadFile = createAsyncThunk(
  "workRegulation/upLoadFile",
  async (args: { folder: string; file: File }, thunkAPI) => {
    const presignedGetParams = {
      queryStringParameters: {
        folder: args.folder,
        fileName: args.file.name,
      },
    };
    try {
      // 署名付きURL取得
      const presignedUrl: any = await API.post(
        API_NAME,
        API_PATHS.WORK_REGULATION_UPLOAD_GET,
        presignedGetParams
      );

      // ファイルアップロード
      const options = {
        headers: {
          "Content-Type": args.file.type,
        },
      };
      await axios.put(presignedUrl.pdfUrl, args.file, options);

      return;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const workRegulationSlice = createSlice({
  name: "workRegulation",
  initialState,
  reducers: {
    setAddWorkRegulationFile(
      state: WorkRegulationState,
      action: PayloadAction<{ file: File | null }>
    ) {
      return {
        ...state,
        workRegulationsInfo: {
          ...state.workRegulationsInfo,
          addFile: action.payload.file,
        },
      };
    },
    setAddChildcareLeaveFile(
      state: WorkRegulationState,
      action: PayloadAction<{ file: File | null }>
    ) {
      return {
        ...state,
        childcareLeavesInfo: {
          ...state.childcareLeavesInfo,
          addFile: action.payload.file,
        },
      };
    },
    setAddHygieneManagementFile(
      state: WorkRegulationState,
      action: PayloadAction<{ file: File | null }>
    ) {
      return {
        ...state,
        hygieneManagementsInfo: {
          ...state.hygieneManagementsInfo,
          addFile: action.payload.file,
        },
      };
    },
    setAddDefinedContributionPensionFile(
      state: WorkRegulationState,
      action: PayloadAction<{ file: File | null }>
    ) {
      return {
        ...state,
        definedContributionPensionInfo: {
          ...state.definedContributionPensionInfo,
          addFile: action.payload.file,
        },
      };
    },
    setAddPMarkAndISMSFile(
      state: WorkRegulationState,
      action: PayloadAction<{ file: File | null }>
    ) {
      return {
        ...state,
        pMarkAndISMSInfo: {
          ...state.pMarkAndISMSInfo,
          addFile: action.payload.file,
        },
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<WorkRegulationState>) => {
    builder
      .addCase(
        fetchAsyncgetWorkRegulations.fulfilled,
        (state: WorkRegulationState, action: PayloadAction<any>) => {
          return {
            ...state,
            workRegulationsInfo: {
              ...state.workRegulationsInfo,
              files: action.payload.workRegulationFiles,
            },
            childcareLeavesInfo: {
              ...state.childcareLeavesInfo,
              files: action.payload.childcareLeaveFiles,
            },
            hygieneManagementsInfo: {
              ...state.hygieneManagementsInfo,
              files: action.payload.hygieneManagementFiles,
            },
            definedContributionPensionInfo: {
              ...state.definedContributionPensionInfo,
              files: action.payload.definedContributionPensionFiles,
            },
            pMarkAndISMSInfo: {
              ...state.pMarkAndISMSInfo,
              files: action.payload.pMarkAndISMSFiles,
            },
          };
        }
      )
      .addCase(
        fetchAsyncgetWorkRegulations.rejected,
        (state: WorkRegulationState, action: PayloadAction<any>) => {
          // window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncgetWorkRegulations.pending,
        (state: WorkRegulationState, action: PayloadAction<any>) => {}
      )
      .addCase(
        fetchAsyncgetWorkRegulationUrl.fulfilled,
        (state: WorkRegulationState, action: PayloadAction<any>) => {
          window.open(action.payload.pdfUrl);
        }
      )
      .addCase(
        fetchAsyncgetWorkRegulationUrl.rejected,
        (state: WorkRegulationState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncgetWorkRegulationUrl.pending,
        (state: WorkRegulationState, action: PayloadAction<any>) => {}
      )
      .addCase(
        fetchAsyncDeleteFile.fulfilled,
        (state: WorkRegulationState, action: PayloadAction<any>) => {
          window.location.href = "/work-regulations";
        }
      )
      .addCase(
        fetchAsyncDeleteFile.rejected,
        (state: WorkRegulationState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncDeleteFile.pending,
        (state: WorkRegulationState, action: PayloadAction<any>) => {}
      )
      .addCase(
        fetchAsyncUploadFile.fulfilled,
        (state: WorkRegulationState, action: PayloadAction<any>) => {
          window.location.href = "/work-regulations";
        }
      )
      .addCase(
        fetchAsyncUploadFile.rejected,
        (state: WorkRegulationState, action: PayloadAction<any>) => {
          // window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncUploadFile.pending,
        (state: WorkRegulationState, action: PayloadAction<any>) => {}
      );
  },
});

export const {
  setAddWorkRegulationFile,
  setAddChildcareLeaveFile,
  setAddHygieneManagementFile,
  setAddDefinedContributionPensionFile,
  setAddPMarkAndISMSFile,
} = workRegulationSlice.actions;
export const selectIsLoading = (state: RootState) =>
  state.workRegulation.isLoading;
export const selectWorkRegulationsInfo = (state: RootState) =>
  state.workRegulation.workRegulationsInfo;
export const selectChildcareLeavesInfo = (state: RootState) =>
  state.workRegulation.childcareLeavesInfo;
export const selectHygieneManagementsInfo = (state: RootState) =>
  state.workRegulation.hygieneManagementsInfo;
export const selectDefinedContributionPensionInfo = (state: RootState) =>
  state.workRegulation.definedContributionPensionInfo;
export const selectPMarkAndISMSInfo = (state: RootState) =>
  state.workRegulation.pMarkAndISMSInfo;
export default workRegulationSlice.reducer;
