import log from "loglevel";
import { Auth, Amplify } from "aws-amplify";
import { API_NAME } from "common/constants";

/** 初期処理フック */
export const useInitialHooks = () => {
  /** アプリ全体の初期設定を行う */
  const initialize = () => {
    /** Amplify Libraryの設定 */
    Amplify.configure({
      Auth: {
        region: "ap-northeast-1",
        userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        oauth: {
          domain: process.env.REACT_APP_COGNITO_DOMAIN,
          redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
          redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT,
          scope: ["openid", "email", "profile"],
          responseType: "code",
        },
      },
      API: {
        endpoints: [
          {
            name: API_NAME,
            endpoint: process.env.REACT_APP_AGW_ENDPOINT,
            custom_header: async () => {
              return {
                Authorization: `Bearer ${(await Auth.currentSession())
                  .getIdToken()
                  .getJwtToken()}`,
              };
            },
          },
        ],
      },
    });
    /** ログレベル */
    switch (process.env.REACT_APP_LOG_LEVEL) {
      case "info":
        log.setLevel("info");
        break;
      case "warn":
        log.setLevel("warn");
        break;
      case "error":
        log.setLevel("error");
        break;
      default:
        log.setLevel("debug");
        break;
    }
  };

  return { initialize };
};
