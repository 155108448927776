import { FC, useEffect, useMemo } from "react";
import { PAGES, PageType } from "common/PAGES";
import Layout from "components/Layout";
import Loader from "components/Loader";
import SearchArea from "components/SearchArea";
import Profile from "components/Profiles/Profile";
import { Employee } from "ducks/employee/type";
import {
  fetchAsyncSearchEmployees,
  setNowPage,
  setConfirmSearchForm,
} from "ducks/employee/slice";
import styles from "styles/containers/Profiles.module.css";
import { useProfile } from "hooks/profileHooks";
import {
  EmployeesPagination,
  PaginationBox,
  PaginationBoxSx,
} from "styles/components/Profiles/profilesTheme";
import { cn } from "lib/utils";
import { useSearchParams } from "react-router-dom";
import { initialState } from "ducks/employee/initialState";

/**
 * 社員一覧画面
 * @returns
 */
const Profiles: FC = () => {
  const pageName: string = useMemo(() => PAGES.PROFILES.NAME, []);
  const breadcrumbs: PageType[] = useMemo(
    () => [PAGES.HOME, PAGES.PROFILES],
    []
  );

  const {
    dispatch,
    isLocked,
    isLoading,
    pageTotal,
    employees,
    nowPage,
    pageChange,
    refreshFlg,
  } = useProfile();

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (!searchParams.size) {
      setSearchParams({ category: String(initialState.searchCategory.value), word: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refreshFlg) {
      dispatch(fetchAsyncSearchEmployees());
      dispatch(setConfirmSearchForm());
      dispatch(setNowPage(1));
    }
  }, [dispatch, refreshFlg]);

  return (
    <Layout pageName={pageName} breadcrumbs={breadcrumbs}>
      <div className={styles.searcContainer}>
        <SearchArea />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={cn(styles.container, isLocked ? "pointer-events-none" : "")}>
          <ul className={styles.profiles}>
            {employees.map((searchEmployee: Employee, index: number) => {
              return (
                <Profile
                  key={index}
                  id={searchEmployee.partitionKey}
                  firstName={searchEmployee.firstName}
                  lastName={searchEmployee.lastName}
                  picture={searchEmployee.picture}
                  jobId={searchEmployee.jobId}
                  badgeStyle={searchEmployee.badgeStyle}
                  deleteFlg={searchEmployee.deleteFlg}
                />
              );
            })}
          </ul>
          <PaginationBox sx={PaginationBoxSx}>
            <EmployeesPagination
              count={pageTotal}
              page={nowPage}
              variant="outlined"
              showFirstButton
              showLastButton
              onChange={(e, page) => pageChange(page)}
            />
          </PaginationBox>
        </div>
      )}
    </Layout>
  );
};

export default Profiles;
