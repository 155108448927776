import { FC, memo, useState } from "react";
import { useSelector } from "react-redux";
import { GrFormClose } from "react-icons/gr";
import { Icon } from "@iconify/react";
import { WelfareDetailType } from "ducks/welfare/type";
import {
  selectWelfareDetail,
  selectIsDetailLoading,
} from "ducks/welfare/slice";
import { Tab, Tabs } from "@mui/material";
import TabPanel from "./ModalTabPanel";

type Props = {
  isOpen: boolean;
  closeModal: VoidFunction;
};

/**
 * 福利厚生詳細モーダル
 * @param Props
 * @returns
 */
const WelfareDetailModal: FC<Props> = ({ isOpen, closeModal }) => {
  // field
  const welfare: WelfareDetailType = useSelector(selectWelfareDetail);
  const isDetailLoading: boolean = useSelector(selectIsDetailLoading);

  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 z-[1001] flex flex-col items-center justify-center w-full h-full bg-black/50" onClick={closeModal}>
          <div className="relative z-[1002] lg:flex items-start justify-center lg:w-[700px] lg:h-[99%] p-[40px_0] my-[14px] text-white bg-[#0e1d3e] rounded-[20px]
          md:w-[600px] w-[99%] h-[90%]" onClick={(e) => e.stopPropagation()}>
            {isDetailLoading ? (
              <></>
            ) : (
              <>
                <div>
                  <div className="absolute top-[10px] right-[5px] pr-[20px]">
                    <span className="cursor-pointer">
                      <GrFormClose
                        size={"4vw"}
                        onClick={closeModal}
                      />
                    </span>
                  </div>
                </div>
                <div className="max-h-full p-[0_20px_0_40px] overflow-y-scroll custom-scrollbar
                md:px-[10px] md:py-0 md:pl-[30px] md:min-w-full pl-[20px] pr-[0px]">
                  <div className="mx-auto lg:text-[42px] font-bold text-center lg:tracking-[16px]
                  md:text-[42px] md:tracking-[18.5px] text-[28px] tracking-[8px]">
                    {welfare.division}
                  </div>
                  <div className="lg:w-[250px] lg:h-[250px] lg:mx-auto md:w-[360px] md:h-[360px] md:mt-[10px] md:mx-auto md:mb-0
                  w-[50vw] h-[50vw] py-[2vw] px-0 my-[6px] mx-auto">
                    <Icon className="lg:w-[170px] lg:h-[170px] mt-[20px] lg:mx-[40px] mb-0 tb:w-[252px] md:h-[252px] md:mt-[14px] md:mx-[54px] md:mb-0
                    block w-[28vw] h-[28vw] mx-auto my-0" icon={welfare.icon} />
                    <div className="lg:mt-[10px] lg:mx-auto lg:text-[24px] lg:text-center md:mt-[8px] md:mx-auto md:mb-0 md:text-[24px] md:text-center
                    mt-[4vw] mx-auto mb-0 text-[20px] text-center">{welfare.name}</div>
                  </div>
                  <div className="mt-[24px] border-solid border-[1px] rounded-[15px]">
                    <Tabs
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#3498DB",
                          height: "2px",
                        },
                      }}
                      value={selectedTab}
                      onChange={(_, value) => {
                        setSelectedTab(value);
                      }}
                    >
                      <Tab
                        label={
                          <span style={{ color: "#fff", fontSize: 18 }}>
                            概要
                          </span>
                        }
                      />
                      <Tab
                        label={
                          <span style={{ color: "#fff", fontSize: 18 }}>
                            申請方法
                          </span>
                        }
                      />
                    </Tabs>
                    <hr className="h-px bg-gray-300 border-none"/>
                    <TabPanel value={selectedTab} index={0}>
                      <div className="lg:w-[600px] lg:min-h-[230px] p-0 mt-0 mx-auto mb-0 lg:text-[20px] lg:leading-[34px] break-words whitespace-pre-wrap
                      md:w-[540px] md:min-h-[210px] md:text-[18.5px] md:leading-[30px]
                      w-full min-h-[150px] text-[14px] leading-[22px]">
                        {welfare.explanation}
                      </div>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                      <div className="lg:w-[600px] lg:min-h-[230px] p-0 mt-0 mx-auto mb-0 lg:text-[20px] lg:leading-[34px] break-words whitespace-pre-wrap
                      md:w-[540px] md:min-h-[210px] md:text-[18.5px] md:leading-[30px]
                      w-full min-h-[150px] text-[14px] leading-[22px]">
                        {welfare.application}
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default memo(WelfareDetailModal);
