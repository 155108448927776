import { FC, memo } from "react";
import styles from "styles/components/Home/FloatingLink.module.css";
import { Icon } from "@iconify/react";
import {
  KOKOROZASHI_ICON_FILE_NAME,
  X_URL,
  WANTEDLY_LOGO,
  WANTEDLY_URL,
  CORPORATE_URL,
  RECRUIT_URL,
  KOKOSYNA_V2_LOGO_FILE_NAME,
  KOKOSYNA_URL,
} from "common/constants";

type Props = {};

/**
 * 追従リンク
 * @param param0
 * @returns
 */
const FloatingLink: FC<Props> = () => {
  /** リンククリック */
  const clickLink = (url: string) => {
    window.open(url, "_blank");
  };
  return (
    <div className={styles.floating}>
      <ul className={styles.floating_list}>
        <li
          className={styles.floating_item}
          onClick={() => clickLink(KOKOSYNA_URL)}
        >
          <img
            src={KOKOSYNA_V2_LOGO_FILE_NAME}
            className={styles.floating_icon}
            alt="kokosyna"
          />
        </li>
        <li className={styles.floating_item} onClick={() => clickLink(X_URL)}>
          <Icon icon="ri:twitter-x-fill" className={styles.floating_icon} />
        </li>
        <li
          className={styles.floating_item}
          onClick={() => clickLink(WANTEDLY_URL)}
        >
          <img
            src={WANTEDLY_LOGO}
            className={`${styles.floating_icon} w-auto h-auto`}
            alt="wantedly"
          />
        </li>
        <li
          className={styles.floating_item}
          onClick={() => clickLink(CORPORATE_URL)}
        >
          <img
            src={KOKOROZASHI_ICON_FILE_NAME}
            className={styles.floating_icon}
            alt="kokorozashi"
          />
        </li>
        <li
          className={styles.floating_item}
          onClick={() => clickLink(RECRUIT_URL)}
        >
          <Icon
            icon="hugeicons:permanent-job"
            className={styles.floating_icon}
          />
        </li>
      </ul>
    </div>
  );
};

export default memo(FloatingLink);
