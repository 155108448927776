import { FC } from "react";

/**
 * エラー画面
 * @returns
 */
const Error: FC = () => {
  return (
    <div>
      <h1>404</h1>
      <a href="/">ホームへ戻る</a>
    </div>
  );
};

export default Error;
