import { FC, useEffect } from "react";
import Layout from "components/Layout";
import {
  CustomContainer,
  containerSx,
} from "styles/containers/activitiesTheme";

import { fetchAsyncGetActivityReports } from "ducks/activity/slice";
import ActivityList from "components/Activities/ActivityList";
import ActivitySide from "components/Activities/ActivitySide";
import Loader from "components/Loader";
import { useActivities } from "hooks/activitiesHooks";

const Activities: FC = () => {
  const { dispatch, isLoading, pageName, breadcrumbs } = useActivities();

  // useEffect
  useEffect(() => {
    dispatch(fetchAsyncGetActivityReports());
  }, [dispatch]);

  return (
    <Layout pageName={pageName} breadcrumbs={breadcrumbs}>
      <></>
      {isLoading ? (
        <Loader />
      ) : (
        <CustomContainer sx={containerSx}>
          <ActivityList />
          <ActivitySide />
        </CustomContainer>
      )}
    </Layout>
  );
};

export default Activities;
