import { SxProps, Theme } from "@mui/material/styles";
import MDEditor from "@uiw/react-md-editor";
import { Box, Card, Modal, TextField, Typography } from "@mui/material";
import { AiFillEye } from "react-icons/ai";
import { BsImages } from "react-icons/bs";
import { BsFillEyeSlashFill, BsCloudArrowUpFill } from "react-icons/bs";
import { BsFillTrashFill } from "react-icons/bs";
import { BiSave, BiPen } from "react-icons/bi";

import emotionStyled from "@emotion/styled";
import styled from "@emotion/styled";

// styled
export const PreviewDiv = styled.div`
  background-color: white;
  border: solid;
  border-width: 1px;
  border-color: black;
  border-radius: 10px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
  p {
    margin-bottom: 24px;
  }
  h1 {
    margin-bottom: 12px;
  }
  h2 {
    margin-bottom: 12px;
  }
  h3 {
    margin-bottom: 12px;
  }
  h4 {
    margin-bottom: 12px;
  }
  hr {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .break {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

// emotionStyled（スタイル）
export const TitleArea = emotionStyled(Box)`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
align-items: top;
`;

export const TitleTextField = emotionStyled(TextField)`
`;

export const EyeCatchArea = emotionStyled(Box)`
`;

export const ButtonArea = emotionStyled(Box)`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
align-items: top;
`;

export const EyeCatchSelectIcon = emotionStyled(BsImages)`
cursor: pointer;
@media (max-width:599px) {
  width: 28px;
  height: 28px;
}
@media (min-width:600px) {
  width: 32px;
  height: 32px;
}
@media (min-width:900px) {
  width: 36px;
  height: 36px;
}
@media (min-width:1200px) {
  width: 40px;
  height: 40px;
}
`;

export const EyeCatchDeleteIcon = emotionStyled(BsFillTrashFill)`
cursor: pointer;
@media (max-width:599px) {
  width: 28px;
  height: 28px;
}
@media (min-width:600px) {
  width: 32px;
  height: 32px;
}
@media (min-width:900px) {
  width: 36px;
  height: 36px;
}
@media (min-width:1200px) {
  width: 40px;
  height: 40px;
}
`;

export const PreviewOnIcon = emotionStyled(AiFillEye)`
cursor: pointer;
@media (max-width:599px) {
  width: 28px;
  height: 28px;
}
@media (min-width:600px) {
  width: 32px;
  height: 32px;
}
@media (min-width:900px) {
  width: 36px;
  height: 36px;
}
@media (min-width:1200px) {
  width: 40px;
  height: 40px;
}
`;

export const PreviewOffIcon = emotionStyled(BsFillEyeSlashFill)`
cursor: pointer;
@media (max-width:599px) {
  width: 28px;
  height: 28px;
}
@media (min-width:600px) {
  width: 32px;
  height: 32px;
}
@media (min-width:900px) {
  width: 36px;
  height: 36px;
}
@media (min-width:1200px) {
  width: 40px;
  height: 40px;
}
`;
export const PostIcon = emotionStyled(BsCloudArrowUpFill)`
cursor: pointer;
@media (max-width:599px) {
  width: 28px;
  height: 28px;
}
@media (min-width:600px) {
  width: 32px;
  height: 32px;
}
@media (min-width:900px) {
  width: 36px;
  height: 36px;
}
@media (min-width:1200px) {
  width: 40px;
  height: 40px;
}
`;

export const CustomMDEditor = emotionStyled(MDEditor)`
@media (max-width:599px) {
  margin-bottom: 6%;
  margin-left: 4%;
  margin-right: 4%;
  min-height: 380px;
}
@media (min-width:600px) {
  margin-bottom: 6%;
  margin-left: 6%;
  margin-right: 6%;
  min-height: 420px;
}
@media (min-width:900px) {
  margin-bottom: 5%;
  margin-left: 12%;
  margin-right: 12%;
  min-height: 450px;
}
@media (min-width:1200px) {
  margin-bottom: 4%;
  margin-left: 22%;
  margin-right: 22%;
  min-height: 480px;
}
`;

export const PreviewCard = emotionStyled(Card)`
overflow: auto;
`;

export const SaveIcon = emotionStyled(BiSave)`
cursor: pointer;
@media (max-width:599px) {
  width: 28px;
  height: 28px;
}
@media (min-width:600px) {
  width: 32px;
  height: 32px;
}
@media (min-width:900px) {
  width: 36px;
  height: 36px;
}
@media (min-width:1200px) {
  width: 40px;
  height: 40px;
}
`;

export const DraftIcon = emotionStyled(BiPen)`
cursor: pointer;
@media (max-width:599px) {
  width: 28px;
  height: 28px;
}
@media (min-width:600px) {
  width: 32px;
  height: 32px;
}
@media (min-width:900px) {
  width: 36px;
  height: 36px;
}
@media (min-width:1200px) {
  width: 40px;
  height: 40px;
}
`;

export const DraftModal = emotionStyled(Modal)`
display: flex;
flex-flow: column;
justify-content: center;
align-items: center;
`;

export const DraftModalBox = emotionStyled(Box)`
display: flex;
flex-flow: column;
justify-content: center;
align-items: center;
background-color: white;
`;

export const DraftContentsBox = emotionStyled(Box)`
display: flex;
flex-flow: column;
justify-content: center;
align-items: left;
background-color: white;
`;

export const DraftContentTypography = emotionStyled(Typography)`
cursor: pointer;
text-decoration:underline;
&:hover {
  color: #1976d2;
}
`;

// Theme（レスポンシブスタイル）
export const titleAreaSx: SxProps<Theme> = {
  marginLeft: { xs: "4%", sm: "6%", md: "12%", lg: "22%" },
  marginRight: { xs: "4%", sm: "6%", md: "12%", lg: "22%" },
  marginTop: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
  marginBottom: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
};

export const titleTextFieldSx: SxProps<Theme> = {
  width: { xs: "80%", sm: "85%", md: "90%", lg: "90%" },
};

export const eyeCatchAreaSx: SxProps<Theme> = {
  marginLeft: { xs: "20%", sm: "30%", md: "35%", lg: "38%" },
  marginRight: { xs: "20%", sm: "30%", md: "35%", lg: "38%" },
  marginTop: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
  marginBottom: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
};

export const buttonAreaSx: SxProps<Theme> = {
  marginLeft: { xs: "4%", sm: "6%", md: "12%", lg: "22%" },
  marginRight: { xs: "4%", sm: "6%", md: "12%", lg: "22%" },
  marginTop: { xs: 4, sm: 6, md: 8, lg: 10 },
  marginBottom: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
  gap: { xs: 3, sm: 5, md: 6, lg: 7 },
};

export const previewCardSx: SxProps<Theme> = {
  marginLeft: { xs: "4%", sm: "6%", md: "12%", lg: "22%" },
  marginRight: { xs: "4%", sm: "6%", md: "12%", lg: "22%" },
  marginTop: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
  marginBottom: { xs: 2, sm: 2.5, md: 3, lg: 3.5 },
  padding: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
  minHeight: { xs: 350, sm: 500, md: 500, lg: 520 },
};

export const draftModalBoxSx: SxProps<Theme> = {
  width: { xs: 400, sm: 400, md: 400, lg: 800 },
};

export const draftContentsBoxSx: SxProps<Theme> = {
  width: { xs: 400, sm: 400, md: 400, lg: 800 },
};
