import { FC, memo } from "react";
import { CommandGroup, CommandItem, CommandList } from "./Shadcn/Command";
import { Check } from "lucide-react"
import { cn } from "lib/utils";
import { useSearchParams } from "react-router-dom";

type Props = {
  options: string[];
  setOpen: (value: boolean) => void;
}

/**
 * 検索アイテム
 * @returns
 */
const SearchItems: FC<Props> = ({options, setOpen}: Props) => {

  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get("category");
  const word = searchParams.get("word");

  const handleSelect = (value: string) => {
    searchParams.set("word", value ?? "");
    searchParams.set("category", category ?? "");
    setSearchParams(searchParams);
    setOpen(false);
  }

  return (
    <div className="overflow-visible relative h-0 z-100 p-0 m-0">
      <CommandList className="absolute left-0 top-0 w-full rounded bg-background shadow-md z-50">
        <CommandGroup>
          {options.map((v) => (
          <CommandItem
            key={v}
            value={v}
            onMouseDown={() => handleSelect(v)}
            onSelect={() => handleSelect(v)}
          >
            <Check
              className={cn(
                'mr-2 h-4 w-4',
                v === word ? 'opacity-100' : 'opacity-0'
              )}
            />
            {v}
          </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </div>
  );
}

export default memo(SearchItems);