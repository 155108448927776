import { ProfileState } from "ducks/profile/type";

export const initialState: ProfileState = {
  isLoading: true,
  isUpdateLoading: false,
  isUpdateFinished: false,
  uploadPicture: null,
  uploadSkillSheet: null,
  searchedSkills: [],
  profile: {
    partitionKey: "",
    staffId: "",
    firstName: "",
    lastName: "",
    stateId: 0,
    city: "",
    firstNameKana: "",
    lastNameKana: "",
    birthday: "",
    hireDate: "",
    email: "",
    picture: "",
    jobId: 0,
    hobby: "",
    skills: [],
    freeSpace: "",
    skillSheet: "",
    deleteFlg: 0,
  },
};
