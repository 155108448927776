import { ChangeEvent, FC, useState } from "react";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";

import { PAGES } from "common/PAGES";
import { DEFAULT_USER_ICON, LOCAL_STORAGE_KEYS } from "common/constants";
import { UserInfo } from "ducks/auth/type";
import { selectUserInfo } from "ducks/auth/slice";
import {
  DropdownMenu,
  DropdownMenuGroup,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/Shadcn/DropdownMenu"
import { LogOut, User } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "components/Shadcn/Button";

/**
 * サムネイルメニュー
 * @returns
 */
const ThumbnailMenu: FC = () => {
  const navigate = useNavigate();
  const userInfo: UserInfo = useSelector(selectUserInfo);
  const [isThumbnailLoading, setIsThumbnailLoading] = useState<boolean>(false);


  const clickEditProfile = () => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.PROFILE_EDIT_KEY,
      userInfo.partitionKey
    );
    navigate(PAGES.PROFILE_EDIT.PATH);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="outline-none flex justify-center">
        <Button variant="secondary" size="icon" className="rounded-full">
          <img
            className={`${isThumbnailLoading ? "block" : "none"} rounded-full`}
            src={userInfo.picture}
            alt="profile img"
            onLoad={() => setIsThumbnailLoading(true)}
            onError={(e: ChangeEvent<HTMLImageElement>) =>
              (e.target.src = DEFAULT_USER_ICON)
            }
          />
          <span className="sr-only">Toggle user menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="z-[2000]">
        <DropdownMenuLabel>{`${userInfo.lastName} ${userInfo.firstName}`}</DropdownMenuLabel>
        <DropdownMenuLabel className="text-gray-500">{userInfo.email}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem onSelect={clickEditProfile}>
            <User className="mr-2 h-4 w-4" />
            <span>プロフィール編集</span>
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={() => Auth.signOut()}>
            <LogOut className="mr-2 h-4 w-4" />
            <span>サインアウト</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>

  );
};

export default ThumbnailMenu;
