import { ActivityReportUpdateState } from "./type";

export const initialState: ActivityReportUpdateState = {
  eyeCatchImg: "",
  title: "",
  texts: [
    {
      text: "",
      mdText: "",
      editFlg: false,
      type: 0,
    },
  ],
  htmlText: "",
};
