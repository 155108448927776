import { SEARCH_CATEGORY, SearchCategoryValue } from "./constants";
import { Skill } from "./types";

/**
 * 生年月日から年齢を計算
 * @param birthday 誕生日（yyyy/mm/dd形式)
 * @returns number 現在の年齢
 **/
export const calculateAge = (birthday: string): number => {
  const nowDate: Date = new Date();
  const birthdayDate: Date = new Date(birthday);

  const birthNumber =
    birthdayDate.getFullYear() * 10000 +
    (birthdayDate.getMonth() + 1) * 100 +
    birthdayDate.getDate();
  const nowNumber =
    nowDate.getFullYear() * 10000 +
    (nowDate.getMonth() + 1) * 100 +
    nowDate.getDate();

  return Math.floor((nowNumber - birthNumber) / 10000);
};

/**
 * テキストをユーザのクリップボードにコピー
 * @param text コピーしたいテキスト
 * @returns void
 */
export const copyText = (text: string): void => {
  navigator.clipboard.writeText(text);
};

/**
 * スキルソート
 * @param a
 * @param b
 * @returns
 */
export const sortSkill = (a: Skill, b: Skill) => {
  if (a.skillType < b.skillType) {
    return -1;
  }
  if (a.skillType > b.skillType) {
    return 1;
  }
  return 0;
};

/**
 * 検索種別取得
 * @param number
 * @returns SearchCategoryValue | undefined
 */
export const getSearchCategoryByValue = (value: string | null): SearchCategoryValue | undefined => {
  const num = parseInt(value ?? "1")
  const categories = Object.values(SEARCH_CATEGORY);
  return categories.find(category => category.value === num);
}

/**
 * 年月日フォーマットチェック
 * yyyy/MM/dd形式
 * @params date
 * @return true/false null等はfalseで返す
 */
export const checkDateFormat = (date: string): boolean => {
  const res = date.match(/\d{4}\/\d{2}\/\d{2}/);
  return res ? true : false;
};

/**
 * ios端末かのチェック
 * @returns true: ios系端末, false: その他
 */
export const checkIos = (): boolean => {
  const ua = window.navigator.userAgent.toLowerCase();
  return (
    ua.indexOf("iphone") > -1 ||
    ua.indexOf("ipad") > -1 ||
    (ua.indexOf("macintosh") > -1 && "ontouchend" in document)
  );
};

/** 日時フォーマット */
const DATE_TIME_FORMAT: Intl.DateTimeFormat = new Intl.DateTimeFormat("ja-JP", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  timeZone: "Asia/Tokyo",
});

/**
 * 現在日時取得
 * @return string 現在日時(YYYY/MM/DD HH:MM:SS)
 */
export const getDateTimeNow = (): string => {
  return DATE_TIME_FORMAT.format(new Date());
};

/**
 * 現在日時取得
 * @return string 現在日時(YYYYMMDDHHMMSS)
 */
export const getDateTimeNow2 = (): string => {
  const now: Date = new Date();
  return (
    now.getFullYear() +
    ("0" + (now.getMonth() + 1)).slice(-2) +
    ("0" + now.getDate()).slice(-2) +
    ("0" + now.getHours()).slice(-2) +
    ("0" + now.getMinutes()).slice(-2) +
    ("0" + now.getSeconds()).slice(-2)
  );
};

/**
 * モーダル背景固定
 * @param scrollPos スクロール位置
 */
export const lockModalBack = (scrollPos: number): void => {
  // iOSとそれ以外でスタイル分ける必要あり
  if (checkIos()) {
    // for ios
    document.body.style.width = "100%";
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollPos}px`;
  } else {
    // for others
    document.body.style.overflowY = "hidden";
  }
};

/**
 * モーダル背景の固定解除
 * @param scrollPos スクロール位置
 */
export const unlockModalBack = (scrollPos: number): void => {
  document.body.style.width = "";
  document.body.style.position = "";
  document.body.style.top = "";
  document.body.style.overflowY = "initial";
  window.scrollTo(0, scrollPos);
};

/**
 * AWS統合リクエストを使用したPutItem用のデータのエスケープ処理
 * @param str
 * @returns
 */
export const escapeForAwsIntegra = (str: string): string => {
  return str.replaceAll("\n", "\\n");
};

/**
 * 日時フォーマット変換
 * yyyy/mm/dd hh:mm:dd => yyyymmddhhmmss
 * @param dateStr
 * @returns
 */
export const convertIntoRowDate = (dateStr: string): string => {
  return dateStr.replaceAll("/", "").replaceAll(":", "").replaceAll(" ", "");
};
