import { SxProps, Theme } from "@mui/material/styles";
import { Box, Card, Link } from "@mui/material";
import emotionStyled from "@emotion/styled";

/* Emotion */
export const SideBox = emotionStyled(Box)`
position: fixed;
display: flex;
flex-flow: column;
justify-content: center;
align-items: center;
left: 50%;
@media (max-width:599px) {
  margin-left: 90px;
}
@media (min-width:600px) {
  margin-left: 90px;
}
@media (min-width:900px) {
  margin-left: 220px;
}
@media (min-width:992px) and (max-width:1200px){
  top: 155px;
}
@media (min-width:1200px) {
  margin-left: 250px;
}
`;

export const SideCard = emotionStyled(Card)`
display: flex;
flex-flow: column;
justify-content: center;
align-items: center;
`;

export const SideAvatarArea = emotionStyled(Box)`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: right;
align-items: center;
`;

export const SideLink = emotionStyled(Link)`
cursor: pointer;
`;

/* Theme（レスポンシブ） */
// Box
export const sideBoxSx: SxProps<Theme> = {
  width: { xs: 100, sm: 190, md: 210, lg: 300 },
  top: { xs: 118, sm: 122, md: 125, lg: 160 },
};

// Card
export const sideCardSx: SxProps<Theme> = {
  width: { xs: 90, sm: 185, md: 200, lg: 280 },
  height: { xs: 75, sm: 80, md: 100, lg: 120 },
  marginTop: { xs: 0.2, sm: 0.4, md: 0.5, lg: 0.4 },
  marginBottom: { xs: 0.5, sm: 1, md: 1, lg: 2 },
  padding: { xs: 0.5, sm: 1, md: 1, lg: 1.5 },
};

// Area
export const sideAvatarAreaSx: SxProps<Theme> = {
  width: { xs: 80, sm: 130, md: 180, lg: 230 },
  marginTop: { xs: 0.5, sm: 1, md: 1, lg: 1 },
};

// Element
export const sideLinkSx: SxProps<Theme> = {
  fontSize: { xs: 10, sm: 13, md: 15, lg: 18 },
};

export const sideAvatarElementSx: SxProps<Theme> = {
  width: { xs: 20, sm: 25, md: 30, lg: 40 },
  height: { xs: 20, sm: 25, md: 30, lg: 40 },
};
