import { PAGES, PageType } from "common/PAGES";
import ActivityUpdateEditArea from "components/ActivityUpdate/ActivityUpdateEditArea";
import ActivityUpdatePreviewArea from "components/ActivityUpdate/ActivityUpdatePreviewArea";
import Layout from "components/Layout";
import { Button } from "components/Shadcn/Button";
import {
  fetchAsyncGetActivityReportUpdate,
  setEyeCatchImg,
  setTitle,
} from "ducks/activityReportUpdate/slice";
import { useActivityUpdate } from "hooks/activityUpdateHooks";
import {
  FC,
  useMemo,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
  MouseEvent,
} from "react";
import { useLocation } from "react-router-dom";
import {
  ButtonArea,
  EyeCatchArea,
  EyeCatchDeleteIcon,
  EyeCatchSelectIcon,
  PostIcon,
  PreviewOffIcon,
  PreviewOnIcon,
  TitleArea,
  TitleTextField,
  buttonAreaSx,
  eyeCatchAreaSx,
  titleAreaSx,
  titleTextFieldSx,
} from "styles/containers/activityAddTheme";

const ActivityUpdate: FC = () => {
  /* URLパラメータの取得 */
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const reportId = query.get("reportId");

  /** header情報 **/
  const pageName: string = useMemo(() => PAGES.ACTIVITY_REPORT_UPDATE.NAME, []);
  const breadcrumbs: PageType[] = useMemo(
    () => [PAGES.HOME, PAGES.ACTIVITY_REPORT, PAGES.ACTIVITY_REPORT_UPDATE],
    []
  );

  /** customHooks **/
  const {
    dispatch,
    eyeCatchImg,
    title,
    onClickEyeCatch,
    onClickPost,
    onClickPreview,
    onChangeEyeCatch,
  } = useActivityUpdate();

  /* useState */
  const [previewFlg, setPreviewFlg] = useState<boolean>(false);
  const [eyeCatchFile, setEyeCatchFile] = useState<File | null>(null);

  /* useRef */
  const eyeCatchRef = useRef<HTMLInputElement>(null);

  /* useEffect */
  useEffect(() => {
    dispatch(fetchAsyncGetActivityReportUpdate(reportId ? reportId : ""));
  }, [dispatch, reportId]);

  return (
    <Layout pageName={pageName} breadcrumbs={breadcrumbs}>
      <ButtonArea sx={buttonAreaSx}>
        {previewFlg ? (
          <Button variant="ghost">
            <PreviewOffIcon
              onClick={() => {
                setPreviewFlg(false);
              }}
            />
          </Button>
        ) : (
          <Button variant="ghost">
            <PreviewOnIcon
              onClick={() => {
                onClickPreview();
                setPreviewFlg(true);
              }}
            />
          </Button>
        )}
        <Button variant="ghost">
          <PostIcon
            onClick={() => {
              onClickPost(reportId ? reportId : "");
            }}
          />
        </Button>
      </ButtonArea>

      {eyeCatchImg && (
        <EyeCatchArea sx={eyeCatchAreaSx}>
          <img src={eyeCatchImg} alt="Loading…" />
        </EyeCatchArea>
      )}

      <TitleArea sx={titleAreaSx}>
        <TitleTextField
          id="post-title"
          placeholder="タイトル"
          variant="standard"
          inputProps={{ style: { fontSize: 28 } }}
          value={title}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dispatch(setTitle(e.target.value));
          }}
          sx={titleTextFieldSx}
        />
        <Button variant="ghost">
          {eyeCatchFile === null && eyeCatchImg === "" ? (
            <EyeCatchSelectIcon
              onClick={() => {
                onClickEyeCatch(eyeCatchRef);
              }}
            />
          ) : (
            <EyeCatchDeleteIcon
              onClick={() => {
                setEyeCatchFile(null);
                dispatch(setEyeCatchImg(""));
              }}
            />
          )}
        </Button>
        <input
          style={{ display: "none" }}
          type="file"
          ref={eyeCatchRef}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeEyeCatch(e, setEyeCatchFile)
          }
          // 同じファイルを再度指定された時用
          onClick={(e: MouseEvent<HTMLInputElement>) => {
            (e.target as HTMLInputElement).value = "";
          }}
        />
      </TitleArea>

      {previewFlg ? <ActivityUpdatePreviewArea /> : <ActivityUpdateEditArea />}
    </Layout>
  );
};

export default ActivityUpdate;
