import { AuthState } from "ducks/auth/type";
export const initialState: AuthState = {
  userInfo: {
    isAdmin: false,
    partitionKey: "",
    firstName: "",
    lastName: "",
    email: "",
    picture: "",
    histories: [],
    deleteFlg: 0,
  },
};
