import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { PAGES } from "common/PAGES";
import { Button } from "components/Shadcn/Button";

const ErrorFallback = () => {
  return (
    <Box m={6}>
      <Card>
        <CardContent>
          <Typography variant="h4" color="text.primary">
            エラーが発生しました
          </Typography>
        </CardContent>
        <CardActions>
          {/* navigateでは正常に画面遷移できないため、hrefを利用
              ※ReactのErrorBoundary機能はJavaScriptのエラー時に表示される
                通常起こりえない+異常終了であるため、画面リセットという意味でhrefでも問題なし */}
          <Button variant="ghost" onClick={() => window.location.href = PAGES.HOME.PATH}>
            ホームへ戻る
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default ErrorFallback;
