import { FC, memo } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./Shadcn/Select";
import { useSearchArea } from "hooks/profiles/searchAreaHooks";
import { SEARCH_CATEGORY } from "common/constants";
import { getSearchCategoryByValue } from "common/common";
import { useDebouncedCallback } from "use-debounce";
import { useSearchParams } from "react-router-dom";
import { useProfile } from "hooks/profileHooks";

/**
 * 検索種別選択
 * @returns
 */
const SearchCategorySelect : FC = () => {
  const {
    changeSearchCategory,
  } = useSearchArea();
  const {
    lockOn,
    lockOff,
  } = useProfile();
  const debouncedLockOff = useDebouncedCallback(lockOff, 30);
  const handleSelectChange = (value: string) => {
    const category = getSearchCategoryByValue(value);
    if (!category) return;
    changeSearchCategory(category);
    // HACK: モバイルデバイスでPointerDownした際にイベント透過してしまうため
    debouncedLockOff();
  };
  // eslint-disable-next-line
  const [searchParams, _] = useSearchParams();
  const category = searchParams.get("category") ?? "1";

  return (
    <>
      <Select value={category} defaultValue={category} onValueChange={handleSelectChange}>
        <SelectTrigger className="outline-none rounded-r-none w-[250px] focus:outline-none focus:ring-0">
          <SelectValue></SelectValue>
        </SelectTrigger>
        <SelectContent>
          {Object.values(SEARCH_CATEGORY).map((category) => (
            <SelectItem
              onPointerDown={lockOn} // HACK: モバイルデバイスでPointerDownした際にイベント透過してしまうため
              value={String(category.value)}>{category.label}</SelectItem>
          ))}
        </SelectContent>
      </Select>
    </>
  );
}

export default memo(SearchCategorySelect);